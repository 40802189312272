@import "./variables";

.terminos {
  width: 672px;
  margin: 31.1px auto;

  .title {
    color: $copec-dark-blue;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .bajada {
    color: $copec-grey;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.4px;
  }

  p {
    color: $copec-dark-blue;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.34px;
  }

  .content-separator-line {
    margin-top: 31.4px;
    margin-bottom: 47.5px;
  }

  .terminos-buttons {
    display: flex;
    justify-content: center;
    .hallow {
      margin-right: 33px;
      background-color: white;
      color:$copec-red;
      border: solid 1px $copec-red !important;
    }
    .marginm {
      margin-right: 33px;
    }
    .red {
      background-color: #ea212e;
    } 
  }

  .listado {
    margin-left: 1.7rem;
    p {
      margin: 0;
    }
  }

  .plistado {
    margin-bottom: 0;
  }

  .listadocorreos {
    width: 640px;
    background-color: $copec-light-grey;
    padding: 8px;
    border-radius: 8px;
    .title {
      border-radius: 4px;
      background-color: $copec-dark-blue;
      color:white;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      margin-bottom: 8px;
      padding: 9px 16px 8px 16px;
    }
    .lista {
      color: $copec-dark-blue;
      padding: 5px 7px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      .item{
        padding: 7.5px 0 9px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .mail{
          margin-left:10px
        }
        img{
          margin-right:10px;
        }
      }
    }
  }

  .terminos-error {
    width: 500px;
    margin: 175px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    img {
      width: 120px !important;
      margin: auto;
    }
    h3 {
      color: #084271;
      text-align: center;
      margin-top: 48px;
      margin-bottom: 24px;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: 0.86px;
    }
    p {
      text-align: center;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.4px;
      color: #084271;
    }
  }
}
