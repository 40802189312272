.resumen {
  .resumen-options {
    max-width: 1308px;
    @media screen and (max-width: $mobile-break-point) {
      .important-message {
        padding: 16px;
        display: flex;
        font-size: 14px;
      }
    }
  }
  .historial-options {
    max-width: 1308px;
  }
  .liberar-options {
    max-width: 1314px;
  }
  @media screen and (max-width: $mobile-break-point) {
    .mobile-table__item-content-list {
      max-height: 50px;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
    }
    .mobile-table__item-button.expanded-section {
      & + .mobile-table__item-content-list {
        max-height: 170px;
      }
    }
  }

  .historial-arcoprime{
    width: 192px;
    margin: 55px auto;
    .arcoprime-title{
      font-size: 12px;
      font-weight: 800;
      color: $copec-dark-blue;
      margin-bottom: 8px;
      &.mtop{
        margin-top:24px;
      }
      .smaller-title{
        font-size: 10px;
      }
    }
    .sep{
      margin-top:23px;
      margin-bottom: 31px;
    }
    .actions{
      margin:0;
      display: flex;
      justify-content: center;
      .actions-buttons {
        margin:auto;
        width: 138px;
        height: 32px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        float:none;
        &.hallow {
          background-color: white;
          cursor: not-allowed;
        }
      }
    }
    .arcoprime-button{
      width: 192px;
      height: 32px;
      padding: 8px 1px;
      font-size: 12px;
      background-color: $copec-dark-blue;
      border-radius: 36px;
      color:white;
      border:none;
      cursor: pointer;
      margin-bottom: 30px;
      &.disabled,&:disabled{
        background-color: #bdbdbd;
        color:white;
      }
    }
    
  }
}

.resumen-table {
  overflow-x: scroll;
  min-height: 400px;

  .filter-content {
    &.active {
      .numeric-select {
        input.equal-selected,
        input.comparision-selected,
        input.all-selected {
          border: solid 1px #6cbfff;
          border-left: none;
        }
        .comparision-type {
          border: solid 1px #6cbfff;
          border-right: none;
        }
      }
    }
  }

  .numeric-select {
    width: 208px !important;
    .comparision-type {
      padding-left: 5px;
    }
    input {
      width: calc(100% - 27px) !important;
      padding-right: 2px;
      &.equal-selected {
        width: calc(100% - 21px) !important;
      }
      &.all-selected {
        width: 191px !important;
      }
    }
  }

  .th-data {
    display: flex;
    justify-content: space-between;
    width: 168px;
    .arrows {
      margin-right: 0;
    }
  }
  &.liberar-table,
  &.liberar-documentos {
    .th-data {
      width: 148px;
    }
  }
  td.align-left {
    &.green {
      color: #07d188;
      font-weight: 700;
    }
    &.yellow {
      color: #ffb906;
      font-weight: 700;
    }
  }
}

.liberar-documentos .important-message {
  margin-top: 15px;
}

.resumenfix {
  @media print {
    .react-datepicker__input-container {
      input {
        width: 207px !important;
      }
    }
  }
}
