@import "./variables";

.fecha-options{
    display: flex;
    gap:10px;
    margin-top: 28px;
    justify-content: space-between;
    .react-datepicker-wrapper {
        border: 1px solid $copec-grey;
        border-radius: 4px;
        input{
            width: 170px;
            height: 16px;
        }
        
      }
}

.order-container{
    width: 873px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .orden-separator{
      margin: 20px 0px;
      height: 1px;
      width: 100%;
    }

    .important-message{
      width: 813px;

      .aceptada{
        font-weight: 700;
      }
    }

    .order-options{
        width: 100%;
        .orden-input{
            padding: 8px 24px 8px 16px;
            width: calc(160px - 32px);
            height: calc(36px - 16px);
            border-radius: 4px;
            border: 1px solid #7c97ab;
            font-size: 12px;
            font-weight: 500;
            color: $copec-dark-blue;
        }

        /*DESAPARECER FLECHAS DE INPUT DE TIPO NUMBER*/
        /* Chrome, Safari, Edge, Opera */
        .orden-input::-webkit-outer-spin-button,
        .orden-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        .orden-input[type=number] {
          -moz-appearance: textfield;
        }

    }
    .order-data{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap:32px;

        .important-message{
          width: 100%;
          background-color: rgba(234, 33, 46, 0.1);
          border-radius: 8px;
          font-weight: 700;
          color: #ea212e;

        }
        .error-icon{
          margin: 7px;
        }

        .info{
            display: flex;
            flex-direction: column;
            gap:24px;
        }

        .data-row{
            display: flex;
            gap:32px;
        }

        .campo{
            label{
                color:$copec-dark-blue;
                font-size: 12px;
                font-weight: 800;
                margin-bottom: 8px;
                display:inline-block;
            }
            .valor{
                background-color: #f1f5f8;
                min-height: 15px;
                padding: 8px 16px 9px 16px;
                font-size: 12px;
                color:$copec-dark-blue;
                width: calc(192px - 32px);
                font-weight: 500;
                border-radius: 4px;
                &.full{
                    width: calc(417px - 32px);
                }
            }
        }
        .table-container{
            label{
                color:$copec-dark-blue;
                font-size: 12px;
                font-weight: 800;
                margin-bottom: 8px;
                display:inline-block;
            }
            tbody{
              a{
                text-decoration: underline;
              }
              a:hover{
                color:#005190;
              }
            }
        }
        .factura-link{
          color: $copec-dark-blue;
        }
    }
    table {
        width: 417px;
        display: table;
        border-collapse: inherit;
        margin-top: 0px !important;
        border-spacing: 0;
        font-size: 12px;
        margin-bottom: 16px;
        padding-top: 8px;
        thead {
          width: 100%;
          background: #7c97ab;
          tr {
            background: #7c97ab;
            th {
              font-weight: 800;
              //padding-left: 40px;
              white-space: nowrap;
              border-right: 1px solid #7c97ab;
            }
          }
        }
        th,
        tfoot td {
          border: 0;
          font-weight: bold;
          line-height: 1.6;
          &:first-child {
            padding: 12px 10px 12px 15px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            padding: 12px 15px 12px 15px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          &[colspan="3"] {
            padding: 5px;
          }
        }
        td {
          background: white;
        }
        tbody {
          td {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-right: 10px;
            border-bottom: 1px solid $copec-light-grey;
            white-space: nowrap;
            &.align-right{
              padding: 12px 0px 12px 50px !important;
              &.tdcontent{
                width: 157px;
                display: flex;
                justify-content: space-between;
              }
            }
            
          }
          tr {
            td:last-child{
              padding: 12px 15px !important;
              &.tdsep{
                padding: 0 15px !important;
              }
            }
            &:first-child {
              td:first-child {
                border-top-left-radius: 4px;
              }
              td:last-child {
                border-top-right-radius: 4px;
              }
            }
            &:last-child {
              td:first-child {
                border-bottom-left-radius: 4px;
              }
              td:last-child {
                border-bottom-right-radius: 4px;
              }
            }
            td:first-child {
              padding-left: 20px;
              //width: 40%;
            }
            &.data {
              &.subtotal {
                td:first-child,
                td:last-child {
                  font-weight: bold;
                }
                td {
                  font-size: 14px;
                }
              }
            }
          }
        }
        tfoot td {
          font-size: 14px;
          &:last-child {
            text-align: right;
          }
        }
      }
}

.documento-info-modal.mantenimiento{
  min-width: 400px;
  table{
    th:last-child{
      padding-left: 15px;
    }
    thead{
      background:#7c97ab;
      tr{
        background:#7c97ab;
        th {
          border-right: 1px solid #7c97ab;
        }
      }
    }
    tbody{
      tr{
        a{
          text-decoration: underline;
        }
        a:hover{
          color:#005190;
        }
        td{
          padding: 12px 15px 14px !important;
          &:last-child{
            padding: 12px 15px 14px !important;
          }
        }
      }
    }
  }
}

.ellipsis-with-tooltip-container{
  position: relative !important;

  .highlighter-container{
    overflow: visible !important;
    display: contents;
    span{
      display: contents;
    }
    span span{
      //display: inline-block !important;
      //overflow: hidden;
      //text-overflow: ellipsis;
      //white-space: nowrap;
    }
  }

  &-avisos-mantenimiento-descripcion, &-avisos-mantenimiento-producto{    
    span span{
      width: 164px;
    }

    .table-data-ellipsis, .ot{
      width: 164px !important;
    }
  }

  &-mantenimiento{
    span span{
      width: 374px;
    }

    .table-data-ellipsis, .ot{
      width: 374px;
    }
  }

  .ellipsis-tooltip{
    position: absolute;
    top: 35px;
    left: 60px;
    z-index: 100;
    width: 280px;
    padding: 3px 10px 7px 10px;
    box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
    background: white;
    border-radius: 4px;
    visibility: hidden;
    &.active{
      visibility: visible;
    }
    .ellipsis-tooltip-title{
      color: $copec-dark-blue;
      display: inline-block;
      font-size: 10px;
      margin-bottom: 10px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
    .ellipsis-tooltip-content{
      background: $copec-gris-input;
      color: $copec-dark-blue;
      font-size: 10px;
      padding: 8px 4px;
      border-radius: 4px;
    }
  }

  .active-ellipsis:hover{
    .ellipsis-tooltip{
      visibility: visible;
    }
  }
}

.mantenimiento-table{
  .th-data{
    padding: 0 8px;
    position: relative;
    height: 46px;
  }
  .th-text{
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
  }
  .factura-link{
    color:$copec-dark-blue;
  }
}

.warning-message{
  position: absolute;
  font-size: 10px;
}
