.comprobante-container{
	display: inline-block;
	width: 100%;
	margin-top: 82px;
	.comprobante{
		position: relative;
		width: 603px;
		margin: 0 auto;
		border: 1px solid $copec-grey-border;
		border-radius: 6px;

		.logo{
			margin: 26px 24px;
		}

		.bottom-logo{
			position: absolute;
			bottom: 52px;
			right: 43px;
		}

		.comprobante-title{
			font-size: 18px;
			color: $copec-dark-blue;
			width: 100%;
			text-align: center;
			font-weight: bold;
		}
		hr{
			width: calc(100% - 48px);
			margin: 40px auto 30px;

		}

		.header-data{
			margin: 0 44px;
			color: $copec-dark-blue;
			font-size: 10px;
			.title{
				font-weight: bold;
			}
			.data{
				margin-bottom: 10px;
				white-space: nowrap;
			}
		}

		table{
			background: transparent;
			color: $copec-dark-blue;
			border-collapse: collapse;
			width: calc(100% - 50px);
			margin: 25px;
			margin-bottom: 150px;
			padding: 0 !important;
			display: inline-table;

			thead{
				background: $copec-grey-table;
				font-weight: bold;
				th{
					font-size: 11px;
					border: none;
					padding: 5px 15px;
					color: $copec-dark-blue;
					&:first-child {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
					  }
					  &:last-child {
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
					  }
				}
			}
			tbody{
				tr{
					border-bottom: 1px solid $copec-light-grey;
				}
				td{
					font-size: 10px;
					padding: 15px;
				}
				.total-saldo{
					border-bottom: none;
					td{
						font-size: 12px;
						font-weight: bold;
					}
				}
			}
		}
	}
	&.nomargintop{
		margin-top:0;
	}
}

.bottom-actions{
	text-align: center;
	width: 310px;
	margin: 35px auto;
	.back-button{
		width: 200px;
		height: 45px;
		color: white;
		border: none;
		background: $copec-dark-blue;
		font-size: 16px;
		border-radius: 26px;
		font-weight: bold;
		float: left;
		cursor: pointer;
		&.red{
			background: $copec-red;
		}
		&.cancel{
			background: white;
			border: 1px solid $copec-dark-blue;
			color: $copec-dark-blue;
			margin-right: 20px;
			&.red{
				border: 1px solid $copec-red;
				color: $copec-red;
			}
		}
	}
	.comprobante-action{
		margin-top: 0;
		margin-left: 15px;
		float: left;
	}
}

.liberar-documentos{
	max-width: 1004px;
	overflow-x: auto;
	.bottom-actions{
		width: 530px;
	}

}