@import "./variables";

.camion {
  width: 900px;
  margin-bottom: 30px;
  background-image: url("../assets/img/camion.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 270px;

  &.empty {
    background-image: url("../assets/img/camion-empty.png");
  }

  .container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    top: 41px;
    left: 250px;
    position: relative;

    .compartimiento {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      height: 120px;
      padding: 4px;
      position: relative;

      .comp,
      .liters {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.14;
        letter-spacing: normal;
        color: $copec-dark-blue;
      }

      &.sp97 {
        background-color: #97d700;
      }

      &.pd {
        background-color: #545859;
        .comp,
        .liters {
          color: white;
        }
      }
      &.empty {
        background-color: $copec-grey-button-background;
        .comp,
        .liters {
          color: $copec-dark-blue;
        }
      }

      &.k {
        background-color: #004b87;
        .comp,
        .liters {
          color: white;
        }
      }

      .circle {
        margin: auto;
        border-radius: 50%;
        border: solid 1px #809cb2;
        width: 80px;
        height: 80px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .number {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.69px;
        text-align: center;
        color: $copec-dark-blue;
      }

      .code {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: 0.34px;
        text-align: center;
        color: $copec-dark-blue;
      }

      .compartimiento-info {
        position: absolute;
        top: 130px;
        visibility: hidden;
        background: white;
        border-radius: 4px;
        color: $copec-dark-blue;
        padding: 10px 11px;
        box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
        width: 270px;
        z-index: 10;
        .title {
          font-weight: bold;
          font-size: 10px;
          text-align: center;
          padding: 5px 0;
        }
        .content {
          background: $sidebar-grey;
          padding: 9px 6px;
          font-size: 10px;
          font-weight: 500;
          height: 14px;
          .left {
            float: left;
          }
          .right {
            float: right;
          }
        }
        .footer {
          .important {
            color: #ed1d24;
          }
        }
      }

      &:hover {
        .compartimiento-info {
          visibility: visible;
        }
      }
    }
    &.cont2 {
      .compartimiento {
        width: 288px;
        &.sp93 {
          background-image: url("../assets/img/93sp2.png");
        }
        &.sp95 {
          background-image: url("../assets/img/95sp2.png");
        }
      }
    }
    &.cont3 {
      .compartimiento {
        width: 187px;
        &.sp93 {
          background-image: url("../assets/img/93sp3.png");
        }
        &.sp95 {
          background-image: url("../assets/img/95sp3.png");
        }
      }
    }
    &.cont4 {
      .compartimiento {
        width: 136px;
        &.sp93 {
          background-image: url("../assets/img/93sp4.png");
        }
        &.sp95 {
          background-image: url("../assets/img/95sp4.png");
        }
      }
    }
    &.cont5 {
      .compartimiento {
        width: 106px;
        &.sp93 {
          background-image: url("../assets/img/93sp5.png");
        }
        &.sp95 {
          background-image: url("../assets/img/95sp5.png");
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-break-point) {
  .camion-mobile {
    width: auto;
    height: auto;
    background: none;
    padding-top: 0px;
    margin-bottom: 0;
    .container {
      display: block;
      position: static;
    }
    .observaciones {
      &__title {
        font-size: 12px;
        color: $copec-dark-blue;
        font-weight: bold;
        margin: 24px 0 8px 0;
      }
      textarea {
        width: calc(100% - 22px);
        height: 46px;
        border: 1px solid $copec-grey-3;
        border-radius: 4px;
        padding: 8px;
        color: $copec-dark-blue;
      }
      .observaciones-cont {
        font-size: 12px;
        color: $copec-dark-blue;
        text-align: right;
        margin-right: 2px;
      }
    }
    &__title {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
      color: $copec-dark-blue;
      font-weight: 500;
      strong {
        font-weight: 700;
      }
    }
    &__compartment {
      display: flex;
      margin-bottom: 24px;
    }
    &__compartment-info {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 4px;
      border: solid 1px #003965;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-right: 16px;
    }
    &__compartment-fuel-type {
      width: auto !important;
      height: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__compartment-fuel-type-number {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      width: 32px;
      height: 32px;
      text-align: center;
      background: white;
      color: $copec-dark-blue;
      border-radius: 50%;
      border: 1px solid $copec-grey;
      margin: 0;
    }
    &__compartment-fuel-volume {
      font-size: 12px;
      line-height: 1;
      color: white;
      font-weight: 700;
      background: $copec-dark-blue;
      text-align: center;
      height: 16px;
      display: inline;
    }
    &__compartment-field {
      flex-grow: 1;
      min-width: 0;
    }
    &__expanding-options-section {
      width: 100%;
      button,
      label {
        background: none;
        border: none;
        color: $copec-dark-blue;
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    &__options-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        margin-right: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__options-list {
      padding-left: 32px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }
    &__option-item {
      margin: 32px 0;
      display: inline-block;
      width: 100%;
      text-align: left;
      &:first-child {
        margin-top: 64px;
      }
    }
    &__options-input {
      opacity: 0;
      position: absolute;
      top: -1000px;
      left: -1000px;
      pointer-events: none;
      &:checked + .camion-mobile__options-list {
        max-height: 230px;
      }
    }
  }
}
