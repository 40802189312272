.historial-fluctuaciones{
  .table-separator-row-border-top{
    border-top: none;
    height: 6px;
  }
  .filters-container{
		width: 100%;
		margin-top: 24px;
		&.fluctuaciones{
			margin-top: 34px;
			margin-bottom: 0;
		}
		.main-button{
			margin-left: 35px;
		}
		.filter-input{
			width: 192px;
			&.margin-right-32{
				margin-right: 32px;
			}
			&.margin-right-40{
				margin-right: 40px;
			}
      &.arcoprime{
        width: 28px;
      }
		}
    .info-input{
      background: $copec-gris-input;
      padding: 9px 16px 8px;
      font-size: 12px;
      font-weight: 500;
      color: $copec-dark-blue;
      width: 160px;
      border-radius: 4px;
      &.arcoprime{
        width: 51px;
      }
    }
    .filter{
      &.arcoprime{
        width: 69px;
      }
    }
       
	}

	.actions-container{
		float: right;
		margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      width: 34px;
      margin-top: 5px;
      margin-left: 16px;
    }
	}

	.main-button{
		font-size: 12px;
		padding: 5px;
		width: 192px;
		height: 32px;
	}

  .historial-fluctuaciones-table {
    //width: 1147px;
    width: 1312px;
    .restante {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .th-data {
      width: calc(104px - 16px);
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.dinamo{
        width: calc(250px - 16px) !important;
      }
      &.th-select {
        width: 42px;
      }
      &.bigger {
        width: calc(150px - 16px);
      }
      &.validado{
        width: calc(84px - 16px);
      }
    }

    td {
      &.estado {
        .sap {
          font-size: 10px;
        }
      }
    }

    .document {
      cursor: pointer;
      position: relative;
      .documento-info {
        position: absolute;
        left: -100px;
        visibility: visible;
        background: white;
        border-radius: 4px;
        color: $copec-dark-blue;
        padding: 10px 11px;
        box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
        z-index: 10;

        &.recalculo {
          left: 100px;
          top: -70px;
        }
        .title {
          font-weight: bold;
          font-size: 10px;
          text-align: center;
          padding: 5px 0;
        }
        
        .footer {
          .important {
            color: #ed1d24;
          }
        }
      }
    }
  }
  .historial-fluctuaciones-table-container{
    .print-file-header{
      display: none;
  
      img{
        width: 170px;
        margin-bottom: 50px;
      };
      .title{
        font-size: 22px !important;
        color: $copec-dark-blue;
        font-weight: 700;
      }
      .title-separator{
        margin: 14px 0 24px 0;
      }
      .content-separator{
        margin: 14px 0;
      }
      .info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .col{
          width: 50%;
          display: flex;
          flex-direction: column;
          gap:8px;
        }
        .info-item{
          display: flex;
          .info-label{
            width: 136px;
            font-size: 12px;
            color:$copec-dark-blue;
            font-weight: 700;
          }
          .info-value{
            font-size: 12px;
            color:$copec-dark-blue;
            font-weight: 500;
          }
        }
      }
    }    
  }
  
}

.print-file-historial-fluctuaciones-fix{
  @media print{
    margin: 0 auto !important;
    padding: 0.8rem !important;
    
    img{
      width: 170px;
      margin-bottom: 50px;
    };
    .title{
      font-size: 22px;
      color: $copec-dark-blue;
      font-weight: 700;
    }
    .title-separator{
      margin: 14px 0 24px 0;
    }
    .content-separator{
      margin: 14px 0;
    }
    .info{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .col{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap:8px;
      }
      .info-item{
        display: flex;
        .info-label{
          width: 136px;
          font-size: 12px;
          color:$copec-dark-blue;
          font-weight: 700;
        }
        .info-value{
          font-size: 12px;
          color:$copec-dark-blue;
          font-weight: 500;
        }
      }
    }
  }    
}

.historial-fluctuaciones-table-media-fix-container{
  @media print {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.historial-fluctuaciones-table-media-fix {
  @media print {
    text-align: left;
    position: relative;
    top: 275px;
    left: 65px;    
    transform: scale(0.82);
    transform-origin: top left;

    tbody {
      tr {
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
            padding-left: 20px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
        }
      }
    }
    .th-data {
      width: calc(104px - 16px);
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.th-select {
        width: 42px;
      }
      .arrows{
        display: none;
      }
    }
    .react-datepicker__input-container {
      input {
        width: 175px !important;
      }
    }
    .filter-content .table-select {
      width: 180px !important;
    }
    .numeric-select {
      width: 180px !important;
      .comparision-type {
        padding-left: 5px;
      }
      input {
        width: calc(100% - 28px);
        padding-right: 2px;
        &.equal-selected {
          width: calc(100% - 21px) !important;
        }
        &.comparision-selected {
          width: calc(100% - 28px) !important;
        }
        &.all-selected {
          width: calc(100% - 17px) !important;
        }
      }
    }
  }
}


