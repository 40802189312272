@import "./variables";

@media screen and (max-width: $mobile-break-point) {
  .page {
    padding-left: 16px;
    padding-right: 16px;
  }
  .view {
    &-title {
      display: block;
      font-size: 22px;
      line-height: 40px;
    }
    &-title-separator {
      display: none;
    }
  }
  .header-data-container {
    display: flex;
    margin-top: 16px;
    margin-bottom: 7px;
    justify-content: space-between;
    line-height: 40px;
  }
  .header-data {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    float: none;
    width: 31.25%;
    margin: 0;
    display: flex;
    .left,
    .right {
      display: block;
      padding: 0;
      font-size: 12px;
      height: 40px;
      img{
        width: 40px;
      }
    }
    .right {
      align-self: flex-start;
      max-width: 100%;
      .title {
        display: none;
      }
      .info {
        text-align: left;
        font-size: 12px;
      }
    }
    .data{
      width: 100%;
    }
  }
  .header-box-multiple-container {
    column-gap: 8px;
    padding-bottom: 8px;
    &.pagosboxes {
        grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .header-data-container-multiple {
    padding: 4px 8px;
    margin: 0;
    display: grid;
    position: relative;
    &.first,
    &.last {
      margin: 0;
    }
    .monto-total-title {
      font-size: 10px;
      line-height: 16px;
    }
    .col.full {
      padding: 0;
      .text-container {
        padding-left: 28px;
        .monto-total-data {
          display: none;
        }
      }
    }
    .col {
      .monto {
        text-align: center;
        margin-bottom: 4px;
      }
      .col-left {
        position: absolute;
        left: 8px;
        top: 8px;
        margin: 0;
        padding: 0;
        .img-container {
          padding: 0;
          width: 24px;
          height: auto;
          img {
            width: 24px;
          }
        }
      }
    }
  }

  .header-data-container-single {
    display: block;
    padding: 16px;
    margin: 0;
    .col {
      display: block;
      .col-left {
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid #dee4ea;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        .img-container {
          padding-right: 16px;
          display: block;
        }
        .text-container {
          display: flex;
          justify-content: space-between;
          flex-grow: 1;
        }
      }
      .separator {
        display: none;
      }

      .col-center {
        margin: 0;
        padding: 16px 0;
        text-align: center;
        border-bottom: 1px solid $copec-light-grey;
      }
      .col-right {
        padding-top: 16px;
        margin-left: 0;
        text-align: center;
      }
    }
    .monto-total-title,
    .monto-total-data {
      font-size: 16px;
    }
    & + hr {
      display: none;
    }
  }

  .filters-container {
    margin: 16px 0;
    background-color: $copec-background-filters;
    border-radius: 4px;
    padding: 16px;
    display: block;
    .filter {
      float: none;
      width: 100%;
      .filter-title {
        font-size: 16px;
      }
      .filter-input .search {
        margin: 24px 0 0;
        width: 100%;
        background-color: white;
      }
    }
    .eds-select {
      width: 100%;
    }
  }
  .page-main-content {
    position: relative;
    overflow-y: auto;
    padding: 0 16px 16px;
    margin: -16px -16px 0;
    min-height: 400px;
    .sticky-page-part {
      position: sticky;
      top: 0;
      background: white;
      z-index: 11;
      padding-top: 16px;
    }
  }
  .actions {
    display: none;
  }

  .mobile-table {
    margin-top: 16px;
  }

  .mobile-table__item {
    padding: 8px 16px;
    background: $sidebar-grey;
    border-radius: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    &-name {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      margin: 0;
      color: $copec-dark-blue;
      padding: 0;
    }
    &-content {
      display: flex;
      justify-content: space-between;
    }
    &-content-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 8px;
      justify-content: space-between;
    }
    &-content-list-item {
      width: 47%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &-content-list-item-3-columns{
      width: 30%;
    }

    &-content-list-item-title {
      font-size: 10px;
      margin: 0;
      line-height: 16px;
      color: $copec-grey;
    }
    &-content-list-item-value {
      font-size: 14px;
      margin: 0;
      line-height: 24px;
      color: $copec-dark-blue;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.link {
        cursor: pointer;
        text-decoration: underline;
      }
      &.red {
        color: $copec-red;
      }
      &.green {
        color: $copec-green;
      }
    }
    &-button {
      background: none;
      border: none;
      display: flex;
//      padding: 8px;
margin-top: -10px;
      order: 1;
      img {
        transition: transform 0.3s ease-in-out;
      }
      &.expanded-section {
        img {
          transform: rotateZ(180deg);
        }
      }
    }
    &-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-name-container {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      padding: 0;
      & > * {
        width: 44%;
      }
      .mobile-table__item-content-list-item {
        width: 100%;
        padding-left: 8px;
      }
    }
  }
  .scroll-top {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $copec-dark-blue;
    position: fixed;
    bottom: 72px;
    right: 16px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .mobile-filters {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 8px 16px;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid $copec-light-grey;
      h1 {
        font-size: 18px;
        line-height: 32px;
        color: $copec-dark-blue;
        margin: 0;
      }
    }
    &__close-btn {
      background: none;
      border: none;
      display: flex;
      margin: 0 0px 0 0;
      padding: 8px;
    }
    &__footer {
      border-top: 1px solid $copec-light-grey;
      padding: 24px 16px;
      .main-button {
        width: 100%;
        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
    &__main-section {
      overflow: auto;
      padding: 24px 16px;
    }
    &__choice {
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &-name {
        margin: 0 0 8px 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: $copec-dark-blue;
      }
      &-trigger {
        padding: 4px 16px;
        border: 1px solid $copec-dark-blue;
        border-radius: 4px;
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        color: $copec-dark-blue;
        width: 100%;
        font-weight: 500;
        &:disabled {
          background-color: $copec-gris-input;
          border-color: $copec-gris-input;
          padding: 8px 16px;
          color: $copec-grey;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-transform: capitalize;
        }
        .selected & {
          border-color: $active-select-border;
          border-width: 2px;
          &:disabled {
            background-color: $copec-gris-input;
            border-color: $copec-gris-input;
            padding: 8px 16px;
            color: $copec-grey;
          }
        }
      }
    }
    &__choice-view {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: white;
      height: 100%;
      display: flex;
      flex-direction: column;
      &-title {
        font-size: 18px;
        line-height: 32px;
        font-weight: 700;
        padding: 12px 16px;
        border-bottom: 1px solid $copec-light-grey;
        margin: 0;
        color: $copec-dark-blue;
      }
      &-option {
        padding: 32px 0;
        border-bottom: 1px solid $copec-light-grey;
        &-name {
          border: none;
          background: none;
          padding: 0;
          font-size: 18px;
          line-height: 32px;
          color: $copec-dark-blue;
          font-weight: 500;
        }
        &-input,
        .react-datepicker-wrapper input {
          display: block;
          border: solid 1.5px $active-select-border;
          border-radius: 4px;
          padding: 8px;
          font-size: 12px;
          line-height: 16px;
          box-sizing: border-box;
          -ms-appearance: none;
          -o-appearance: none;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: textfield;
          color: $copec-dark-blue;
          width: 100%;
        }
        &-input {
          margin-top: 40px;
          width: 280px;
          margin-right: 16px;
          margin-left: 16px;
        }
      }
      &-options {
        flex-grow: 1;
        overflow: auto;
        padding: 0 16px;
      }
      &-footer {
        text-align: center;
        padding: 12px 16px;
        border-top: 1px solid $copec-light-grey;
      }
      &-close {
        width: 40px;
        height: 40px;
        background: $copec-dark-blue;
        border-radius: 50%;
        border: none;
        padding: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .push-up {
    margin-bottom: 24px;
  }

  .mobile-select {
    margin-top: 24px;
    &__opened-view {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: white;
    }
  }
  .resumen-mobile-col-center {
    font-size: 22px !important;
  }
  .resumen-mobile-red-button {
    font-size: 16px;
    padding: 14px 44px;
    border-radius: 36px;
  }
  .copec-stepper {
    width: auto;
    flex-grow: 1;
    margin-top: 0;
    margin-right: 4px;
    margin-left: 32px;
  }

  .filters-container {
    .filter {
      .filter-input {
        .search {
          padding: 7px 0 8px 26px;
          &:placeholder-shown {
            background-size: 26px;
            background-position: right 3px center;
          }

          &:not(:placeholder-shown) {
            background-position: right 3px center;
          }

          &::-webkit-search-cancel-button {
            background-position: right 0 center;
          }
        }
      }
    }
  }

  .separator-table-row-mobile{
    padding: 4px !important;
  }
}
