.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  
    li{
      display: inline;
    }
  
    li:first-child > a, li:first-child > span{
      margin-left: 0;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  
    .disabled{
      span, span:hover, span:focus, a, a:hover, a:focus {
        color: #777777;
        background-color: #fff;
        border-color: #ddd;
        cursor: not-allowed;
      }
    }
  
    li > a, li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.42857143;
      text-decoration: none;
      color: $copec-dark-blue;
      background-color: #fff;
      margin-left: -1px;
    }
  
    .active{
      a, span, a:hover, span:hover, a:focus, span:focus {
        z-index: 3;
        font-weight: bold;
        cursor: default;
      }
    }
  
    .prev, .next{
      color: $copec-dark-blue;
      font-size: 20px;
      margin-top: -2px;
      font-weight: bold;
    }
  
    .prev:after{
      content: url(../assets/img/chevron-left.svg);
    }
    .next:after{
      content: url(../assets/img/chevron-right.svg);
    }
  }
  
  .pagination-container{
      text-align: center;
      padding-bottom: 200px;
  }
  .pagination{
  
      a{
          width: 40px;
          height: 31px;
          padding: 9px 0 0 !important;
          text-align: center;
          border-radius: 6px;
          font-weight: bold;
          font-size: 14px;
      }
      .active a{
          background: #f1f5f8;
      }
  }
  
  