.fluctuaciones-ingreso-mediciones {
  .page-options {
    align-items: flex-start;
    &.second-row-options {
      padding-top: 0;
      align-items: flex-end;
      .filters-container {
        margin-top: 0;
      }
    }
    .filter-input .timepicker__input {
      min-height: 38px;
    }
  }
  &-stepper {
    width: 450px;
    margin-top: 10px;
    .copec-stepper__step-name {
      font-weight: 500;
      min-width: 70px;
    }
  }
  &-small-section {
    width: 640px;
    margin: 0 auto;
  }
  &-medium-section {
    width: 865px;
    margin: 0 auto;
  }
  &-big-section {
    width: 1088px;
    margin: 0 auto;
  }
  .flutuaciones-table {
    th {
      width: 100%;
      &.no-padding-left-th{
        padding-left: 0 !important;
      }
      &.mov-bodega-padding-left-th-first{
        padding-left: 27px;
        width: 213px;
      }
      &.mov-bodega-padding-left-th{
        padding-left: 21px;
        width: 212px;
      }
    }
  }
  .tanks-table {
    td,
    th {
      &:first-child {
        padding-left: 24px;
      }
    }
    td,
    th {
      &:last-child {
        padding-right: 24px;
      }
    }
    .measurement-column {
      min-width: 185px;
    }
    td {
      white-space: normal;
    }
    .tank-name-cell {
      padding-right: 0;
    }
    .container-cell {
      min-width: 315px;
      padding-left: 0;
      .small-camion-compartment {
        margin-right: 8px;
      }
      &-content {
        display: flex;
        align-items: center;
      }
    }
    .remove-row-span-border {
      td[rowspan] {
        &::after {
          display: none;
        }
      }
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding-top: 32px;
    border-top: 1px solid $copec-separator;
    .main-button {
      margin: 0 16px;
    }
  }
  .main-button {
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    width: 160px;
    text-align: center;
    &:disabled {
      border-color: #bdbdbd;
      &.hallow {
        color: #bdbdbd;
        background: #fff !important;
      }
    }
    &.red {
      color: $copec-red;
      border-color: $copec-red;
      &:not(.hallow) {
        background: $copec-red;
        color: white;
      }
    }
  }
  .allocation-table {
    width: auto;
    td,
    th {
      padding-left: 24px;
      padding-right: 24px;
      position: relative;
    }
    .container-cell {
      padding-left: 24px;
      min-width: 260px;
    }
    .remove-row-span-border {
      td[rowspan] {
        vertical-align: top;
        padding-top: 15px;
      }
    }
    .row-with-border td:first-child::after {
      left: 0;
    }
    .table-select {
      width: 100%;
      min-width: 115px;
    }
    .tight-row {
      td {
        &:not(:first-child) {
          padding-left: 8px;
        }
        &:not(:last-child) {
          padding-right: 8px;
        }
      }
    }
  }
  .calibrations-table {
    .input-with-clear-btn {
      width: 100px;
    }
  }
  .cellar-movement-table {
    td {
      min-width: 70px;
      /* padding-left: 20px; */
      padding-right: 17px;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 40px;
      }
    }
    .table-select {
      width: 100%;
    }
    .remove-row-btn {
      right: 16px;
    }
    .container-cell-content{
      display: flex;
      gap:8px;
      align-items: center;
        }
    .text-input{
      background-color: #f1f5f8;
      padding: 4px 16px;
      border-radius: 4px;
    }
  }
  .actions .main-button:not(:last-child) {
    margin-right: 32px;
  }
  .confirmation-table {
    th {
      /* min-width: 40px; */
      padding: 8px 7px;
    }
    td{
      padding-right: 0px;
    }
    td.cierre-diario-align-right{
      padding-right: 7px !important;
    }
    th:last-child{
      padding-right: 16px;
      padding-left: 8px;
    }
  }
}

.small-camion-compartment {
  width: 32px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px $copec-dark-blue;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: left top;
  &.cont2 {
    &.sp93 {
      background-image: url("../assets/img/93sp2.png");
    }
    &.sp95 {
      background-image: url("../assets/img/95sp2.png");
    }
  }
  &.cont3 {
    &.sp93 {
      background-image: url("../assets/img/93sp3.png");
    }
    &.sp95 {
      background-image: url("../assets/img/95sp3.png");
    }
  }

  &.cont4 {
    &.sp93 {
      background-image: url("../assets/img/93sp4.png");
    }
    &.sp95 {
      background-image: url("../assets/img/95sp4.png");
    }
  }

  &.cont5 {
    &.sp93 {
      background-image: url("../assets/img/93sp5.png");
    }
    &.sp95 {
      background-image: url("../assets/img/95sp5.png");
    }
  }
  &.sp97 {
    background-color: #97d700;
  }

  &.pd {
    background-color: #545859;
  }

  &.k {
    background-color: #004b87;
  }

  &__fuel-type-number {
    font-size: 12px;
    font-weight: 700;
    line-height: 23px;
    width: 23px;
    height: 23px;
    text-align: center;
    background: white;
    color: $copec-dark-blue;
    border-radius: 50%;
    border: 1px solid $copec-grey;
    margin: 0;
    &.gas{
      font-size: 10px;
    }
  }
}

.fim {
  &-modal{
    min-width: 800px;
    overflow-y: scroll;
  }
  &-table {
    max-height: 540px;
    margin: 48px auto 0;
    display: block;
    width: fit-content;
    //box-sizing: border-box;
    overflow-y: auto;
    scrollbar-color: $copec-grey transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: $copec-grey;
      border-radius: 5px;
      width: 5px;
    }
    th {
      min-width: 120px;
      white-space: nowrap;
      position: sticky;
      top: -8px;
      z-index: 1;
    }
    thead{
      tr{
        background: $copec-dark-blue !important;
        box-sizing: unset !important;
        border-collapse: collapse;
        border-spacing: 0 !important;
      }
    }
    tbody{
      tr{
        background: white;
        .horizontal-separator{
          background: $copec-table-background !important;
        }
      }
    }
    & + .fluctuaciones-ingreso-mediciones-actions {
      margin-top: 0;
      border-top: 0;
      margin-bottom: 16px;
    }
  }
}

.fluctuaciones-ingreso-mediciones-big-section{
  display: inline-block;
  margin-bottom: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
}
