.cartola {
  .filter-content .table-select {
    width: 87px;
  }

  .cartola-table {
    &.pagos {
      width: 1312px;

      .numeric-select .numeric-select-options-container{
        width: 185px;
      }


      tbody{
        max-height: 597px;
        overflow-y: scroll;
        overflow-x: hidden;
        display: block;
        td{
          &.td-pagos-62{
            width: 49px !important;
          }
          &.td-pagos-121{
            width: 108px !important;
          }
          &.td-pagos-123{
            width: 110px !important;
          }
          &.td-pagos-124{
            width: 111px !important;
          }
          &.td-pagos-248{
            width: 235px !important;
          }
        }
      }
    }
    .numeric-select {
      width: 119px !important;
      .comparision-type {
        padding-left: 5px;
      }
      input {
        width: calc(100% - 28px);
        padding-right: 2px;
        &.equal-selected {
          width: calc(100% - 21px) !important;
        }
      }
    }

    th:last-child{
      border-right: 0 !important;
    }

    td{
      span{
        width: 100px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        display:inline-block;
      }
      .highlighter-container{
        width: 100px;
      }
      &.cartola-table-tipo-movimiento{
        width: 280px !important;
        .highlighter-container{
          width: 280px !important;
          span{
            width: 280px;
          }
        }
      }

      &.cartola-table-tipo-movimiento-pagos{
        width: 200px !important;
        .highlighter-container{
          width: 200px !important;
          span{
            width: 200px;
          }
        }
      }
    }

    .datepicker-tooltip {
      visibility: hidden;
      width: 132px;
      background-color: white;
      text-align: center;
      padding: 10px;
      border-radius: 4px;
      position: absolute;
      left: 20px;
      top: 40px;
      box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
      z-index: 1;
      .datepicker-tooltip-title{
        display: inline-block;
        color: $copec-dark-blue;
        font-size: 10px;
        margin-bottom: 10px;
        font-weight: 800;
      }
      .datepicker-tooltip-content{
        background: $copec-gris-input;
        color: $copec-dark-blue;
        font-size: 10px;
        padding: 8px 4px;
        border-radius: 4px;
      }
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .datepicker-tooltip-container:hover .datepicker-tooltip {
      visibility: visible;
    }
  }

  .cartola-container {
    min-height: 400px;
    padding-bottom: 200px;
    @media screen and (max-width: $mobile-break-point) {
      padding-bottom: 30px;
    }
  }

  .cartola-options {
    max-width: 1312px;
  }
  .pagos-options {
    max-width: 1312px;
    //&.arcoprime{
    //  max-width: 1200px;
    //}
  }
  .filters-container.mobile {
    display: none;
  }
  @media screen and (max-width: $mobile-break-point) {
    .filters-container-mobile-wrapper {
      position: sticky;
      top: 99px;
      padding: 0;
      background: white;
      z-index: 1;
      border-top: 8px solid white;
      border-bottom: 8px solid white;
    }
    .filters-container {
      &.mobile {
        display: flex;
        align-items: center;
        margin: 0;
      }
      &:not(.mobile) {
        display: none;
      }
      padding: 8px;
      .filter .filter-input .search {
        margin-top: 0;
        &:not(:placeholder-shown):hover {
          background-color: #fff;
        }
      }
      .selectingInput {
        margin: 0 16px 0 0;
      }
      .filter-button {
        width: 32px;
        height: 32px;
        background: white;
        border: 1px solid $copec-dark-blue;
        margin-left: 16px;
        display: block;
        border-radius: 50%;
        flex-shrink: 0;
        padding: 0;
        img {
          width: 100%;
        }
        &.two-icons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 10px;
            margin: 2px 0;
            filter: brightness(0) saturate(100%) invert(18%) sepia(22%)
              saturate(4393%) hue-rotate(181deg) brightness(93%) contrast(104%);
          }
        }

        &.active{
          box-shadow: 0 0 2px 1px #0e69af;
        }
      }
    }
    .mobile-table {
      margin-top: 8px;
    }
    .mobile-table__item {
      max-height: 80px;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
      &.open {
        max-height: 185px;
      }
      &-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &-content-list {
        flex-grow: 1;
        padding: 0;
      }

      &-bottom-section {
        margin-top: 8px;
        border-top: 1px solid $copec-separator;
        padding-top: 8px;
        padding-left: 24px;
        padding-right: 29px;
        .pagos & {
          padding-left: 44px;
          padding-right: 29px;
        }
        .mobile-table__item-content-list-item {
          width: 48%;
          margin-bottom: 8px;
          &:nth-last-of-type(-n + 2) {
            margin-bottom: 0;
          }
        }
        .mobile-table__item-content-list-item-value {
          font-weight: 500;
        }
      }
    }
    .mobile-actions {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: white;
      padding: 8px 16px;
      box-sizing: border-box;
      .main-button {
        width: 100%;
      }
    }
    .important-message {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-break-point) {
  .mobile-action-modal {
    .main-button {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    .important-message {
      position: absolute;
      top: 0;
      transform: translateY(calc(-100% - 16px));
      left: 16px;
      width: calc(100% - 32px);
      background: #fde8ea;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 12px 16px;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      .error-icon {
        flex-shrink: 0;
        margin-right: 8px;
        padding: 0;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
}
.header-box-multiple-container {
  //display: inline-block;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  &.cartolaboxes {
    max-width: 1382px;
  }
  &.pagosboxes {
    max-width: 1382px;
  }
}

.th-text {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cartolafix {
  .filter-content .table-select {
    width: 87px;
  }

  .tipo-movimiento-table-filter{
    .filter-content .table-select {
      width: 187px !important;
    }
  }
  .tipo-movimiento-pagos-table-filter{
    .filter-content .table-select {
      width: 157px !important;
    }
  }

  .numeric-select {
    width: 119px !important;
    .comparision-type {
      padding-left: 5px;
    }
    input {
      width: calc(100% - 28px);
      padding-right: 2px;
      &.equal-selected {
        width: calc(100% - 21px) !important;
      }

      &.comparision-selected {
        width: calc(100% - 28px) !important;
      }
    }
  }

  .numeric-select {
    width: 68px;
  }

  .comparision-type.all-selected {
    width: 96px !important;
  }

  @media print {
    .react-datepicker__input-container {
      input {
        width: 116px !important;
      }
    }
  }
}
