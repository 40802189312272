table {
  background: $copec-table-background;
  padding: 4px 8px 8px;
  margin-top: 9px;
  border-collapse: collapse;
  display: inline-block;
  border-radius: 6px;
}
tbody{
  background-color: white;
}
th {
  color: white;
  font-size: 12px;
  text-align: left;
  border: 4px solid $copec-table-background;
  border-bottom: none;
  &:first-child{
    border-left: 0;
  }
  &:last-child{
    border-right: 0;
  }
  .th-data {
    border-radius: 6px;
    background: $copec-dark-blue;
    padding: 16px 21px 15px;
    width: calc(211px - 42px);
    &.selecc-col {
      width: 66px !important;
    }
  }
}

td{
  border: none !important;
  font-weight: 800;
}

.table-separator-row-border-top{
  background: $copec-table-background;
  border-top: 4px solid $copec-table-background;
}

.table-separator-row-border-bottom{
  background: $copec-table-background;
  border-bottom: 4px solid $copec-table-background;
}

td.align-left {
  text-align: left;
  padding-left: 15px !important;
  width: 100px;
  text-overflow: ellipsis;
  &.trseparator{
    padding-left: 8px !important;
  }
  .table-data-ellipsis{
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    /* Estilo para modulo mantenimiento */
    &.ot{
      width: 374px;
    }

    &.aviso{
      width:164px;
    }
    
  }
}

td.align-left-empty{
  padding-left: 15px !important;
}

td.table-link {
  text-decoration: underline;
  cursor: pointer;
}

td.align-right {
  text-align: right;
  padding-right: 15px !important;
  &.negative {
    color: $copec-red;
  }
  &.green {
    color: $copec-green;
  }
  &.abonos-no-compensados{
    color: $copec-green;
  }
}

th.align-right{
  text-align: right;
}

td.liberar-radio {
  text-align: center;
}

tr {
  font-size: 12px;
  color: $copec-dark-blue;
  font-weight: 500;
}

tr.data:nth-child(even) {
  background-color: #f0f5f8;
  &.selected{
    background-color:rgba(16,128,213,0.15);
  }
}

tr.data:nth-child(odd) {
  background-color: white;
  &.selected{
    background-color:rgba(16,128,213,0.24);
  }
}

tr.data td {
  padding: 13px 0 12px;
}

tr.total {
  background-color: white;
  font-weight: bold;
  td {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .align-right {
    text-decoration: underline;
    &.nodecoration{
      text-decoration: none;
    }
  }
}

.filter-content {
  padding-left: 3px;
  .table-select {
    border: none;
    width: 160px;
  }
  &.no-padding-left{
    padding-left: 0;
  }
  img {
    position: absolute;
    top: 0;
    right: 3px;
  }
}

.filters-row td {
  padding-bottom: 9px;
  position: relative;
  vertical-align: top;
}

.cartola-table {
  .tipo-movimiento-pagos-table-filter{   
    width: 227px !important; 
    .table-select{
      width: 227px !important;
    }
  }

  .th-data {
    width: calc(117px - 16px);
    padding: 0 8px;
    position: relative;
    height: 46px;
    &.th-select{
      width: 42px;
    }
    &.th-data-tipo-movimiento{
      width: 288px !important;
      &.th-select{
        width: 167px !important;
      }
    }

    &.th-data-tipo-movimiento-pagos{
      width: 226px !important;
      &.th-select{
        width: 107px !important;
      }
    }
  }
  &.cheque-en-linea-table {
    .th-data {
      min-width: calc(131px - 26px);
      width: unset !important;
    }
  }

  .filter-content {
    &.active {
      .numeric-select {
        input.equal-selected,
        input.comparision-selected,
        input.all-selected {
          border: solid 1px #6cbfff;
          border-left: none;
        }
        .comparision-type {
          border: solid 1px #6cbfff;
          border-right: none;
        }
      }
    }
  }

  .numeric-select {
    width: 68px;
  }

  .comparision-type.all-selected {
    width: 96px !important;
  }

  .document {
    cursor: pointer;
    position:relative;
    .documento-info {
      position: absolute;
      left:-200px;
      visibility: visible;
      background: white;
      border-radius: 4px;
      color: $copec-dark-blue;
      padding: 20px 11px;
      box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
      z-index: 10;
      .title {
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        padding: 5px 0;
      }
      .content {
        //background: $sidebar-grey;
        //padding: 7px 6px;
        table{
          .headers{
            th{
              color:$copec-dark-blue;
            }
          }
          .data{
            td{
              padding: 10px;
              white-space:nowrap;
            }
          }
        }
      }
      .footer {
        .important {
          color: #ed1d24;
        }
      }
    }

    /* &:hover {
      .documento-info {
        visibility: visible;
      }
    } */
  }
}
