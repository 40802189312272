@import "./variables";

.prefactura-main-container{
  .prefactura-container{
    max-width: 192px;
    margin: 0 auto;
    margin-top: 40px;
    .filters-container{
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    hr{
      margin-bottom: 32px;
    }
    .button-area{
      display: flex;
      flex-direction: column;
      gap: 16px;
      .limpiar-button{
        background-color: white !important;
      }
      .limpiar-button:disabled{
        color: #BDBDBD !important;
        border: 1px solid #BDBDBD !important;
      }
      .cargar-button{
        color: white !important;
      }
      .cargar-button:disabled{
        border: none !important;
      }
      .main-button{
        margin: 0px !important;
        width: 192px;
      }
    }
  }
  .entrada-datos-main-container{
    width: 1312px;
    margin-top: 16px;
    .titulo-steper-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titulo-container{
        display: flex;
        flex-direction: column;
        gap: 9px;
        .titulo-step{
          font-weight: 700;
          font-size: 18px;
          line-height: 22.5px;
          color: $copec-dark-blue;
        }
        .step-numero{
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #7C97AB;
        }
      }
      .prefactura-stepper{
        width: 280px;
        margin-right: 0px;
      }
    }
    .servicio-data-container{
      width: 864px;
      margin: 0 auto;
      margin-top: 32px;
      .filters-container{
        display: flex;
        gap: 32px;
        .disabled-input{
          width: 192px;
          border: none;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #003965;
        }
      }
    }
    .entrada-datos-table-container{
      width: 864px;
      margin: 0 auto;
      .entrada-datos-table{
        width: 100%;
        margin: 0 auto;
        position: relative;
        margin-top: 12px;
        border-spacing: 0;
        border-collapse: collapse;
        // padding-left: 13px;
        // padding-right: 8px;
        padding: 4px 7px 8px !important;
        .head-trow{
          background-color: #003965;
          th{
            background-color: #003965;
            border: none;
            padding-right: 1px;
          }
          th:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
          }
          th:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
          }
        }
        .th-data{
          margin-right: 2px;
          padding: 0;
          &.width-88{
            width: 88px;
          }
          &.width-120{
            width: 120px;
          }
          &.width-136{
            width: 136px;
          }
          &.width-163{
            width: 163px;
          }
          &.width-196{
            width: 196px;
            margin: 0 !important;
          }
          &.width-197{
            width: 197px;
          }
          &.width-280{
            width: 280px;
          }
        }
        .th-text{
          padding: 17px 8px 16px;
          position: relative;
          transform: none;
          &.two-lines{
            padding: 9px 8px;
          }
        }
        tbody{
          .data{
            .no-data{
              padding-left: 8px
            }
            td{
              width: auto;
              .filters-container{
                .filter{
                  input{
                    max-width: 52px;
                    text-align: right;
                    padding: 8px 16px;
                    border: 1px solid #7C97AB;
                    border-radius: 4px;
                    color: #003965;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 500;
                  }
                  input:disabled{
                    background-color: #F1F5F8;
                    border: none;
                  }
                }
              }
              
            }
            .documentacion{
              color: #005190;
            }
            
            &.table-link{
              text-decoration: underline;
              cursor: pointer;
            }
            &.row-with-rounded-borders{
              td:first-child {
                border-top-left-radius: 4px !important;
                border-bottom-left-radius: 4px !important;
              }
              td:last-child {
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
              }
            }
          }
          .table-separator-row-border-top{
            border: none !important;
            height: 8px;
          }
          .aviso{
            td{
              padding: 12px 16px;
              color: #003965;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              span{
                font-size: 12px;
                color: #EA212E;
                font-weight: 700;
                line-height: 16px;
              }

            }
          }
        }
      }
    }
    .observaciones-container{
      width: 864px;
      margin: 0 auto;
      margin-top: 24px;
      margin-bottom: 24px;
      .observaciones-titulo{
        color: #003965;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      textarea{
        box-sizing: border-box;
        resize: none;
        width: 100%;
        height: 64px;
        border: 1px solid #7C97AB;
        border-radius: 4px;
        padding: 8px 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
    hr{
      width: 864px;
      margin: 0 auto;
      margin-bottom: 32px;
    }
    .button-area{
      width: 864px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      gap: 32px;
      button{
        margin: 0;
      }
    }
  }
}