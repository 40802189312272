.numeric-select{
	width: 170px;
	input{
		width: 135px;
		border: none;
		border-radius: 3px;
		padding: 10px 15px;
		font-size: 12px;
		color: $copec-dark-blue;
		font-weight: 500;
		float: left;
		&.equal-selected{
			width: 130px;
			padding-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&.comparision-selected{
			width: 118px;
			padding-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&.all-selected{
			width: 102px !important;
			padding-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&::placeholder{
			color: $copec-dark-blue;
		}
		&:focus::placeholder {
		  color: white !important;
		}
	}

	.numeric-select-options-container{
		position: absolute;
		top: 38px;
		background: white;
		width: 100%;
		border: 1px solid red;
		width: 200px;
		font-size: 12px;
		font-weight: 500;
		border: 1px solid $copec-grey-3;
		border-radius: 4px;
	}
	.numeric-select-option{
		padding: 11px 21px;
		cursor: pointer;
		&:hover{
			background: $sidebar-grey;
		}
	}

	.comparision-type{
		background: white;
		padding: 10px 5px 10px 15px;
		float: left;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		font-size: 12px;
		color: $copec-dark-blue;
		font-weight: 500;
		&.all-selected{
			width: 2px !important;
			color: white;
			border-radius-left: 3px;
			padding-right: 5px;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	input[type=number] {
	  -moz-appearance: textfield;
	}

}
