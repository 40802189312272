@import "./variables";

.cierre-diario {
  .actions {
    .main-button {
      &.iconbutton {
        img {
          margin-left: 0;
        }
        display: flex;
        gap: 5px;
        align-items: center;
        width: 192px;
        justify-content: center;
        &.no-mr{
          margin-right: 0;
        }
      }
    }
  }

  .cierre-diario-table {
    th .th-data {
      padding: 16px 11px 17px;
      width: calc(99px - 22px);
      &.two {
        padding: 9px 11px 9px;
        display: flex;
        align-items: center;
      }
      .th-text {
        top: unset;
        position: unset;
        transform: unset;
        .arrows {
          margin-right: 0;
        }
      }
    }
    tbody {
      .data {
        td.align-left {
          width: 87px;
          &.icon-container {
            text-align: center !important;
            img {
              margin-right: 10px;
              text-align: center;
            }
          }
          // &.state-container{
          //   display: flex;
          //   align-items: center;
          // }
        }
      }
    }
  }
  .pill {
    background-color: rgba(16, 128, 213, 0.1);
    padding: 3px 8px 3px 0px;
    width: calc(106px - 16px);
    border-radius: 16px;
    margin-right: 0px !important;
  }

  .calibrations-table {
    .calibraciones-material-cell {
      width: 150px !important;
      min-width: 150px;
    }
    .input-with-clear-btn {
      width: 153px;
      input {
        text-align: right;
      }
    }
    .calibraciones-table-input {
      width: 153px;
      height: 32px;
      text-align: right;
      padding-right: 12px;
    }
    .pill {
      text-align: right;
      height: 24px;
      padding: 0 16px;
      width: 121px;
    }
    td {
      padding-right: 0;
    }
    .diferencia-row {
      padding-right: 16px !important;
    }
  }

  .cierre-diario-options {
    .page-options{
      display: flex;
      align-items: flex-end;
    }
    
    .title {
      color: $copec-dark-blue;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .title-separator {
      width: 191px;
      color: #dee5ea;
    }
    .actions{
      display: flex;
      margin-bottom: 4px;
      button{
        height:38px;
        white-space: nowrap;
        padding: 0 10px;
        width: 180px;
      }
      .main-button{
        margin-left:40px;
      }
      img{
        margin-left: 7px;
      }
    }
  }

  .cierre-estados {
    width: 864px;
    margin: 0 auto;
    .title {
      color: $copec-dark-blue;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .title-separator {
      width: 191px;
      color: #dee5ea;
    }
  }

  .page-options {
    .filters-container {
      .step-title {
        color: $copec-dark-blue;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 6px;
      }
      .step-number {
        color: #7c97ab;
        font-weight: 700;
        font-size: 16px;
      }

      .filter-input-element {
        color: $copec-dark-blue;
        font-weight: 500;
      }
    }
  }

  .ventas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ventas-separator {
      margin: 24px 0 32px 0;
      background-color: #dee5ea;
      width: 1046px;
    }

    .ventas-actions {
      display: flex;
      gap: 32px;
    }

    .important-message {
      width: 985px;
      margin-top: 8px;
      background-color: rgba(234, 33, 46, 0.1);
      .error-icon {
        font-size: 13px;
        padding: 0 6px;
        border-radius: 100%;
        margin-right: 10px;
      }

      .text {
        color: #ea212e;
        font-weight: 700;
      }
    }
  }

  .ventas-in-fluctuaciones{
    margin-top: 14px;
    display: block;
  }

  .ventas {
    width: 1046px;
    border-collapse: inherit;
    margin-top: 0px !important;
    border-spacing: 0;
    font-size: 12px;
    padding-top: 8px;

    .table-header {
      display: flex;
      margin-bottom: 5px;
      justify-content: space-between;
      .legend {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        margin-left: 8px;
        .material {
          display: flex;
          gap: 8px;
          .rect {
            width: 8px;
            height: 16px;
            border-radius: 2px;
            background-color: #0e69af;
            &.concesionado {
              background-color: #07d188;
            }
          }
          .text {
            font-weight: 700;
            color: $copec-dark-blue;
          }
        }
      }
      .selector {
        display: flex;
        gap: 11px;
        margin-right: 10px;
        .text {
          font-weight: 700;
          color: $copec-dark-blue;
        }
        .switch {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 16px;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #7c97ab;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 14px;
          width: 14px;
          left: 1px;
          bottom: 1px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
        input:checked + .slider {
          background-color: #7c97ab;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(14px);
          -ms-transform: translateX(14px);
          transform: translateX(14px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
    }
    thead {
      width: 100%;
      background: $copec-dark-blue;
      tr {
        background: $copec-dark-blue;
        th {
          font-weight: 800;
          white-space: nowrap;
          border-right: 1px solid $copec-dark-blue;
          padding-left: 40px;
          height: 24px;
          line-height: 1.33;
        }
      }
    }
    th,
    tfoot td {
      border: 0;
      font-weight: bold;
      line-height: 1.6;
      &:first-child {
        padding: 12px 10px 12px 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        padding: 12px 15px 12px 15px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &[colspan="3"] {
        padding: 5px;
      }
    }
    td {
      background: white;
    }
    tbody {
      .container-cell {
        &.align-left {
          padding-left: 8px !important;
          padding-right: 0px;
        }
        .type {
          width: 8px;
          height: 104px;
          border-radius: 2px;
          &.concesionado {
            background-color: #07d188;
          }
          &.consignado{
            background-color: #0e69af;
          }
          &.ambos{
            background: linear-gradient(180deg, #0e69af 50%, #07d188 50%);
          }
        }
        .container-cell-content {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-right: 20px;
        }
        .material {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          margin-left: 5px;
          width: 65px;
        }
      }

      td {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-right: 10px;
        border-bottom: 1px solid $copec-light-grey;
        white-space: nowrap;
        &.align-right {
          padding: 12px 8px 12px 40px !important;
          &.no-mr {
            padding-right: 0px !important;
          }
          &.tdcontent {
            width: 157px;
            display: flex;
            justify-content: space-between;
          }
        }
        &.small-padding-left {
          padding-left: 8px !important;
        }
      }

      tr {
        td:last-child {
          padding: 12px 15px !important;
          &.tdsep {
            padding: 0 15px !important;
          }
          &.cierre-diario-ventas-last-column {
            padding: 8px !important;
            border: none !important;
          }
        }
        &:nth-child(2) {
          td {
            border-bottom: 1px solid #dee5ea !important;
          }
        }
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
            &.total {
              padding-left: 40px !important;
            }
          }
          td:last-child {
            border-top-right-radius: 4px;
            margin-right: 10px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
          //width: 40%;
        }
        &.data {
          &.subtotal {
            td:first-child,
            td:last-child {
              font-weight: bold;
            }
            td {
              font-size: 14px;
            }
          }
        }
        &.cierre-diario-ventas-last-column {
          padding: 8px !important;
          border: none !important;
        }
      }
    }
    tfoot td {
      font-size: 14px;
      &:last-child {
        text-align: right;
      }
    }
  }
}

.cierre-diario-mov-bodega-table {
  .mov-bodega-padding-left-th-first {
    width: 169px;
  }

  .mov-bodega-padding-left-th,
  .mov-bodega-padding-left-th-first {
    width: 169px !important;
  }

  .row-with-border td {
    &:first-child {
      &::after {
        left: 8px !important;
      }
    }
    &:last-child {
      &::after {
        right: 8px !important;
      }
    }
  }

  td {
    font-weight: 500;
    padding: 8px 16px 8px 0;
    &:first-child {
      padding-left: 8px !important;
    }
    &:last-child {
      padding-right: 8px !important;
    }

    .text-input {
      width: 121px !important;
    }
    .container-cell-content.bodega {
      font-weight: bold;
    }
  }
  .cd-mb-select {
    width: 153px;
  }

  .cd-mb-select-last {
    width: 132px;
  }
  .remove-row-btn {
    right: 8px !important;
  }
}

.cierre-diario-ingreso-mediciones {
  width: 1088px;
  margin: 0 auto;
  .eds-select {
    width: auto;
  }
  .cierre-diario-ingreso-mediciones-data {
    background: $copec-gris-input;
    width: 104px;
    height: 24px;
    padding: 4px 8px;
    font-weight: 500;
    text-align: right;
    border-radius: 4px;
  }
  .cierre-diario-ingreso-mediciones-data-total {
    background: #1080d51a;
    width: 104px;
    height: 22px;
    padding: 0 8px;
    font-weight: bold;
    text-align: right;
    border-radius: 16px;
  }
  .container-cell {
    min-width: 0 !important;
  }
  .container-cell-content {
    width: 120px;
  }
  .cierre-diario-ingreso-mediciones-input {
    .input-with-clear-btn {
      width: 120px;
      height: 32px;
    }
  }
  .cierre-diario-table-grey-border {
    background: $copec-separator;
    padding: 0 4px;
  }
  .cierre-diario-table-grey-border-header {
    width: 2px;
    padding: 0;
  }
  .cierre-diario-table-tanque-name {
    width: 128px;
  }
  .measurement-column {
    min-width: 0 !important;
  }
  td:last-child {
    padding-right: 16px !important;
  }
  .cierre-diario-table-align-right {
    text-align: right;
    padding-right: 24px;
    padding-left: 0;
    &.last-column {
      padding-right: 8px !important;
    }
  }
}

.fluctuaciones-ingreso-mediciones-big-section-cierre-diario {
  display: inline-block;
  margin-bottom: 100px;
  .container-cell {
    min-width: 0 !important;
  }
  .cierre-diario-confirmacion-data-total {
    background: #1080d51a;
    width: 84px;
    height: 22px;
    padding: 0 8px;
    font-weight: bold;
    text-align: right;
    border-radius: 16px;
  }
  td {
    padding: 8px;
  }

  .cierre-diario-confirmacion-th {
    width: 110px !important;
    padding: 0;
    text-align: right;
  }
  .cierre-diario-confirmacion-th-tanque{
    width: 122px !important;
  }
  .cierre-diario-confirmacion-th-material{
    width: 122px !important;
  }
  .cierre-diario-confirmacion-th-alerta{
    width: 62px !important;
  }
}

.cierre-diario-align-right {
  text-align: right;
  padding-right: 15px !important;
}

.alert-container-cierre-diario {
  position: relative;

  .alert-tooltip {
    position: absolute;
    visibility: hidden;
    top: 34px;
    right: -4px;
    background: white;
    box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
    padding: 8px;
    border-radius: 4px;
    z-index: 100;
    &.confirmacion {
      overflow: visible;
      white-space: nowrap;
      padding-right: 12px;
    }
    .content {
      background-color: #f1f5f8;
      color: $copec-dark-blue;
      font-size: 10px;
      font-weight: 500;
      padding: 5px;
      border-radius: 4px;
    }
  }

  /* .alert-tooltip:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 20px;
        width: 0;
        border-bottom: 10px solid white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      } */

  /* .alert-tooltip:before {
        content: "";
        position: absolute;
        bottom: -webkit-calc(100% - 10px); 
        bottom: calc(100% - 10px); 
        left: 25px;
        height: 15px;
        width: 15px;
        background: white;
        transform: rotate(45deg);
        border-bottom:1px solid white;
        border-right:inherit;
        box-shadow:inherit;
        z-index: -1;
      } */

  &:hover {
    .alert-tooltip {
      visibility: visible;
    }
  }
}

.cierre-diario-ventas-last-column {
  padding: 8px !important;
  border: none !important;
}


  .table-with-material-title2{
    width: 1332px;
    padding-left: 25px;
    border-collapse: inherit;
    margin-top: 0px !important;
    border-spacing: 0;
    font-size: 12px;
    padding-top: 8px;
    .table-header{
      display: flex;
      margin-bottom: 5px;
      justify-content: space-between;
      .legend{
        display: flex;
        justify-content: space-between;
        gap:24px;
        margin-left: 8px;
        .material{
          display: flex;
          gap:8px;
          .rect{
            width: 8px;
            height: 16px;
            border-radius: 2px;
            background-color: #0e69af;
            &.concesionado{
                background-color: #07d188;
            }
          }
          .text{
            font-weight: 700;
            color:$copec-dark-blue;
          }  
        }
      }
    }

    th .th-data{
      // padding: 16px 7px 17px;
      padding: 0px;
      height: 48px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      &.two-lines{
        height: 48px;
        // padding: 9px 7px 9px;
        padding: 0px;
        display: flex;
        align-items: center;
      }
      &.material{
        padding: 8px 7.5px 8px 8px;
      }
      &.litros-controlador{
        padding: 8px;
      }
      &.alerta{
        padding: 8px 7px 8px 8px;
      }
      &.width-62{
      	width: 62px;
      }
      &.width-89{
      	width: 89px;
      }
      &.width-100{
      	width: 86px;
      }
      &.width-110{
        width: 96px;
      }
      &.width-116{
        width: 102px;
      }
      &.width-60{
      	width: 46px;
      }
      .th-text{
        top: unset;
        position: unset;
        transform: unset;
      }
    }
    th{
    	// padding: 0;
      border: 3px solid #dee4ea;
    }

    tbody{
    	tr:nth-child(6n+1), 
    	tr:nth-child(6n+2), 
      tr:nth-child(6n+3) {
			  // background-color: #f1f5f8;
        background-color: white;
			}
      
			tr:nth-child(6n+4), 
			tr:nth-child(6n+5), 
			tr:nth-child(6n+6) 
      {
        background-color: #f1f5f8;
        // background-color: white;
			}
      // tr.odd{
      //   background-color: white;
      // }
      // tr.even{
      //   background-color: #f1f5f8;
      // }
    	td{
        
    		// padding: 8px;
        // padding-right: 12px;
        max-width: 89px !important;
    		line-height: 2;
    		text-align: right;
        padding-right: 10px;
        // margin-right: 5px;
        &.alert-container-cierre-mensual{
          max-width: 62px;
          // .alert-tooltip{
          //   padding: 0;
          // }
        }
        &.total-border-top{
          border-top: 1px solid #F1F5F8 !important;
          // padding-right: 0px;
        }
        &.align-left{
          text-align: left;
          padding-left: 5px !important;
          padding-right: 5px;
        }
        .total-container{
          box-sizing: border-box;
          // padding-right: 4px;
          max-width: inherit;
          margin: 0;
          margin-top: 8px;
          margin-right: 0 !important;
          margin-bottom: 7px;
          // width: 89px;
          height: 22px;
          // margin: 8.5px 8px 2px 16px;
          // padding: 1px;
          border-radius: 16px;
          background-color: rgba(16, 128, 213, 0.1);
          span{
            margin-right: 5px;
          }
        }
        &.total-data-container{
          padding-right: 8px !important;
        }
        .total-data{
          background-color: rgba(16, 128, 213, 0.1019607843);
          border-radius: 16px;
          padding: 0 8px;
          text-align: right;
        }
    		&.align-center{
    			text-align: center;
    		}
    		&.def-tanque-container{
    			text-align: left;
          .top-row{
            white-space: nowrap;
            font-size: 12px;
            font-weight: 700;
          }
          .bottom-row{
            font-weight:500;
          }
    		}
    		&.alerta-container{
          max-width: 62px;
    			padding: 12px 0 9px;
    			line-height: normal;
    			text-align: center;
    		}
	    	.type{
	    		float: left;
	        width: 8px;
	        height: 24px;
	        border-radius: 2px;
	        margin-right: 8px;
          &.consignado{
            background-color: #0e69af;
          }
	        &.concesionado{
	          background-color: #07d188;
	        }
          &.tanques2{
            height: 79px !important;
          }
          &.nomarginright{
            margin-right: 0px !important;
          }
	      }
	    }
    }
    tr.total-row{
    	background: white;
    	border-radius: 6px;
    	td{
    		padding: 8px 4px;
    		line-height: 2;
    		&:first-child{
    			border-top-left-radius: 6px;
    			border-bottom-left-radius: 6px;
    		}
    		&:last-child{
    			border-top-right-radius: 6px;
    			border-bottom-right-radius: 6px;
    		}
    		.total-data{
    			background-color: #1080d51a;
    			border-radius: 16px;
    			padding: 0 8px;
    			text-align: right;
          margin-right: 5px;
    		}
        &.total-data-container{
          padding-right: 8px !important;
        }
    	}
    }
    &.fluctuaciones{
      margin-bottom: 16px;
    	tbody{
	    	td{
	    		padding: 12px 16px 12px 8px;
	    		&.def-tanque-container{
	    			padding: 8px 0 8px 8px;
	    			line-height: normal;
	    			.def-tanque{
			      	float: left;
			      	text-align: left;
			      }
	    		}
          &.total-data-container{
            border-top: 1px solid #dee5ea !important;
          }
	    		&.alerta-container{
	    			padding: 16px 0 13px;
	    		}
		    	.type{
		    		float: left;
		        background-color: #0e69af;
		        width: 8px;
		        height: 32px;
		        border-radius: 2px;
		        margin-right: 8px;
		        &.concesionado{
		          background-color: #07d188;
		        }
		      }
		    }
        .total-row{
          td{
            padding: 8px 16px 8px 8px;
          }
          .total-text-sifones{
            text-align: left;
            padding-left: 25px;
          }
        }
	    }
    }
  }
.table-input-gas{
  width: 80px;
  padding: 8px;
}

.entradas-manuales-gas-row{
  td{
    input{
      float: none;
    }
    .eds-select{
      margin-left: 15px;
    }
    .react-datepicker__input-container{
      width: 90px;
      input{
        padding: 8px;
        width: 76px;
      }
    }
  }
}

.eds-select-width-240{
  width: 240px;
}
