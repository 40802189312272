.historial-de-alarmas {
  .filters-container {
    display: flex;
    align-items: flex-end;
  }
  .flutuaciones-table {
    th,
    td {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
.hda-resumen__table {
  margin-top: 24px;
  th,
  td {
    width: 100%;
  }
}
