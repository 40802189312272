@import "./variables";

input,
button,
div,
select,
span {
  outline: none;
  box-shadow: none;
}

hr {
  border: none;
  border-top: 1px solid $copec-light-grey;
  margin: 0;
}

.icon-btn {
  background: none;
  border: none;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  img {
    width: 100%;
  }
}

.title-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  .volver-text{
    margin: 0;
  }
  .volver-arrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img{
      margin-right: 11px;
      margin-top:2px;
      content: url("../assets/img/back_default.svg");
    }
    p{
      font-size: 14px;
      font-weight: 800;
      color:$copec-dark-blue;
    }
  }
  .volver-arrow:hover{
    img{
      content: url("../assets/img/back_hover.svg");
    }
  }
}

.view-title,
.view-title-separator,
.view-subtitle {
  color: $copec-dark-blue;
  display: inline;
  font-size: 22px;
}

.view-title {
  font-weight: 800;
}

.view-title-separator {
  padding: 7px;
}

.view-subtitle {
  font-weight: 400;
}

.title-separator-line {
  width: 154px;
  margin: 0px;
  border-bottom: none;
  border-top: 1px solid $copec-light-grey;
  margin-top: 7px;
}

.red-button {
  background: $copec-red;
  color: white;
  border-radius: 26px;
  padding: 11px 35px 11px 36px;
  border: none;
  font-size: 12px;
  letter-spacing: -0.18px;
  font-weight: bold;
  cursor: pointer;
}

.eds-select,
.table-select {
  width: 170px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: $copec-dark-blue;
  input[type="checkbox"] {
    margin: 0 14px 0 0 !important;
    vertical-align: middle;
  }
  div {
    color: $copec-dark-blue;
  }
}

.table-select {
  border: 1px solid #809cb2;
}

.header-data-container {
  margin-top: 21px;
  display: inline-block;
  margin-bottom: 36px;
  width: 100%;
}

.header-data {
  float: left;
  display: table;
  //width: 400px;
  width: calc(30% - 120px);
  margin-right: 62px;
  .left {
    display: table-cell;
    padding-right: 20px;
    width: 48px;
  }
  .right {
    display: table-cell;
    vertical-align: top;
    .title {
      font-size: 12px;
      font-weight: bold;
      color: $copec-dark-blue;
      line-height: 24px;
      letter-spacing: 0.34px;
    }
    .info {
      color: $copec-grey;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.46px;
      max-width: 296px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &.last {
    margin-right: 0;
  }
}

.header-box-two{
  margin: 0 20px 32px 6px;
  padding: 12px 16px;
  border-radius: 9px;
  box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
  background-color: #ffffff;
  @media (max-width: $mobile-break-point) {
    grid-column: 1/4;
    margin:0 0 8px 0;
  }
  img{
    width: 24px;
  }
  .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
    .title{
      font-size: 12px;
      color:$copec-dark-blue;
      font-weight: 800;
      margin-left: 21px;
    }
    .monto{
      font-size: 14px;
      font-weight: 800;
      margin-left: auto;
      &.cargos{
        color:$copec-red;
      }
      &.abonos{
        color:$copec-green
      }
    }
    &.mbottom{
      margin-bottom: 4px;
    }
    &.mtop{
      margin-top: 4px;
    }
  }
}

.header-data-container-multiple {
  display: inline-block;
  margin: 0 0 32px 6px;
  padding: 15px 54px 14px 16px;
  border-radius: 9px;
  box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
  background-color: #ffffff;

  .col {
    vertical-align: top;
    display: table-cell;

    .text-container {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      line-height: 19px;
      margin: 1px 0;
      padding-bottom: 3.5px;
      color: $copec-dark-blue;
      .monto-total-data {
        margin-left: 0.5rem;
        white-space: nowrap;
      }
    }
    .monto {
      color: $copec-red;
      font-weight: bold;
      font-size: 14px;
      margin-top: 5.5px;
      //margin-bottom: 10px;
      &.blue {
        color: $copec-dark-blue;
      }
      &.red {
        color: $copec-red;
      }
      &.yellow {
        color: $copec-yellow;
      }
      &.green {
        color: $copec-green;
      }
    }
    .separator {
      width: 1px;
      height: 32px;
      background: $copec-light-grey;
      margin: 3px 0;
    }
    .col-left {
      display: table;
      color: $copec-dark-blue;
      font-size: 14px;
      font-weight: 500;
      margin-right: 20px;
      .img-container {
        display: table-cell;
        padding-right: 21px;
        height: 40px;
        padding-top: 4px;
      }
    }
    &.full {
      width: 100%;
      padding-right: 1rem;
    }
  }

  .monto-total-title {
    font-weight: bold;
  }

  @media screen and (min-width: $desktop-start-point) {
    .monto-total-title {
      white-space: nowrap;
    }
  }
}

.header-data-container-single {
  display: inline-block;
  margin: 0 0 32px 6px;
  padding: 15px 54px 11px 16px;
  border-radius: 9px;
  box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
  background-color: #ffffff;

  .col {
    vertical-align: top;
    display: table-cell;
    .separator {
      width: 1px;
      height: 32px;
      background: $copec-light-grey;
      margin: 3px 0;
    }
    .col-left {
      display: table;
      color: $copec-dark-blue;
      font-size: 12px;
      font-weight: 500;
      margin-right: 20px;
      .img-container {
        display: table-cell;
        padding-right: 21px;
        height: 40px;
      }
      .text-container {
        vertical-align: top;
        display: table-cell;
        font-size: 14px;
        line-height: 19px;
        margin: 1px 0;
      }
    }

    .col-center {
      color: $copec-red;
      font-weight: bold;
      font-size: 14px;
      margin: 8px 37px 0 38px;
      &.blue {
        color: $copec-dark-blue;
      }
    }

    .col-right {
      margin-left: 53px;
    }
  }

  .monto-total-title {
    font-weight: bold;
  }

  .monto-total-title,
  .monto-total-data {
    font-size: 12px;
  }
}

.header-data-container-multiple {
  padding: 15px 10px 14px 10px;
  margin: 0 20px 32px 6px;
  .col-center {
    padding: 0 10px;
    text-align: center;
    margin: 0 auto !important;
    margin-top: 8px !important;
  }

  .col-left {
    margin-right: 0 !important;
    .text-container {
      padding-right: 10px;
    }
  }

  &.first {
    margin-left: 2px;
    .col-center {
      padding: 0 10px;
    }

    .col-left .text-container {
      padding-right: 10px;
    }
  }
  &.last {
    margin-right: 2px;
  }

  .img-container {
    display: table-cell;
    padding-right: 10px;
    height: 40px;
  }
}

.filters-container {
  margin: 12px 0 14px;
  display: inline-block;
  &.arcoprime{
    margin-top: 24px;
  }
  .filter {
    float: left;

    .filter-title {
      color: $copec-dark-blue;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.34px;
      margin-bottom: 6px;
    }

    .filter-input {
      .search {
        margin: 18px 31px 0;
        width: 222px;
        border: 1px solid $copec-grey-3;
        border-radius: 20px;
        padding: 12px 10px 13px 26px;
        font-size: 12px;
        font-weight: 600;
        color: $copec-dark-blue;

        ::placeholder {
          color: $copec-grey-3;
        }

        &:placeholder-shown {
          background-image: url(../assets/img/lupa.svg);
          background-size: 30px;
          background-position: right 7px center;
          background-repeat: no-repeat;
        }

        &:not(:placeholder-shown) {
          background-image: url(../assets/img/cross-blue-icon.svg);
          background-size: 26px;
          background-position: right 13px center;
          background-repeat: no-repeat;
          &:hover {
            background: none;
          }
        }

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
          height: 26px;
          width: 26px;
          background: url(../assets/img/cross-blue-icon.svg) no-repeat;
          background-position: right 0;
          display: block;
        }

        &.nomarginsearch {
          margin-left: 0;
        }
      }
    }
  }

  .filter-with-margin-left {
    margin-left: 30px;
  }
}

.main-button {
  background: $copec-dark-blue;
  color: white;
  width: 200px;
  border-radius: 26px;
  border: 1px solid transparent;
  height: 45px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &.thin{
    height: 32px;
    font-size: 12px;
    width: 160px;
    display: block;
    margin: 32px auto;
  }

  &.main-button-small{
    height: 32px;
    font-size: 12px;
    width: 160px;
  }

  &.main-button-red {
    background: $copec-red;
  }
  &.bloqueado {
    background: $copec-grey-button-background !important;
  }
  &.disabled,
  &:disabled {
    background: $copec-grey-button-background !important;
    cursor: not-allowed;
  }

  &.hallow {
    background: white;
    border: 1px solid $copec-dark-blue;
    color: $copec-dark-blue;
    &.main-button-red {
      border: 1px solid $copec-red;
      color: $copec-red;
    }
  }

  &.cierre-diario-main-buttons{
    &.disabled,
    &:disabled {
      background: #bdbdbd !important;
    }
  }
}

.actions {
  float: right;
  margin: 28px 0 0 0;
  img {
    margin-left: 30px;
    cursor: pointer;
    &.arcoprime {
      margin-left: 15px;
    }
  }

  .actions-buttons {
    background: $copec-dark-blue;
    color: white;
    width: 200px;
    border-radius: 26px;
    border: none;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
    float: left;
    cursor: pointer;
    &.disabled {
      background: $copec-grey-button-background;
      cursor: not-allowed;
    }
    &.bloqueado {
      background: $copec-grey-button-background;
      cursor: normal;
    }

    @extend .main-button;
  }
}

.react-datepicker-wrapper {
  width: 100% !important;
  input {
    width: 106px;
    border: none;
    border-radius: 3px;
    padding: 10px 0px 10px 10px;
    font-size: 12px;
    color: $copec-dark-blue;
    font-weight: 500;
    opacity: 1;

    &.active {
      border: 1px solid #6cbfff;
      width: 105px;
    }
    &::placeholder{
      color: $copec-dark-blue;
      font-weight: 500;
    }
    &.arcoprime{
      border: 1px solid #7c97ab;
      width: 180px;
    }
  }
  input.pagos {
    width: 94%;
    &.active {
      width: 94%;
    }
  }
}
/*
.ReactModal__Content.ReactModal__Content--after-open{
  inset: 0 auto auto 50% !important;
}
*/

.modal-content {
  width: 306px;
  padding: 17px;
  .close-modal {
    float: right;
  }

  .modal-info {
    display: block;
    width: 270px;
    margin: 0 auto;
    margin-top: 40px;
    color: $copec-dark-blue;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    font-weight: 500;
  }

  .modal-central-icon {
    margin: 30px auto;
    display: block;
  }

  .title {
    color: $copec-dark-blue;
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 12px;
    text-align: center;
    margin-top: 47px;
  }

  hr {
    margin: 30px 0;
    border: 1px solid #dddddd;
    border-bottom: none;
  }

  .bottom {
    width: 100%;
    display: flex;
  }

  .modal-button {
    border: none;
    color: white;
    width: 150px;
    height: 45px;
    border-radius: 26px;
    background: $copec-dark-blue;
    padding: 10px 40px;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
  }
}

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 50;
}

.important-message {
  background: $sidebar-grey;
  color: $copec-dark-blue;
  font-size: 12px;
  padding: 12px 31px;
  font-weight: 500;
  border-radius: 8px;
  .title {
    color: $copec-red;
    font-weight: bold;
  }
  &.error {
    background-color: RGBA(234, 83, 52, 0.05);
    color: $copec-red;
    text-align: center;
    font-size: 13px;
    .error-icon {
      border-radius: 6px;
      color: white;
      background-color: $copec-red;
      font-size: 10px;
      font-weight: bold;
      padding: 0 5px;
      margin-right: 20px;
    }
  }
  &.success {
    background-color: RGBA(34, 156, 101, 0.05);
    color: $copec-green;
    text-align: center;
    font-size: 13px;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    .success-icon {
      border-radius: 9px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      // margin-right: 20px;
    }
    .text{
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
  }
}

.input-with-unit {
  position: relative;
  height: 32px;
  border-radius: 4px;
  border: solid 1px $copec-input-border;
  background: white;
  box-sizing: border-box;
  color: $copec-dark-blue;
  p {
    position: absolute;
    left: 20px;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    font-size: 12px;
    line-height: 1;
  }
  input {
    padding: 12px 10px 12px 35px;
    border: none;
    background: transparent;
    width: 100%;
    display: block;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    z-index: 1;
    color: $copec-dark-blue;
    font-size: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  // input[type="number"] {
  //   -moz-appearance: textfield;
  // }
  &.blocked{
    background: #f1f5f8;
  }
}

.separator-table-row {
  height: 6px !important;
  th, td {
    padding: 0 !important;
    border-top: 2px solid transparent !important;
    border-bottom: 2px solid transparent !important;
  }
}

.selectingInput {
  margin-left: 18px;
  margin-top: -5px;
}

.arrows {
  display: flex;
  flex-direction: column;
  width: 9px;
  justify-self: flex-end;
  margin-right: 15px;
  margin-top: 1px;
  gap: 4px;
  &.mtop {
    margin-top: 7px;
  }
}

.single-option-label {
  color: red !important;
}

.align-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}

.highlight {
  background-color: rgba(255, 185, 6, 0.25);
  color: inherit;
  display: inline-flex;
}

.highlighter-container{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  span{
    span{
      display: contents !important;
    }
  }
}

.underline {
  text-decoration: underline;
  &.bold {
    font-weight: 800;
  }
  span{
    text-decoration: underline;
  }
}

.nomargintop {
  margin-top: 0;
}

.mini-hr {
  margin-top: 8px;
  width: 160px;
}

.modified-option-select {
  height: 28px;
}

.modified-option {
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.modal__content {
  text-align: center !important;
  padding: 0 10px !important;
  letter-spacing: normal !important;
}

.modal-container {
  position: relative;
}

.documento-info-modal {
  left: -200px;
  visibility: visible;
  background: white;
  border-radius: 4px;
  color: $copec-dark-blue;
  padding: 0 11px 15px 11px;
  //box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  .title {
    font-weight: 800;
    font-size: 16px;
    text-align: left;
    padding: 5px 0;
    color:$copec-dark-blue;
  }
  .subtitle{
    font-weight: 800;
    font-size: 14px;
    color:#7c97ab;
  }
  .loading{
    font-weight: 400;
    font-size: 14px;
    align-self: center;
    margin-top: 20px;
    color:$copec-dark-blue;
  }
  .content {
    //background: $sidebar-grey;
    //padding: 7px 6px;

    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    &.reposicion{
      max-height: 550px;
    }
    table {
      width: 100%;
      display: table;
      border-collapse: inherit;
      margin-top: 24px;
      border-spacing: 0;
      font-size: 12px;
      margin-bottom: 16px;
      padding-top: 8px;
      thead {
        width: 100%;
        background: $copec-dark-blue;
        tr {
          background: $copec-dark-blue;
          th {
            font-weight: 800;
            //padding-left: 40px;
            white-space: nowrap;
            border-right: 1px solid $copec-dark-blue;
          }
        }
      }
      th,
      tfoot td {
        border: 0;
        font-weight: bold;
        line-height: 1.6;
        &:first-child {
          padding: 12px 10px 12px 15px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          padding: 12px 15px 12px 20px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &[colspan="3"] {
          padding: 5px;
        }
      }
      td {
        background: white;
      }
      tbody {
        td {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-right: 10px;
          border-bottom: 1px solid $copec-light-grey;
          white-space: nowrap;
          &.align-right{
            padding: 12px 0px 12px 50px !important;
            &.tdcontent{
              width: 157px;
              display: flex;
              justify-content: space-between;
            }
          }
          
        }
        tr {
          td:last-child{
            padding: 12px 15px !important;
            &.tdsep{
              padding: 0 15px !important;
            }
          }
          &:first-child {
            td:first-child {
              border-top-left-radius: 4px;
            }
            td:last-child {
              border-top-right-radius: 4px;
            }
          }
          &:last-child {
            td:first-child {
              border-bottom-left-radius: 4px;
            }
            td:last-child {
              border-bottom-right-radius: 4px;
            }
          }
          td:first-child {
            padding-left: 20px;
            //width: 40%;
          }
          &.data {
            &.subtotal {
              td:first-child,
              td:last-child {
                font-weight: bold;
              }
              td {
                font-size: 14px;
              }
            }
            //por si se necesita para dinamo pedido
            /* td .tdcontent{
              width: 130px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            } */
          }
        }
      }
      tfoot td {
        font-size: 14px;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .footer {
    .important {
      color: #ed1d24;
    }
  }
}

.copecblue {
  color: $copec-dark-blue;
}

.hidden {
  display: none !important;
}

.radio-button-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  margin-left: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid $copec-grey;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio-button-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $copec-dark-blue;
}

table {
  tr:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    td:first-child {
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }

  tr:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    td:first-child {
      border-top-left-radius: 4px;
    }
    td:last-child {
      border-top-right-radius: 4px;
    }
  }
}

.myCheckbox-container {
  margin-top: -1px;
  margin-left: -10px;
}

.myCheckbox input {
  position: relative;
  z-index: -9999;
}

.myCheckbox span {
  width: 12px !important;
  height: 12px;
  display: block;
  border-radius: 3px;
  background: white;
  border: 1px solid $copec-grey;
}

.myCheckbox input:checked + span {
  background: url("../assets/img/checkbox.svg");
  width: 14px !important;
  height: 14px;
  border: none;
}

.myCheckbox input:disabled + span {
  background: $copec-separator;
}

.myCheckbox-title{
  margin-top: 10px;
  margin-left: 8px;
}

.tbody-rounded-corners {
  border-radius: 6px;
  tr {
    &:first-child {
      td:first-child {
        border-top-left-radius: 4px;
      }
      td:last-child {
        border-top-right-radius: 4px;
      }
    }
    &:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.mleft {
  margin-left: 30px;
}

.page-options {
  display: flex;
  align-items: flex-end;
  padding-top: 12px;
  padding-bottom: 10px;
  justify-content: space-between;
  padding-right: 1px;
  padding-left: 1px;
  .filter {
    margin-right: 32px;
    .filter-input-element {
      width: 170px;
      border-radius: 4px;
      background: $copec-gris-input;
      border: none;
      min-height: 38px;
      box-sizing: border-box;
      padding: 8px 16px;
      color: $copec-grey;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .react-datepicker-wrapper {
      border-radius: 4px;
      border: solid 1px $copec-grey;
      width: 170px !important;
      input {
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
  .actions {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.disabled-input {
  width: 100%;
  border-radius: 4px;
  background: $copec-gris-input;
  border: 1px solid $copec-grey;
  min-height: 38px;
  box-sizing: border-box;
  padding: 8px 16px;
  color: $copec-grey;
  font-size: 12px;
  line-height: 16px;
}

.copec-stepper {
  width: 300px;
  margin-right: 23px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-left:4px;

  &.two-steps {
    width: 170px;
    margin-right: 156px;
  }

  &__step {
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $sidebar-grey;
    color: $copec-dark-blue;
    position: relative;
    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;

    &-name {
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, 12px);
      font-size: 10px;
      font-weight: bold;
      color: $copec-dark-blue;
    }
    &.active {
      background-color: $copec-dark-blue;
      color: white;
      &.error {
        background-color: $copec-red;
      }
    }
  }
  &__line {
    flex-grow: 1;
    height: 1px;
    background-color: $copec-grey-border;
    &.active {
      background-color: $copec-dark-blue;
    }
  }
}
.force-one-liner {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.input-with-clear-btn {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border-radius: 4px;
    background: #fff;
    border: 1px solid $active-select-border;
    min-height: 32px;
    box-sizing: border-box;
    padding: 8px 16px;
    color: $copec-dark-blue;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder {
      color: $copec-grey;
    }
    &:placeholder-shown {
      border-color: $copec-grey;
    }
  }
  &.only-text{
    input{
      background-color: #f1f5f8;
      border:none;
    }
  }
  button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    padding: 0;
    img {
      width: 100%;
      display: block;
    }
  }
}

.react-datepicker{
  font-family: Quicksand;
  border: 1px solid $copec-grey;

  &__header{
    background-color: white;
    border-bottom: 1px solid $copec-separator;
  }
  &__current-month, &__day-name, &__day{
    color: $copec-dark-blue;
  }
  &__day:hover{
    border-radius: 15px;
    color: white;
    background-color: $copec-dark-blue;
  }
  &__day--disabled{
    color: #ccc;
  }
  &__quarter-text--keyboard-selected, 
  &__day--keyboard-selected, 
  &__quarter-text--in-range, 
  &__quarter-text--in-selecting-range, 
  &__quarter-text--selected,
  &__day--selected,
  &__day--in-selecting-range,
  &__day--range-end,
  &__day--range-start{
    border-radius: 15px;
    background-color: $copec-dark-blue !important;
    color: white;
  }

  &__day--in-range{
    background-color: $copec-gris-input;
  }

}

.red-asterisk{
  color:$copec-red;
}

b{
  font-weight: 800;
}

.no-padding-left{
  padding-left: 0;
}

.capitalize{
  text-transform: capitalize;
}

.table-bottom-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: $copec-red;
  background: rgba($copec-red, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;
  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin: 0;
  }
  img {
    margin-right: 10px;
  }
  & ~ hr {
    margin-top: 0;
  }
}

.hidden-file-input{
  display: none;
}

.dinamo-info{
  margin-left: 32px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(14, 105, 175, 0.3);
  display: inline-block;
  width: 300px;
  height: 64px;
  margin-top: 48px;

  @media screen and (max-width: $mobile-break-point) {
    margin: 8px 0;
    width: 100%;
  }

  .row{
    padding: 8px 8px 8px 16px;
    line-height: 24px;
    font-weight: bold;
    .title{
      font-size: 12px;
      color: $copec-dark-blue;
    }
    .data{
      font-size: 14px;
      color: $copec-green;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .column-left{
    float: left;
    width: 50%;
  }
  .column-right{
    float: right;
  }

  .cheques-rechazados-container{
    background: $copec-gris-input;
    padding: 8px 8px 5px 8px;
    border-radius: 4px;
    height: 35px;
    width: 88px;
    .left{
      float: left;
    }
    .right{
      float: right;
      font-size: 8px;
      line-height: 1.5;
      text-align: center;
      margin-top: 3px;
      font-weight: normal;
      color: $copec-dark-blue;
    }
  }
}

.uppercase{
  text-transform: uppercase;
}

.tab-selector{
  display: flex;
  flex-direction: column;
  .sections{
    display: flex;
   /*  margin-bottom: 8px; */
   /*  gap:30px; */
    .section{
      padding: 12px 0;
      width: 70%;
      flex:1;
      display: flex;
      justify-content: center;
      cursor: pointer;
      color:#7c97ab;
      font-weight: 800;
      font-size: 12px;
      margin-left:10px;
      margin-right:10px;
      background-color: #f1f5f8;
      border-radius: 4px 4px 0px 0px;
      &.selected{
        color:$copec-dark-blue;
        background-color: rgb(14,105,175,0.1);
      }
    }
    .section:first-child{
      margin-left: 17px;
    }
    .section:last-child{
      margin-right: 17px;
    }
    .section:hover{
      color:$copec-dark-blue;
    }
  }
  .lines{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 2px;
    background-color:#dee5ea;

    .line:first-child{
      margin-left: 8px;
    }
    .line:last-child{
      margin-right: 8px;
    }
  }

  .line{
    flex-grow: 1;
    &.selected{
      height: 4px;
      background-color: #0e69af;
      border-radius: 3px;
    }
  }
}

.center{
  text-align: center;
}

.align-right{
  text-align: right;
}

.no-padding{
  padding: 0 !important;
}

.link{
  text-decoration: underline;
  cursor: pointer;
}

#excel-button{
  &.hide{
    display:none;
  }
}

#print-button{
  &.hide{
    display:none;
  }
}

.wsnw{
  white-space: nowrap !important;
}

// .time-picker-library-component-container{
// 	width: 100px;
// 	margin: 5px auto;
// }
.time-picker-library-container{
  position: relative;

  &.time-picker-library-container-disabled{
    background: #f1f1f1;
    cursor: default;
  }

  .time-container{
    margin: 0 auto;
    width: 118px;
    // min-height: 34px;
    display: flex;
    justify-content: center;
    border: 1px solid #0e69af;
    padding: 4px 0;
    border-radius: 4px;
    font-family: Quicksand, serif ;
    font-weight: 700;
    p{
      margin: 0;
      font-family: Quicksand, serif ;
      color: $copec-dark-blue;
      font-size: 12px;
    }
  }
  .time-picker{
    width: 100px;
    border: 1px solid #7c97ab;
    padding: 10px 9px;
    border-radius: 4px;
    position: absolute;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
    top: 38px;
    .react-time-picker{
      display: flex;
      justify-content: center;
      width: 100px;
      height: 35px;
      font-size: 12px;
      border: 1px solid #7c97ab;
      border-radius: 4px;
      margin-bottom: 15px;
      font-family: Quicksand, serif ;
      color: $copec-dark-blue;
      font-weight: 700;
      .react-time-picker__wrapper{
        border: none !important;
        .react-time-picker__inputGroup{
        text-align: center;
        width: 100px;
        padding: 0;
          input{
            color: $copec-dark-blue;
          }
        } 
        input:focus{
          outline: none;
          color: $copec-dark-blue;
          font-weight: 700;
        }
        input:first-child{
          width: 100%;
        }
      }
    }
    .time-picker-accept{
      cursor: pointer;
      color: white;
      background-color: #003965;
      border: #003965;
      padding: 8px 0;
      border-radius: 16px;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.trx-box-data{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background-color: #ffffff;
  width: 304px;
  height: 72px;
  padding: 15px 16px 15px 12px;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(14, 105, 175, 0.3);
  gap: 20px;
  .left-column{
    .img-container{
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 40px;
        height: 40px;
      }
    }
  }
  .right-column{
    display: flex;
    flex-direction: column;
    width: 216px;
    gap: 4.5px;
    .row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 15px;
      color: $copec-dark-blue;
      .title{
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
      }
      .amount{
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
      }
    }
  }
}

.datos-departamento-modal-container{
  box-sizing: border-box;
  min-width: 490.4px;
  min-height: 378.4px;
  padding-left: 10px;
  padding-top: 6px;
  .enabled-input{
		width: 135px;
		border: 1px solid $copec-grey;
		height: 21px;
		padding: 5px 8px 5px 16px;
		border-radius: 4px;
		background-color: white;
		font-size: 12px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: $copec-dark-blue;
	}

	.disabled-input{
		min-height: 0px !important;
		height: 32px !important;
		border: solid 1px var(--gris-copec-20-f-1-f-5-f-8);
		width: 208px;
		border-radius: 4px;
		background: $copec-gris-input;
		box-sizing: border-box;
		padding: 8px 16px;
		color: $copec-grey;
		font-size: 12px;
		line-height: 16px;
    border: none;
	}::placeholder{
    color: $copec-dark-blue;
  }
  .filters-container{
    display: flex;
    gap: 32px;
  }
  .data{
    margin-top: 32px;
  }

  hr{
    width: 448px;
  }
  .datos-departamento-modal {
    visibility: visible;
    background: white;
    border-radius: 4px;
    color: $copec-dark-blue;
    // padding: 0 11px 15px 11px;
    //box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  
    .title {
      font-weight: 800;
      font-size: 16px;
      text-align: left;
      padding: 5px 0;
      color:$copec-dark-blue;
  
      // font-size: 16px;
      // font-weight: bold;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: 1.5;
      // letter-spacing: normal;
    }
    .subtitle{
      color:$copec-dark-blue;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
    }
    .loading{
      font-weight: 400;
      font-size: 14px;
      align-self: center;
      margin-top: 20px;
      color:$copec-dark-blue;
    }
    
    .content {
      //background: $sidebar-grey;
      //padding: 7px 6px;
  
      overflow-y: auto;
      overflow-x: hidden;
      &.reposicion{
        max-height: 550px;
      }
    }
  
    .footer {
      .important {
        color: #ed1d24;
      }
    }
  }
}

.overflowed-container{
  position: relative;
  max-width: inherit;
  .overflowed{
    // color: green;
    // max-width: inherit;
    // width: 80px;
    // width: calc(100% - 11px);
    // width: calc(92px - 12px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .extra{
      position: absolute;
      // color: green;
      top: 15px;
      background-color: white;
      padding: 7px 10px 7px 10px;
      border-radius: 4px;
      z-index: 100;
      visibility: hidden;
      box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
      color: #003965;
    }
    
  }
  .overflowed_on{
    &:hover{
      .extra{
        visibility: visible;
      }
    }
  }
}