@import "./variables";

.regularizacion {

  .alert-container-regularizacion{
    position: relative;
   
    .alert-tooltip{
      position: absolute;
      visibility: hidden;
      top: 34px;
      background: white;
      box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
      padding: 8px;
      border-radius: 4px;
      z-index: 100;
      &.confirmacion{
        overflow: visible;
        white-space: nowrap;
        padding-right: 12px;
      }
      .content{
        background-color: #f1f5f8;
        color:$copec-dark-blue;
        font-size: 10px;
        font-weight: 500;
        padding: 5px;
        border-radius: 4px;
      }
    }
  
    /* .alert-tooltip:before {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 20px;
          width: 0;
          border-bottom: 10px solid white;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        } */
  
        /* .alert-tooltip:before {
          content: "";
          position: absolute;
          bottom: -webkit-calc(100% - 10px); 
          bottom: calc(100% - 10px); 
          left: 25px;
          height: 15px;
          width: 15px;
          background: white;
          transform: rotate(45deg);
          border-bottom:1px solid white;
          border-right:inherit;
          box-shadow:inherit;
          z-index: -1;
        } */
  
    &:hover{
      .alert-tooltip{
        visibility: visible;
      }
    }
  }

  .regularizacion-options{
    .filters-container{
      margin-bottom: 0;
      .filter-input{
        input.search{
          margin: 18px 21px 0;
        }
      }
    }
    .react-datepicker-wrapper {
      border: 1px solid $copec-grey;
      border-radius: 4px;
      input{
          width: 170px;
          height: 16px;
      }
      
    }
    .main-button{
      font-size:12px;
      height: 32px;
    }
    .actions{
      display: flex;
      gap:33px;
      margin: 38px 0 0 0;
      .main-button{
        &.iconbutton{
          img{
            margin-left: 0;
          }
          display: flex;
          gap:5px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

	.solicitud-regularizacion{
    .eds-select{
      width: 110px;
    }

		.title{
			font-size: 18px;
			color:$copec-dark-blue;
			font-weight: 700;
			margin: 20px 0px 5px;
		}

		.subtitle{
			font-size: 16px;
			color:$copec-grey;
			font-weight: 700;
			margin:5px 0px;
		}

		.info{
			.title{
				font-size: 12px;
				color:$copec-dark-blue;
				font-weight: 700;
			}
			.value{
				padding: 8px 16px;
				background-color: #f1f5f8;
				margin: 8px 0 !important;
				font-size: 12px;
				font-weight: 500;
				color:$copec-dark-blue;
				&.align-right{
					text-align: right;
				}
			}
			
		}

		.first-row-info{
			display: flex;
			gap:32px;

			.info .value{
				width: calc(192px - 32px);
			}
		}

		.second-row-info{
			display: flex;
			gap:31px;

			.info .value{
				width: calc(136px - 32px);
			}
		}

		table {
			//width: 300px;
			display: table;
			border-collapse: inherit;
			margin-top: 0px !important;
			border-spacing: 0;
			font-size: 12px;
			margin-bottom: 16px;
			padding-top: 8px;
			thead {
				width: 100%;
				background: $copec-dark-blue;
				tr {
					background: $copec-dark-blue;
					th {
						font-weight: 800;
						//padding-left: 40px;
						white-space: nowrap;
						border-right: 1px solid $copec-dark-blue;
						padding: 6px 0px 6px 8px;
					}
				}
			}
			th,
			tfoot td {
				border: 0;
				font-weight: bold;
				line-height: 1.6;
				&:first-child {
					//padding: 6px 10px 6px 15px;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				&:last-child {
					//padding: 6px 15px 6px 15px;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
				&[colspan="3"] {
					padding: 5px;
				}
			}
			td {
				background: white;
			}
			tbody {
				td {
					padding-top: 12px;
					padding-bottom: 12px;
					padding-right: 10px;
					border-bottom: 1px solid $copec-light-grey;
					white-space: nowrap;
					&.align-right{
						padding: 12px 0px 12px 8px !important;
						&.tdcontent{
							width: 157px;
							display: flex;
							justify-content: space-between;
						}
					}
					.td-data{
						background-color: $copec-gris-input;
						padding: 8px 16px;
						border-radius: 4px;
						width: calc(92px - 16px);
            &.green{
              background-color:rgb(7, 209, 136,0.15);
              color:$copec-dark-blue;
            }
					}
					
				}
				tr {
					td:last-child{
						padding: 12px 8px 12px 8px !important;
						&.tdsep{
							padding: 0 15px !important;
						}
					}
					&:first-child {
						td:first-child {
							border-top-left-radius: 4px;
						}
						td:last-child {
							border-top-right-radius: 4px;
						}
					}
					&:last-child {
						td:first-child {
							border-bottom-left-radius: 4px;
						}
						td:last-child {
							border-bottom-right-radius: 4px;
						}
					}
					td:first-child {
						padding-left: 20px;
						//width: 40%;
					}
					&.data {
						&.subtotal {
							td:first-child,
							td:last-child {
								font-weight: bold;
							}
							td {
								font-size: 14px;
							}
						}
					}
				}
			}
			tfoot td {
				font-size: 14px;
				&:last-child {
					text-align: right;
				}
			}
		}

		.observaciones{
			display: flex;
			width: 864px;
			margin: 0 auto;
			justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $copec-separator;
			.info{
				display: flex;
				flex-direction: column;
			}
			.info .title{
				font-size: 12px;
				font-weight: 700;
				color:$copec-dark-blue;
			}
			.info .counter{
				align-self:flex-end;
				margin-top: 0px;
				font-size: 12px;
				font-weight: 700;
				color:$copec-dark-blue;
			}

			.info textarea{
				width: 528px;
				height: 72px;
				resize: none;
				border: 1px solid #809cb2;
				border-radius: 4px;
				color: $copec-dark-blue;
			}

      .add-file-button{
        color: $copec-dark-blue;
        border: 1px solid $copec-dark-blue;
        border-radius: 24px;
        font-size: 12px;
        line-height: 1.33;
        font-weight: bold;
        padding: 8px 36px;
        background: white;
        height: 32px;
        width: 192px;
        margin-top: 60px;
        margin-right: 50px;
        cursor: pointer;
        img{
          float: left;
          margin-right: 8px;
        }
      }
      &.edit{
        justify-content: center;
      }
		}

    .action-buttons-container{
      margin: 31px auto !important;
      width: 354px;
      button{
        width: 160px;
        height: 32px;
        font-weight: bold;
        font-size: 12px;
        &.volver-button{
          color: $copec-red;
          background: white;
          border: 1px solid $copec-red;
          border-radius: 24px;
          cursor: pointer;
        }
        &.regularizar-button{
          color: white;
          background: #bdbdbd;
          border: 1px solid #bdbdbd;
          border-radius: 24px;
          float: right;
          &.active{
            background: $copec-red;
            border: 1px solid $copec-red;
            border-radius: 24px;
            cursor: pointer;
          }
        }
      }
    }

    .uploaded-file-container{
      background: $copec-gris-input;
      width: 264px;
      height: 40px;
      margin-top: 42px;
      border-radius: 4px;
      padding: 16px 20px;
      position: relative;
      .left-side{
        float: left;
        width: 44px;
        height: 40px;
        padding-top: 7px;
        border-right: 1px solid $copec-grey;
        display: inline-block;

      }
      .right-side{
        float: left;
        padding-left: 15px;
        display: inline-block;
        width: 155px;
        height: 40px;
        .file-name{
          color: $copec-dark-blue;
          font-size: 12px;
          width: 192px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .file-type{
          color: $active-select-border;
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
        }
      }
      .solicitud-regularizacion-cross-icon{
        position: absolute;
        top: 20px;
        right: 16px;
        font-size: 26px;
        color: $copec-dark-blue;
        cursor: pointer;

        &:after{
          display: inline-block;
          content: "\00D7"; /* This will render the 'X' */
        }
      }
    }
	}


  .regularizacion-table {
    tr.data:nth-child(odd).selected-row {
      background-color: rgba(16,128,213,.24);
    }

    tr.data:nth-child(2n).selected-row {
      background-color: rgba(16,128,213,.15);
    }

    .numeric-select {
      width: 98px !important;
      position: relative;
      height: 32px;
      margin-top: 3px;
      margin-left: 2px;
      .comparision-type {
        padding: 8px 2px 9px 5px;
        min-height: 15px;
        width: 15px !important;
      }
      .numeric-select-options-container{
        width: 97px;
        top: 30px;
        margin-left: -1px;
        border: 1px solid #dee4ea;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .numeric-select-option{
        padding: 8px 4px;
      }
      input {
        width: 73px !important;
        padding: 8px 2px 9px 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.equal-selected, &.comparision-selected, &.all-selected {
          width: 73px !important;
        }
      }
    }

    .highlighter-container {
      width: 160px;
    }

    &.regularizacion-table-with-custom-highlighter{
      .highlighter-container {
        width: unset;
        white-space: normal;
      }
    }

    .react-datepicker-wrapper {
      width: 100% !important;
      input {
        width: 167px;
        border: none;
        border-radius: 3px;
        padding: 10px 5px;
        font-size: 12px;
        color: $copec-dark-blue;
        &.active {
          border: 1px solid #6cbfff;
          width: 167px;
        }
      }
    }

    &.dinamo {
      .numeric-select {
        width: 136px !important;
      }
      .highlighter-container {
        width: calc(136px - 16px);
      }
      .table-select {
        width: 136px;
      }

      .react-datepicker-wrapper {
        width: 100% !important;
        input {
          width: 125px;
          &.active {
            width: 125px;
          }
        }
      }
    }
  }

  .regularizacion-table {
    .table-header {
      display: flex;
      margin-top: 4px;
      justify-content: flex-end;

      .selector {
        display: flex;
        gap: 11px;
        margin-right: 10px;
        .text {
          font-weight: 700;
          color: $copec-dark-blue;
          font-size: 12px;
        }
        .switch {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 16px;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #7c97ab;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 14px;
          width: 14px;
          left: 1px;
          bottom: 1px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
        input:checked + .slider {
          background-color: #7c97ab;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(14px);
          -ms-transform: translateX(14px);
          transform: translateX(14px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
    }
    tbody {
      td.align-left {
        width: 89px;
        &.options {
          width: 49px;
        }
      }
      tr {
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
            padding-left: 20px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
        }
      }
      td {
        &.logistico {
          .mensaje {
            font-size: 10px;
          }
        }
      }
    }
    .th-data {
      width: 84px;
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.th-select {
        width: 42px;
      }
    }

    &.historial-regularizacion-table{
      padding: 4px 1px 8px;
      th{
        border: 6px solid $copec-table-background;
      }
      .th-data {
        width: 94px;
        &.estado-solicitud{
          width: 100px;
        }
      }
      .numeric-select{
        margin-top: -4px;
        width: 110px !important;
        input{
          width: 86px !important;
        }
        &.regularizacion-table-estado-solicitud{
          width: 116px !important;
          input{
            width: 92px !important;
          }
        }
      }
    }

    &.dinamo {
      .th-data {
        width: calc(136px - 16px);
      }
    }

    .filter-content {
      &.no-padding-left {
        padding-left: 0;
      }

      &.active {
        .numeric-select {
          input.equal-selected,
          input.comparision-selected,
          input.all-selected {
            border: solid 1px #6cbfff;
            border-left: none;
          }
          .comparision-type {
            border: solid 1px #6cbfff;
            border-right: none;
          }
        }
      }
    }

    .numeric-select {
      width: 68px;
    }

    /* .comparision-type.all-selected {
      width: 98px !important;
    }  */

    .document {
      cursor: pointer;
      position: relative;
      .documento-info {
        position: absolute;
        left: -200px;
        visibility: visible;
        background: white;
        border-radius: 4px;
        color: $copec-dark-blue;
        padding: 20px 11px;
        box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
        .title {
          font-weight: bold;
          font-size: 10px;
          text-align: center;
          padding: 5px 0;
        }
        .content {
          //background: $sidebar-grey;
          //padding: 7px 6px;
          table {
            .headers {
              th {
                color: $copec-dark-blue;
              }
            }
            .data {
              td {
                padding: 10px;
                white-space: nowrap;
              }
            }
          }
        }
        .footer {
          .important {
            color: #ed1d24;
          }
        }
      }
    }
  }

  .regularizacion-table-container {
    min-height: 400px;
  }

  .estado-solicitud-green{
    color: #009b63;
  }
  .estado-solicitud-yellow{
    color: #ffb700;
  }
  .estado-solicitud-red{
    color: #ea212e;
  }


}
.regularizacion-table-select{
  svg{
    width: 15px;
  }
  div{
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
