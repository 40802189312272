@import "./variables";

.historial-pedidos {
  .pedidos-resumen {
    width: calc(624px - 36px);
    height: calc(72px - 32px);
    padding: 16px 24px 16px 12px;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(14, 105, 175, 0.3);
    margin-bottom: 23.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1px;
    .separator {
      border-right: solid 1px #dee5ea;
      height: 40px;
      width: 1px;
    }
    .total {
      display: flex;

      .selected {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 20px;
        .totaltext {
          font-size: 12px;
          font-weight: bold;
          line-height: 1.33;
          color: $copec-dark-blue;
        }
        .month {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.33;
          color: $copec-dark-blue;
        }
      }
    }

    .liters {
      width: 162px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.14;
      color: $copec-dark-blue;
    }

    .resumen-button {
      border-radius: 36px;
      background-color: $copec-dark-blue;
      width: 160px;
      height: 32px;
      font-size: 12px;
      font-weight: bold;
      color: white;
      border: none;
      cursor: pointer;
      &.hallow {
        background-color: #bdbdbd;
        cursor: not-allowed;
      }
    }
  }

  .historialp-options {
    max-width: 1320px;
    margin-top: 12px;

    &.no-mtop{
      margin-top: 0;
    }
  }

  .actions {
    margin: 12px 0 14px;
    display: flex;
    flex-direction: row;
    .buttons {
      margin-top: 19px;
    }
    .filter {
      float: left;

      .filter-title {
        color: $copec-dark-blue;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.34px;
        margin-bottom: 6px;
      }

      .filter-input {
        .search {
          margin: 18px 31px 0;
          width: 222px;
          border: 1px solid $copec-grey-3;
          border-radius: 20px;
          padding: 12px 10px 13px 26px;
          font-size: 12px;
          font-weight: 600;
          color: $copec-dark-blue;

          ::placeholder {
            color: $copec-grey-3;
          }

          &:placeholder-shown {
            background: url(../assets/img/lupa.svg) no-repeat;
            background-size: 30px;
            background-position: right 7px center;
          }

          &:not(:placeholder-shown) {
            background: url(../assets/img/cross-blue-icon.svg) no-repeat;
            background-size: 26px;
            background-position: right 13px center;
            &:hover {
              background: none;
            }
          }

          &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 26px;
            width: 26px;
            background: url(../assets/img/cross-blue-icon.svg) no-repeat;
            background-position: right 0;
            display: block;
          }

          &.nomarginsearch {
            margin-left: 0;
          }
        }
      }
    }

    .filter-with-margin-left {
      margin-left: 30px;
    }
  }

  .pedidos-table {
    &.pagos {
      //width: 1322px;
      width: 1200px;
    }
    .numeric-select {
      width: 180px !important;
      .comparision-type {
        padding-left: 5px;
      }
      input {
        width: calc(100% - 28px);
        padding-right: 2px;
        &.equal-selected {
          width: calc(100% - 21px) !important;
        }
        &.comparision-selected {
          width: calc(100% - 28px) !important;
        }
        &.all-selected {
          width: calc(100% - 17px) !important;
        }
      }
    }

    .highlighter-container {
      width: 160px;
    }

    .react-datepicker-wrapper {
      width: 100% !important;
      input {
        width: 167px;
        border: none;
        border-radius: 3px;
        padding: 10px 5px;
        font-size: 12px;
        color: $copec-dark-blue;
        &.active {
          border: 1px solid #6cbfff;
          width: 167px;
        }
      }
    }

    &.dinamo {
      .numeric-select {
        width: 136px !important;
      }
      .highlighter-container{
        width: calc(136px - 16px);
      }
      .table-select {
        width: 136px;
      }

      .react-datepicker-wrapper {
        width: 100% !important;
        input {
          width: 125px;
          &.active {
            width: 125px;
          }
        }
      }

    }
  }

  .pedidos-table {
    tbody {
      tr {
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
            padding-left: 20px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
        }
      }
      td {
        &.logistico {
          .mensaje {
            font-size: 10px;
          }
        }
      }
    }
    .th-data {
      width: calc(179px - 16px);
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.th-select {
        width: 42px;
      }
    }

    &.dinamo {
      .th-data {
        width: calc(136px - 16px);
      }
    }

    .filter-content {
      &.no-padding-left {
        padding-left: 0;
      }

      &.active {
        .numeric-select {
          input.equal-selected,
          input.comparision-selected,
          input.all-selected {
            border: solid 1px #6cbfff;
            border-left: none;
          }
          .comparision-type {
            border: solid 1px #6cbfff;
            border-right: none;
          }
        }
      }
    }

    .numeric-select {
      width: 68px;
    }

    /* .comparision-type.all-selected {
      width: 98px !important;
    }  */

    .document {
      cursor: pointer;
      position: relative;
      .documento-info {
        position: absolute;
        left: -200px;
        visibility: visible;
        background: white;
        border-radius: 4px;
        color: $copec-dark-blue;
        padding: 20px 11px;
        box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
        .title {
          font-weight: bold;
          font-size: 10px;
          text-align: center;
          padding: 5px 0;
        }
        .content {
          //background: $sidebar-grey;
          //padding: 7px 6px;
          table {
            .headers {
              th {
                color: $copec-dark-blue;
              }
            }
            .data {
              td {
                padding: 10px;
                white-space: nowrap;
              }
            }
          }
        }
        .footer {
          .important {
            color: #ed1d24;
          }
        }
      }

      /* &:hover {
          .documento-info {
            visibility: visible;
          }
        } */
    }
  }

  .pedidos-table-container {
    min-height: 400px;
  }
}

.resumen-periodo-pedidos {
  width: 864px;
  margin: 0 auto;
  margin-top: 23.5px;
  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.78;
    color: $copec-dark-blue;
    margin-bottom: 0;
  }
  .title-separator {
    width: 191px;
    margin: 7.5px 0;
  }

  table {
    width: 100%;
    display: table;
    border-collapse: inherit;
    margin: 0;
    border-spacing: 0;
    font-size: 12px;
    margin-bottom: 16px;
    padding-top: 8px;
    thead {
      width: 100%;
      background: $copec-dark-blue;
      tr {
        th {
          font-weight: 800;
        }
      }
    }
    th,
    tfoot td {
      border: 0;
      font-weight: bold;
      line-height: 1.6;
      &:first-child {
        padding: 12px 10px 12px 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        padding: 12px 15px 12px 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &[colspan="3"] {
        padding: 5px;
      }
    }
    td {
      background: white;
    }
    tbody {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 10px;
        border-bottom: 1px solid $copec-light-grey;
        &.align-right {
          padding: 12px 0 !important;
        }
      }
      tr {
        td:last-child {
          padding: 12px 15px !important;
          &.tdsep {
            padding: 0 15px !important;
          }
        }
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
          //width: 40%;
        }
        &.data {
          &.subtotal {
            td:first-child,
            td:last-child {
              font-weight: bold;
            }
            td {
              font-size: 14px;
            }
          }
        }
      }
    }
    tfoot td {
      font-size: 14px;
      &:last-child {
        text-align: right;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    float: unset;
    margin-top: 30px;
    .print-button {
      width: 48px;
    }
    .actions-buttons {
      width: 224px;
      height: 48px;
      font-size: 16px;
    }
  }
}

.detalle-pedido {
  width: 864px;
  margin: 0 auto;
  margin-top: 23.5px;
  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.78;
    color: $copec-dark-blue;
    margin-bottom: 0;
  }
  h2{
    margin: 0 0 10px 0;
    font-size: 16px;
    color:$copec-grey;
    font-weight: bold;
  }
  .title-separator {
    width: 191px;
    margin: 7.5px 0;
  }

  .item {
    .title {
      font-size: 12px;
      color: $copec-dark-blue;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .value {
      width: calc(192px - 32px);
      height: calc(32px - 16px);
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 500;
      background-color: #f1f5f8;
      color: #7c97ab;
      border-radius: 4px;
      &.overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &.full {
        width: calc(640px - 32px);
      }
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 23.5px;

    &.col4 {
      .item {
        .value {
          width: calc(192px - 32px);
        }
      }
    }
  }

  table {
    width: 100%;
    display: table;
    border-collapse: inherit;
    margin-top: 24px;
    border-spacing: 0;
    font-size: 12px;
    margin-bottom: 16px;
    padding-top: 8px;
    thead {
      width: 100%;
      background: $copec-dark-blue;
      tr {
        th {
          font-weight: 800;
        }
      }
    }
    th,
    tfoot td {
      border: 0;
      font-weight: bold;
      line-height: 1.6;
      &:first-child {
        padding: 12px 10px 12px 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        padding: 12px 15px 12px 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &[colspan="3"] {
        padding: 5px;
      }
    }
    td {
      background: white;
    }
    tbody {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 10px;
        border-bottom: 1px solid $copec-light-grey;
        &.align-right {
          padding: 12px 0 !important;
        }
      }
      tr {
        td:last-child {
          padding: 12px 15px !important;
          &.tdsep {
            padding: 0 15px !important;
          }
        }
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
          //width: 40%;
        }
        &.data {
          &.subtotal {
            td:first-child,
            td:last-child {
              font-weight: bold;
            }
            td {
              font-size: 14px;
            }
          }
        }
      }
    }
    tfoot td {
      font-size: 14px;
      &:last-child {
        text-align: right;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    float: unset;
    margin-top: 30px;
    .print-button {
      width: 48px;
    }
    .actions-buttons {
      width: 224px;
      height: 48px;
      font-size: 16px;
    }
  }
}

.pedidos-table-container{
  @media print {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.pedidos-table-fix {
  @media print {
    transform: scale(0.7);
    transform-origin: top left;
    position: relative;
    top: 30px;
    left: 20px;
    display: inline-block;
    tbody {
      tr {
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
            padding-left: 20px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
        }
      }
    }
    .th-data {
      width: calc(179px - 16px);
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.th-select {
        width: 42px;
      }
    }
    .react-datepicker__input-container {
      input {
        width: 175px !important;
      }
    }
    .filter-content .table-select {
      width: 180px !important;
    }
    .numeric-select {
      width: 180px !important;
      .comparision-type {
        padding-left: 5px;
      }
      input {
        width: calc(100% - 28px);
        padding-right: 2px;
        &.equal-selected {
          width: calc(100% - 21px) !important;
        }
        &.comparision-selected {
          width: calc(100% - 28px) !important;
        }
        &.all-selected {
          width: calc(100% - 17px) !important;
        }
      }
    }
  }
}
