.bonificacion {

  .bonificacion-options{
    max-width: 1272px;
  }

  .th-data {
    width: calc(150px - 16px);
    padding: 0 8px;
    position: relative;
    height: 46px;
    &.th-select {
      width: 42px;
    }
  }

  .cabecera {
   max-width: 1272px;
  }

  .item {
    margin-bottom: 20px;
    .title {
      font-size: 12px;
      color: $copec-dark-blue;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .value {
      width: calc(192px - 32px);
      height: calc(32px - 16px);
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 500;
      background-color: #f1f5f8;
      color: #003965;
      &.overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &.full {
        width: calc(640px - 32px);
      }
    }
  }

  .infob {
    display: flex;
    justify-content: space-between;
    margin-top: 23.5px;
    flex-direction: row;

    &.col4 {
      .item {
        .value {
          width: calc(192px - 32px);
        }
      }
    }
  }

  .documento-info {
    background: white;
    border-radius: 4px;
    color: $copec-dark-blue;
    padding-bottom: 10px;
    
    
    .title {
      font-weight: bold;
      font-size: 10px;
      text-align: center;
      padding: 5px 0;
    }
    .content {
      //background: $sidebar-grey;
      //padding: 7px 6px;
      display: flex;
    justify-content: center;
    gap:20px;
      table {
        .headers {
          th {
            color: $copec-dark-blue;
            padding: 0 20px 0 0;
          }
        }
        .data {
          td {
            padding: 5px;
            white-space: nowrap;
            &.datatitle{
              background-color: #dee4ea;
              color: $copec-dark-blue;
              font-weight: 800;
            }
          }
        }
      }
    }
    .footer {
      .important {
        color: #ed1d24;
      }
    }
  }
}



.main{

  @media print {
    padding: 2rem;
    .datos{
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      max-width: 1260px;
      color:$copec-dark-blue;
      .title{
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .dato{
        font-weight: 500;
      }
      img{
        width: 300px;
      }
    }
  }

  .datos{
    display: none;
  }
  
  @media print {
    .bonificacion-options{
      max-width: 1260px;
    }
  
    .th-data {
      width: calc(150px - 16px);
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.th-select {
        width: 42px;
      }
    }
  
    .cabecera {
     max-width: 1272px;
    }
  
    .item {
      margin-bottom: 20px;
      .title {
        font-size: 12px;
        color: $copec-dark-blue;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .value {
        width: calc(192px - 32px);
        height: calc(32px - 16px);
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 500;
        background-color: #f1f5f8;
        color: #7c97ab;
        &.overflow {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.full {
          width: calc(640px - 32px);
        }
      }
    }
  
    .infob {
      display: flex;
      justify-content: space-between;
      margin-top: 23.5px;
      flex-direction: row;
  
      &.col4 {
        .item {
          .value {
            width: calc(192px - 32px);
          }
        }
      }
    }
  
    .documento-info {
      background: white;
      border-radius: 4px;
      color: $copec-dark-blue;
      padding-bottom: 10px;
      
      
      .title {
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        padding: 5px 0;
      }
      .content {
        //background: $sidebar-grey;
        //padding: 7px 6px;
        display: flex;
      justify-content: center;
      gap:20px;
        table {
          .headers {
            th {
              color: $copec-dark-blue;
              padding: 0 20px 0 0;
            }
          }
          .data {
            td {
              padding: 5px;
              white-space: nowrap;
              &.datatitle{
                background-color: #dee4ea;
                color: $copec-dark-blue;
                font-weight: 800;
              }
            }
          }
        }
      }
      .footer {
        .important {
          color: #ed1d24;
        }
      }
    }
  }
}


