@import "./variables";

.informe-facturacion-container{
  width: 1312px;
  margin: 0 auto;
  .cards-container{
    width: 1311px;
    margin: 0 auto;
    display: flex;
    gap: 32px;
    margin-bottom: 23.5px;
  }
  .informe-facturacion-main-container{
    .total-filters-container{
      display: flex;
      gap: 32px;
      .filters-container{
        display: flex;
        gap: 32px;
        .filter{
          .fecha{
            box-sizing: border-box;
            width: 168px;
            height: 33px;
            border: solid 1px #7c97ab;
            // padding: 8px 8px 8px 16px;
            border-radius: 4px;
            .react-datepicker-wrapper{
              input{
                padding: 8px 0px 6px 10px;
              }
            }
          }
          .grupo-articulos-select div{
            font-family: Quicksand;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
          }
        }
      }
      .search-bar-container{
        width: 180px;
        height: 32px;
        margin-top: 21px;
        .filters-container{
          .filter{
            .search{
              margin: 0;
              width: 180px;
              height: 32px;
              padding: 12px 0px 12px 26px
            }
            .search:placeholder-shown{
              background-position: right 2px center;
              background-size: 26px;
            }
            .search:not(:placeholder-shown){
              background-position: right 2px center;
            }
            .search:hover{
              padding-right: 0px;
            }
          }
        }
      }
      .detalle-container{
        width: 100px;
        height: 32px;
        margin-top: 32px;
        .detalle-button{
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px #003965;
          border-radius: 24px;
          padding: 8px 1px 8px 2px;
          gap: 6px;
          cursor: pointer;
          img{
            width: 12px;
            height: 12px;
          }
          p{
            font-family: Quicksand;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #003965;
            margin: 0;
          }
        }
      }
    }
    .informe-facturacion-table-container{
      width: 100%;
      .informe-facturacion-table{
        width: 100%;
        margin: 0 auto;
        position: relative;
        margin-top: 12px;
        // padding-left: 13px;
        // padding-right: 8px;
        padding: 4px 7px 8px !important;
        .th-data{
          margin-right: 2px;
          padding: 0;
          &.width-120{
            width: 120px;
          }
          &.width-196{
            width: 196px;
            margin: 0 !important;
          }
          &.width-197{
            width: 197px;
          }
        }
        .th-text{
          padding: 17px 8px 16px;
          position: relative;
          transform: none;
          &.two-lines{
            padding: 9px 8px;
          }
        }
        tbody{
          .data{
            .no-data{
              padding-left: 8px
            }
            td{
              width: auto;
            }
            &.table-link{
              text-decoration: underline;
              cursor: pointer;
            }
            &.row-with-rounded-borders{
              td:first-child {
                border-top-left-radius: 4px !important;
                border-bottom-left-radius: 4px !important;
              }
              td:last-child {
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
              }
            }
          }
          .table-separator-row-border-top{
            border: none !important;
            height: 8px;
          }
        }
      }
    }
  }
}