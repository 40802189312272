.administracion-filters-section{
	width: 100%;
	margin-top: 48px;
	margin-bottom: -6px;
	display: inline-block;
	.selector{
		cursor: pointer;
		position: relative;
		width: 308px;
		float: left;
		color: $copec-grey;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		padding: 0 10px;
		.selector-text{
			padding: 10px 10px;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
		}
		&.active{
			color: $copec-dark-blue;
			:after {
			  content:'';
			  position: absolute;
			  bottom: 0;
			  left: 0;
			  right: 0;
			  background:red;
			  height: 4px;
			  border-radius: 2px;
			}

			.selector-text{
				background: $copec-gris-input;
			}
		}
	}
}

.tabla-copec-1{
  font-size: 12px;
  background: $copec-separator;
  border-radius: 8px;
  border: 8px solid $copec-separator;
  border-spacing: 0px;
  min-width: 416px;
  float: left;
  margin: 20px 50px;

  &-centered{
    margin: 0 auto;
  }

  .column-width-304{
    width: 304px;
  }

  .column-width-100{
    width: 100px;
  }

  th, td{
    padding: 16px 5px 15px 24px;
    font-size: 12px;
    font-weight: bold;
  }

  th{
    text-align: left;
    color: white;
    background: $copec-dark-blue;
    //border: 1px solid $copec-dark-blue;
    border: none;
    &:first-child{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child{
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  td{
    color: $copec-dark-blue;
  }

  .acciones-column{
    width: 128px;
  }

  .tabla-copec-1-separator{
    height: 8px;
    td {
      padding: 0 !important;
    }
  }

  .tabla-copec-1-tbody-separator{
    height: 1px;
    td{
      .tabla-copec-1-tbody-separator-inner{
        background: $copec-separator;
        height: 1px;
        margin: 0 16px;
      }
    }
  }

  tbody{
    tr{
      background: white;

      &:nth-child(even) {
       background-color: #f0f5f8;
      }

      &:nth-child(odd) {
       background-color: white;
      }

      &.selected{
        background: rgba($copec-azul-2, 0.25);
      }

      &:first-child{
        td:first-child{
          border-top-left-radius: 8px;
        }
        td:last-child{
          border-top-right-radius: 8px;
        }
      }

      &:last-child{
        td:first-child{
          border-bottom-left-radius: 8px;
        }
        td:last-child{
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  &.multi-select-table{
    tbody{
      tr:nth-child(even) {
        &.selected{
          background: rgba($copec-azul-2, 0.25);
        }
      }
      tr:nth-child(odd) {
        &.selected{
          background: rgba($copec-azul-2, 0.15);
        }
      }
    }
  }

  .table-actions{
    span{
      text-decoration: underline;
      &.left-action{
        margin-right: 25px;
      }
      &.right-action{
        margin-right: 22px;
      }
    }
  }

  .user-table-eds-activas{
    width: 147px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.table-with-checkboxes{
  width: 416px;
  display: block;
  max-height: 348px;
  overflow: auto;
  th{
    position: sticky;
    top: 0;
    width: 400px;
  }
}

.table-with-checkboxes-checkbox-td{
  width: 15px;
  padding-right: 0 !important;
}
.table-with-checkboxes-checkbox{
  width: 15px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.selected-filter-container{
  border-top: 2px solid #bccad3;
  padding-top: 22px;

  &--no-border{
    border: none;
  }

  .section-title{
    text-align: center;
    font-size: 18px;
    color: $copec-dark-blue;
    font-weight: bold;
    margin: 25px 0 15px;
    &--left{
      text-align: left;
    }
  }

  .centered-container{
    display: block;
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
    &--left{
      margin: 0 80px;
      float: left;
    }
    &--right{
      margin: 0 80px;
      float: right;
    }
  }

  hr{
    margin: 30px 0 10px;
  }

  .info-text{
    color: $copec-dark-blue;
    margin-bottom: 2px;
    display: flex;
  }
  .remove-item{
    margin: 3px 0 0 8px;
    float: right;
    cursor: pointer;
  }

  .estaciones-column{
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .section{
    display: inline-block;
    width: 100%;
  }

  .th-text{
    position: relative;
    transform: none;
  }
}

.correo-input{
  padding: 7px 10px 6px;
  border: 1px solid $copec-dark-blue;
  border-radius: 4px;
  width: 192px;
  margin-top: 10px;
}

.nuevo-correo-button{
  padding: 8px 0 7px;
  color: white;
  font-weight: bold;
  background: $copec-dark-blue;
  width: 150px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 20px;

  &:disabled{
    background: $header-hover;
    cursor: default;
  }
}

.modal-item{
  margin-top: 6px;
  width: 140px;
  float: left;
  img{
    margin-left: 6px;
    cursor: pointer;
  }
}

.personal-estacion-info-modal{
  width: 300px;

  .title{
    font-size: 20px;
    margin: 10px 0;
  }

  .content--data{
    margin-bottom: 15px;
    &--title{
      font-weight: bold;
    }
  }
  .property-input{
    margin-top: 5px;
    border: 1px solid $copec-dark-blue;
    padding: 7px 8px 6px;
    border-radius: 4px;
    width: 250px;
    &.property-input-estacion{
      width: 120px;
      margin-top: 20px;
    }
  }

  .new-estacion-section{
    display: inline-block;
  }
  .content {
    max-height: 450px;
  }
}

.personal-estacio-modal-button{
  padding: 8px 0 7px;
  color: white;
  font-weight: bold;
  background: $copec-dark-blue;
  width: 200px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  display: block;
  margin: 0 auto 20px;

  &:disabled{
    background: $header-hover;
    cursor: default;
  }

  &.cancel{
    background: $copec-red-two;
  }

  &.input-button{
    width: 100px;
    display: inline-block;
    margin-left: 15px;
  }
}

.show-pointer{
  cursor: pointer;
}

