@import "./variables";
.cierre-mensual{
	width: 1312px;
	.info-input{
		background: $copec-gris-input;
		padding: 9px 16px 8px;
		font-size: 12px;
		font-weight: 500;
		color: $copec-dark-blue;
		width: 160px;
		border-radius: 4px;
	}

	.filters-container{
		width: 100%;
		margin-top: 24px;
		&.fluctuaciones{
			margin-top: 34px;
			margin-bottom: 0;
		}
		.main-button{
			margin-left: 35px;
		}
		.filter-input{
			width: 192px;
			&.margin-right-32{
				margin-right: 32px;
			}
			&.margin-right-40{
				margin-right: 40px;
			}
		}
	}

	.actions-container{
		float: right;
		margin-top: 21px;
    display: flex;
    &.arcoprime{
      float: unset;
    }
	}

	.main-button{
		font-size: 12px;
		padding: 5px;
		width: 192px;
		height: 32px;
    &.iconbutton{
        .iconbutton-container{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
    }
	}

	.table-title{
		font-size: 12px;
		font-weight: bold;
		color: $copec-dark-blue;
		margin-bottom: 8px;
	}

	.table-with-material-title{
    width: 100%;
    border-collapse: inherit;
    margin-top: 0px !important;
    border-spacing: 0;
    font-size: 12px;
    padding-top: 8px;
  &.justificacion-fluctuaciones{
    .th-data{
      width: 96px !important;
    }
  }
    .table-header{
      display: flex;
      margin-bottom: 5px;
      justify-content: space-between;
      .legend{
        display: flex;
        justify-content: space-between;
        gap:24px;
        margin-left: 8px;
        .material{
          display: flex;
          gap:8px;
          .rect{
            width: 8px;
            height: 16px;
            border-radius: 2px;
            background-color: #0e69af;
            &.concesionado{
                background-color: #07d188;
            }
          }
          .text{
            font-weight: 700;
            color:$copec-dark-blue;
          }  
        }
      }
    }

    th .th-data{
      padding: 16px 7px 17px;
      &.two-lines{
        padding: 9px 7px 9px;
        display: flex;
        align-items: center;
      }
      &.width-80{
      	width: 66px;
      }
      &.width-100{
      	width: 86px;
      }
      &.width-110{
        width: 96px;
      }
      &.width-116{
        width: 102px;
      }
      &.width-60{
      	width: 46px;
      }
      .th-text{
        top: unset;
        position: unset;
        transform: unset;
      }
    }
    th{
    	padding: 0;
    }

    tbody{
    	tr:nth-child(even) {
			  background-color: #f1f5f8;
			}

			tr:nth-child(odd) {
			  background-color: white;
			}
      tr.odd{
        background-color: white;
      }
      tr.even{
        background-color: #f1f5f8;
      }
    	td{
    		padding: 8px;
        padding-right: 12px;
    		line-height: 2;
    		text-align: right;
        &.total-data-container{
          padding-right: 8px !important;
        }
        .total-data{
          background-color: rgba(16, 128, 213, 0.1019607843);
          border-radius: 16px;
          padding: 0 8px;
          text-align: right;
        }
    		&.align-center{
    			text-align: center;
    		}
        &.align-left{
    			text-align: left;
    		}
    		&.def-tanque-container{
    			text-align: left;
          .top-row{
            white-space: nowrap;
            font-size: 12px;
            font-weight: 700;
          }
          .bottom-row{
            font-weight:500;
          }
    		}
    		&.alerta-container{
    			padding: 12px 0 9px;
    			line-height: normal;
    			text-align: center;
    		}
	    	.type{
	    		float: left;
	        width: 8px;
	        height: 24px;
	        border-radius: 2px;
	        margin-right: 8px;
          &.consignado{
            background-color: #0e69af;
          }
	        &.concesionado{
	          background-color: #07d188;
	        }
          &.tanques2{
            height: 79px !important;
          }
          &.nomarginright{
            margin-right: 0px !important;
          }
	      }
	    }
    }
    tr.total-row{
    	background: white;
    	border-radius: 6px;
    	td{
    		padding: 8px 4px;
    		line-height: 2;
    		&:first-child{
    			border-top-left-radius: 6px;
    			border-bottom-left-radius: 6px;
    		}
    		&:last-child{
    			border-top-right-radius: 6px;
    			border-bottom-right-radius: 6px;
    		}
    		.total-data{
    			background-color: #1080d51a;
    			border-radius: 16px;
    			padding: 0 8px;
    			text-align: right;
    		}
        &.total-data-container{
          padding-right: 8px !important;
        }
    	}
    }
    &.fluctuaciones{
      margin-bottom: 16px;
    	tbody{
	    	td{
	    		padding: 12px 16px 12px 8px;
	    		&.def-tanque-container{
	    			padding: 8px 0 8px 8px;
	    			line-height: normal;
	    			.def-tanque{
			      	float: left;
			      	text-align: left;
			      }
	    		}
          &.total-data-container{
            border-top: 1px solid #dee5ea !important;
          }
	    		&.alerta-container{
	    			padding: 16px 0 13px;
	    		}
		    	.type{
		    		float: left;
		        background-color: #0e69af;
		        width: 8px;
		        height: 32px;
		        border-radius: 2px;
		        margin-right: 8px;
		        &.concesionado{
		          background-color: #07d188;
		        }
            &.tanques2{
              height: 75px;
            }
            &.tanques3{
              height: 125px;
            }
            &.tanques4{
              height: 175px;
            }
		      }
		    }
        .total-row{
          td{
            padding: 8px 16px 8px 8px;
          }
          .total-text-sifones{
            text-align: left;
            padding-left: 25px;
          }
        }
	    }

    }
  }
  .cierre-mensual-bottom{
    margin-top: 24px;
    text-align: center;
    .red-button{
      padding: 9px 30px 8px;
      width: 192px;
      text-align: center;
      margin-top: 32px;
      &:disabled{
        background: #bdbdbd;
        cursor: default;
      }
    }
    &.cierre-mensual-bottom-justificacion{
      width: 864px;
      margin: 0 auto;
      .inputs-content{
        width: 100%;
        margin: 24px 0 40px;
        display: flex;
        gap: 32px;
        .info{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .counter{
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: right;
            color: #003965;
          }
        }
        .cierre-mensual-justificacion-text{
          width: 528px;
          border: 1px solid #809cb2;
          border-radius: 4px;
          resize: none;
          float: left;
        }

        .main-button-cierre{
          width: 304px;
          float: right;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
        .hidden-file-input{
          width: 304px;
          float: right;
          margin-top: 20px;
          border: 2px solid black;
        }
        .uploaded-file-container{
          background: $copec-gris-input;
          width: 264px;
          height: 40px;
          border-radius: 4px;
          padding: 16px 20px;
          position: relative;
          .left-side{
            float: left;
            width: 44px;
            height: 40px;
            padding-top: 7px;
            border-right: 1px solid $copec-grey;
            display: inline-block;
          }
          .right-side{
            float: left;
            padding-left: 15px;
            display: inline-block;
            width: 155px;
            height: 40px;
            .file-name{
              color: $copec-dark-blue;
              font-size: 12px;
              width: 192px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .file-type{
              color: $active-select-border;
              font-size: 14px;
              font-weight: bold;
              display: inline-block;
            }
          }
          .solicitud-regularizacion-cross-icon{
            position: absolute;
            top: 20px;
            right: 16px;
            font-size: 26px;
            color: $copec-dark-blue;
            cursor: pointer;
    
            &:after{
              display: inline-block;
              content: "\00D7"; /* This will render the 'X' */
            }
          }
        }
        .justification-file-container{
          cursor: pointer;
        }
      }
      .cierre-mensual-justificacion-cancelar{
        margin-right: 34px;
      }
      .cierre-mensual-justificacion-volver{
        margin-top: 32px;
      }
    }
  }
  .cierre-mensual-justificar-icon{
    margin-bottom: -6px;
    cursor: pointer;
  }
  .alert-container-cierre-mensual{
    position: relative;
    .alert-tooltip {
      position: absolute;
      visibility: hidden;
      top: 34px;
      right: -4px;
      background: white;
      box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
      padding: 8px;
      border-radius: 4px;
      z-index: 100;
      &.confirmacion {
        overflow: visible;
        white-space: nowrap;
        padding-right: 12px;
      }
      .content {
        background-color: #f1f5f8;
        color: $copec-dark-blue;
        font-size: 10px;
        font-weight: 500;
        padding: 5px;
        border-radius: 4px;
      }
    }
    &:hover {
      .alert-tooltip {
        visibility: visible;
      }
    }
  }

  .section-title{
    margin-top: 24px;
    font-weight: bold;
    .title{
      font-size: 18px;
      color: $copec-dark-blue;
      line-height: 1.78;
    }
    .subtitle{
      font-size: 16px;
      color: #7c97ab;
      line-height: 1.5;
    }

  }
  .important-message{
    margin-top: 16px;
    &.error{
      background: rgba(234, 33, 46, 0.1);
      font-weight: bold;
      font-size: 12px;
    }
  }

  .table-with-material-title2{
    width: 1312px;
    border-collapse: inherit;
    margin-top: 0px !important;
    border-spacing: 0;
    font-size: 12px;
    padding-top: 8px;
    .table-header{
      display: flex;
      margin-bottom: 5px;
      justify-content: space-between;
      .legend{
        display: flex;
        justify-content: space-between;
        gap:24px;
        margin-left: 8px;
        .material{
          display: flex;
          gap:8px;
          .rect{
            width: 8px;
            height: 16px;
            border-radius: 2px;
            background-color: #0e69af;
            &.concesionado{
                background-color: #07d188;
            }
          }
          .text{
            font-weight: 700;
            color:$copec-dark-blue;
          }  
        }
      }
    }

    th .th-data{
      // padding: 16px 7px 17px;
      padding: 0px;
      height: 48px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      &.two-lines{
        height: 48px;
        // padding: 9px 7px 9px;
        padding: 0px;
        display: flex;
        align-items: center;
      }
      &.material{
        padding: 8px 7.5px 8px 8px;
      }
      &.litros-controlador{
        padding: 8px;
      }
      &.alerta{
        padding: 8px 7px 8px 8px;
      }
      &.width-62{
      	width: 62px;
      }
      &.width-89{
      	width: 89px;
      }
      &.width-100{
      	width: 86px;
      }
      &.width-110{
        width: 96px;
      }
      &.width-116{
        width: 102px;
      }
      &.width-60{
      	width: 46px;
      }
      .th-text{
        top: unset;
        position: unset;
        transform: unset;
      }
    }
    th{
    	// padding: 0;
      border: 3px solid #dee4ea;
    }

    tbody{
    	tr:nth-child(6n+1), 
    	tr:nth-child(6n+2), 
      tr:nth-child(6n+3) {
			  // background-color: #f1f5f8;
        background-color: white;
			}
      
			tr:nth-child(6n+4), 
			tr:nth-child(6n+5), 
			tr:nth-child(6n+6) 
      {
        background-color: #f1f5f8;
        // background-color: white;
			}
      // tr.odd{
      //   background-color: white;
      // }
      // tr.even{
      //   background-color: #f1f5f8;
      // }
    	td{
        
    		// padding: 8px;
        // padding-right: 12px;
        max-width: 89px !important;
    		line-height: 2;
    		text-align: right;
        padding-right: 10px;
        // margin-right: 5px;
        &.alert-container-cierre-mensual{
          max-width: 62px;
          // .alert-tooltip{
          //   padding: 0;
          // }
        }
        &.total-border-top{
          border-top: 1px solid #F1F5F8 !important;
          // padding-right: 0px;
        }
        &.align-left{
          text-align: left;
          padding-left: 5px !important;
          padding-right: 5px;
        }
        .total-container{
          box-sizing: border-box;
          // padding-right: 4px;
          max-width: inherit;
          margin: 0;
          margin-top: 8px;
          margin-right: 0 !important;
          margin-bottom: 7px;
          // width: 89px;
          height: 22px;
          // margin: 8.5px 8px 2px 16px;
          // padding: 1px;
          border-radius: 16px;
          background-color: rgba(16, 128, 213, 0.1);
          span{
            margin-right: 5px;
          }
        }
        &.total-data-container{
          padding-right: 8px !important;
        }
        .total-data{
          background-color: rgba(16, 128, 213, 0.1019607843);
          border-radius: 16px;
          padding: 0 8px;
          text-align: right;
        }
    		&.align-center{
    			text-align: center;
    		}
    		&.def-tanque-container{
    			text-align: left;
          .top-row{
            white-space: nowrap;
            font-size: 12px;
            font-weight: 700;
          }
          .bottom-row{
            font-weight:500;
          }
    		}
    		&.alerta-container{
          max-width: 62px;
    			padding: 12px 0 9px;
    			line-height: normal;
    			text-align: center;
    		}
	    	.type{
	    		float: left;
	        width: 8px;
	        height: 24px;
	        border-radius: 2px;
	        margin-right: 8px;
          &.consignado{
            background-color: #0e69af;
          }
	        &.concesionado{
	          background-color: #07d188;
	        }
          &.tanques2{
            height: 79px !important;
          }
          &.nomarginright{
            margin-right: 0px !important;
          }
	      }
	    }
    }
    tr.total-row{
    	background: white;
    	border-radius: 6px;
    	td{
    		padding: 8px 4px;
    		line-height: 2;
    		&:first-child{
    			border-top-left-radius: 6px;
    			border-bottom-left-radius: 6px;
    		}
    		&:last-child{
    			border-top-right-radius: 6px;
    			border-bottom-right-radius: 6px;
    		}
    		.total-data{
    			background-color: #1080d51a;
    			border-radius: 16px;
    			padding: 0 8px;
    			text-align: right;
          margin-right: 5px;
    		}
        &.total-data-container{
          padding-right: 8px !important;
        }
    	}
    }
    &.fluctuaciones{
      margin-bottom: 16px;
    	tbody{
	    	td{
	    		padding: 12px 16px 12px 8px;
	    		&.def-tanque-container{
	    			padding: 8px 0 8px 8px;
	    			line-height: normal;
	    			.def-tanque{
			      	float: left;
			      	text-align: left;
			      }
	    		}
          &.total-data-container{
            border-top: 1px solid #dee5ea !important;
          }
	    		&.alerta-container{
	    			padding: 16px 0 13px;
	    		}
		    	.type{
		    		float: left;
		        background-color: #0e69af;
		        width: 8px;
		        height: 32px;
		        border-radius: 2px;
		        margin-right: 8px;
		        &.concesionado{
		          background-color: #07d188;
		        }
		      }
		    }
        .total-row{
          td{
            padding: 8px 16px 8px 8px;
          }
          .total-text-sifones{
            text-align: left;
            padding-left: 25px;
          }
        }
	    }

    }
  }
  

}


