@import "./styles/common";
@import "./styles/common-mobile";
@import "./styles/resumen";
@import "./styles/cartola";
@import "./styles/results-table";
@import "./styles/numeric-select";
@import "./styles/comprobante-pago";
@import "./styles/abonar-en-banco";
@import "./styles/terminos";
@import "./styles/formulario-pedidos";
@import "./styles/camion";
@import "./styles/historial-pedidos";
@import "./styles/pedidos-activos";
@import "./styles/custom-select";
@import "./styles/bonificacion";
@import "./styles/historial-flutuaciones";
@import "./styles/historial-de-modificaciones";
@import "./styles/historial-de-alarmas";
@import "./styles/configuracion-de-alertas";
@import "./styles/configuracion-tanques";
@import "./styles/fluctuaciones-ingreso-mediciones";
@import "./styles/timepicker";
@import "./styles/mantenimiento";
@import "./styles/pagination";
@import "./styles/cierre-diario";
@import "./styles/cierre-mensual";
@import "./styles/regularizacion";
@import "./styles/tct-tae";
@import "./styles/historial-fluctuaciones-cierre";
@import "./styles/informe-gestion";
@import "./styles/prefactura";
@import "./styles/administracion-dinamo";

.App {
  font-family: Quicksand;
  margin-top: 35px;
  display: inline;
}

button,
select,
a,
input,
textarea {
  font-family: Quicksand, serif;
}

textarea:focus, input:focus{
  outline: none;
}

.required-field:after{
    content: "*";
    color: $copec-red;
}
