.configuracion-de-alertas {
  width: 200px;
  margin: 40px auto;

  &__section {
    margin-bottom: 24px;
    &__title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: $copec-dark-blue;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .main-button {
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 8px;
        max-width: 14px;
      }
    }
  }
}
