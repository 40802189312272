.abonar-en-banco {
  padding: 0;
  &__content {
    display: flex;
    align-items: flex-start;
    padding-top: 32px;
    &.arcoprime{
      padding-top: 25px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    color: $copec-dark-blue;
    margin-bottom: 4px;
    margin-top: 0;
  }
  &__subtitle {
    font-size: 16px;
    color: $copec-subtitle;
    font-weight: bold;
    margin: 0;
  }
  &__step-content {
    width: 750px;
    font-weight: 500;
    .contingencia{
      color:$copec-dark-blue;
    }
  }
  &__table {
    width: 100%;
    display: table;
    border-collapse: inherit;
    margin: 0;
    border-spacing: 0;
    font-size: 12px;
    margin-bottom: 16px;
    padding-top: 8px;
    thead {
      width: 100%;
      background: $copec-dark-blue;
    }
    th,
    tfoot td {
      border: 0;
      font-weight: bold;
      line-height: 1.6;
      &:first-child {
        padding: 12px 10px 12px 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        padding: 12px 15px 12px 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &[colspan="3"] {
        padding: 5px;
      }
      &.mobile-separator-row {
        padding: 2px;
      }
    }
    td {
      background: white;
    }
    tbody {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 10px;
        border-bottom: 1px solid $copec-light-grey;
        padding-left: 0 !important;
        &.cargo {
          color: $copec-red;
        }
        &.abono {
          color: $copec-green;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &.bank-name-cell {
          &:first-child {
            width: 90%;
          }
          img {
            max-height: 28px;
            width: auto;
            max-width: 24%;
          }
        }
      }
      tr {
        &.separator {
          height: 2px;
          padding: 0 8px 0 8px;
        }
        td {
          &.trseparator {
            padding: 0 8px 0 8px !important;
            .line-sep {
              background-color: #dee5ea;
              height: 1px;
            }
          }
        }
        td:first-child {
          padding-left: 15px !important;
          &.trseparator {
            padding-left: 8px !important;
          }
        }
        &:first-child {
          td:first-child {
            border-top-left-radius: 4px;
          }
          td:last-child {
            border-top-right-radius: 4px;
          }
        }
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        td:first-child {
          padding-left: 20px;
          //width: 40%;
        }
      }
    }
    tfoot td {
      font-size: 14px;
      &:last-child {
        text-align: right;
      }
    }
  }
  &__step-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    button:first-child {
      margin-right: 32px;
    }
    .main-button {
      &.hallow {
        background: white;
        border: 1px solid $copec-dark-blue;
        color: $copec-dark-blue;
        &.disabled,
        &:disabled {
          border:1px solid #bdbdbd;
          color:#bdbdbd;
          background-color: white !important;
        }
        &.main-button-red {
          border: 1px solid $copec-red;
          color: $copec-red;
        }
      }
    }
  }
  &__disclaimer {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 12px;
    padding: 16px 12px;
    background-color: $sidebar-grey;
    border-radius: 6px;
    font-weight: 500;
    color: $copec-dark-blue;
    span {
      color: $copec-red-two;
    }
  }
  &__report {
    border: 1px solid $copec-grey-border;
    padding: 25px;
    border-radius: 6px;
    width: 600px;
    color: $copec-dark-blue;
    margin-bottom: 42px;
    &__title {
      text-align: center;
      margin: 28px 0;
      font-size: 18px;
    }
    &__details {
      padding: 18px;
      font-size: 10px;
      font-weight: 500;
      color: $copec-dark-blue;
    }
    &__table {
      width: 100%;
      padding: 0;
      border-collapse: inherit;
      background-color: white;
      border-spacing: 0;
      margin-top: 0;
      display: table;
      color: $copec-dark-blue;
      th,
      td {
        &.trseparator {
          padding: 0;
          .line-sep {
            background-color: #dee5ea;
            height: 1px;
          }
        }
        padding: 12px 18px;
        &:last-child {
          text-align: right;
          &.forceleft{
            text-align: left !important;
          }
        }
      }
      thead {
        th {
          color: $copec-dark-blue;
          font-size: 11px;
          border: none;
          background-color: $copec-grey-table;
          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $copec-light-grey;
          background-color: white !important;
        }
        td {
          font-size: 10px;
          font-weight: 500;
          border-bottom: 1px solid $copec-light-grey;
        }
      }
      tfoot {
        td {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    &__stamp {
      display: block;
      width: 67px;
      margin-left: auto;
      margin-top: 24px;
    }
    &__footer {
      padding: 35px;
      text-align: center;
      img {
        width: 120px;
        margin-bottom: 28px;
      }
      h3 {
        font-size: 18px;
        margin: 0 auto;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        font-weight: bold;
        color: $copec-subtitle;
      }
    }
    &-actions {
      width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px auto;
      & > *:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  &__report-mobile {
    display: none;
  }
  @media screen and (max-width: $mobile-break-point) {
    &__content {
      flex-direction: column;
      hr {
        margin-bottom: 100px;
      }
      & > * {
        width: 100%;
      }
    }

    &__stepper-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }

    &__step-actions,
    &__report-actions {
      position: fixed;
      left: 0;
      background: white;
      width: 100%;
      box-sizing: border-box;
    }

    &__report {
      position: fixed;
      top: -1000px;
      left: -1000px;
    }

    &__report-mobile {
      display: block;
      color: $copec-dark-blue;
      h3 {
        font-size: 22px;
        line-height: 40px;
      }
      p {
        line-height: 24px;
        color: $copec-dark-blue;
        font-weight: 500;
      }
      &__open-voucher {
        border: none;
        background: none;
        font-size: 14px;
        text-decoration: underline;
        margin-top: 32px;
        margin-bottom: 100px;
        color: $copec-dark-blue;
      }
    }

    &__report-actions {
      margin: 0;
      padding: 8px 16px;
      .icon-btn {
        display: none;
      }
    }

    .cheque-en-linea:not(.transactions) {
      thead,
      tbody {
        th,
        td {
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(1) {
            width: 85% !important;
          }
        }
      }
      thead th:last-child {
        font-size: 0;
      }
    }

    .cheque-en-linea.transactions {
      thead th {
        &:nth-child(2) {
          width: 65% !important;
        }
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:nth-child(2) {
          padding-left: 16px;
        }
      }
      tbody td {
        &:nth-child(2) {
          width: 65% !important;
        }
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      thead,
      tbody {
        th,
        td {
          &.hide-in-mobile{
            display: none;
          }
        }
        th, td {
          &.border-top-left-radius{
            border-top-left-radius: 4px;
          }
          &.border-bottom-left-radius{
            border-bottom-left-radius: 4px;
          }
        }

      }
      tfoot td:nth-child(1) {
        display: flex;
      }
      tbody,
      tfoot {
        td {
          padding-left: 16px !important;
        }
      }
    }

    &__table tbody td.bank-name-cell img {
      max-width: none;
    }
  }
}

.modal {
  &__close-btn {
    position: absolute;
    top: 24px;
    right: 18px;
    background: none;
    border: none;
    cursor: pointer;
  }
  &__img {
    display: block;
    width: 120px;
    margin: 40px auto;
  }
  &__title {
    text-align: center;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 16px;
    color: $copec-dark-blue;
  }
  &__content {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 32px;
    letter-spacing: 0.4px;
    text-align: justify;
    padding: 0 20px;
    font-weight: 500;
    color: $copec-dark-blue;
    line-height: 1.71;
  }
  &__actions {
    text-align: center;
    margin-top: 32px;
    button:first-child {
      margin-bottom: 30px;
    }
    .main-button {
      &.ml {
        margin-left: 20px;
      }
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-break-point) {
  .mobile-tbody-row-separator{
    height: 1px;
    padding: 0 !important;
    .mobile-tbody-row-separator-inner{
      height: 1px;
      margin-left: -5px;
      width: calc(100% - 5px);
      background: $copec-table-background;
    }
  }
}