.formulario-pedidos-container {
  width: 1312px;
  .formulario-pedidos {
    margin-top: 40px;
    display: table;
    width: 1312px;
    padding-left: 2px;
    .filters-container {
      width: 200px;
      display: table-cell;
      vertical-align: top;
      .filter {
        margin-bottom: 24px;
      }

      .planta {
        background-color: $copec-gris-input;
        width: 160px;

        color: $copec-grey;
        font-size: 12px;
        padding: 8px 16px;
        text-transform: capitalize;
        border-radius: 4px;
      }

      .planta-hr {
        width: 192px;
        margin: 23px 0;
      }

      .eds-select {
        width: 192px;
      }
    }

    .camion-info-container {
      display: table-cell;
      vertical-align: top;
      float: right;
      width: 980px;
      &__title {
        font-size: 16px;
        color: $copec-dark-blue;
        margin-top: 30px;
      }
    }

    .comp-container {
      display: table;
      width: calc(100% + 30px);
      margin-left: -16px;
      margin-top: 15px;
      .comp {
        display: table-cell;
        padding: 0 16px;
        &.comp3 {
          width: 33%;
        }
        &.comp4 {
          width: 25%;
        }
        &.comp5 {
          width: 20%;
        }

        .filter-title {
          font-size: 12px;
          font-weight: bold;
          color: $copec-dark-blue;
          margin-bottom: 8px;
        }
      }
    }

    .observaciones {
      &.observaciones-dinamo{
        width: calc(100% - 336px);
        float: left;
      }
      &__title {
        font-size: 12px;
        color: $copec-dark-blue;
        font-weight: bold;
        margin: 24px 0 8px 0;
      }
      textarea {
        width: calc(100% - 22px);
        height: 46px;
        border: 1px solid $copec-grey-3;
        border-radius: 4px;
        padding: 8px;
        color: $copec-dark-blue;
      }
      .observaciones-cont {
        font-size: 12px;
        color: $copec-dark-blue;
        text-align: right;
        margin-right: 2px;
      }
    }

    .pedidos-datepicker {
      border: 1px solid $copec-grey;
      width: 180px;
      &:read-only {
        background: $copec-gris-input;
      }
      &::placeholder {
        color: $copec-dark-blue;
        font-weight: 600;
      }
    }
  }

  .actions-container {
    text-align: center;
    button {
      margin: 32px 16px;
    }
  }

  .error-pedidos {
    width: 100%;
    background-color: $copec-background-error;
    padding: 12px 0;
    font-size: 12px;
    text-align: center;
    color: $copec-red;
    margin-bottom: 15px;
    font-weight: bold;
    border-radius: 8px;
    .icon {
      width: 14px;
      height: 14px;
      background: $copec-red;
      color: white;
      font-size: 10px;
      font-weight: bold;
      border-radius: 7px;
      display: inline-block;
      margin-right: 10px;
      &::after {
        content: "!";
      }
    }
  }
  @media screen and (max-width: $mobile-break-point) {
    width: auto;
    .formulario-pedidos {
      width: auto;
      display: block;
      .filters-container {
        display: block;
        width: 100%;
        box-sizing: border-box;
      }
      .camion-info-container {
        width: 100%;
        float: none;
        display: block;
        .camion {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $mobile-break-point) {
  .formulario-pedidos-mobile-first-form {
    padding: 24px 0;
  }
  .mobile-actions-container {
    width: 100%;
    margin-top: 96px;
    border-top: 1px solid $copec-light-grey;
    padding-top: 32px;
    .main-button {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  .error-pedidos {
    margin-top: 8px;
    text-align: left !important;
    display: flex;
    align-items: flex-start;
    padding-left: 16px !important;
    padding-right: 16px !important;
    box-sizing: border-box;
    .icon {
      text-align: center;
    }
  }
}

.mix-pedidos-actual{
  margin-top: 60px;
  @media screen and (max-width: $mobile-break-point) {
    margin: 10px 0 15px;
  }
  .mix-pedidos-actual-title{
    color: $copec-dark-blue;
    font-size: 12px;
    font-weight: bold;
  }
  .modificar-pedidos-table{
    @media screen and (max-width: $mobile-break-point) {
      width: 100%;
      thead, tbody{
        width: 100%;
      }
    }

    th{ 
      &.productos{
        width: 870px !important;
      }
      &.litros{
        width: 94px !important;
      }
      @media screen and (max-width: $mobile-break-point) {
        &.productos{
          width: 70% !important;
        }
        &.litros{
          width: 30% !important;
        }
      }
    }
    td{
      @media screen and (max-width: $mobile-break-point) {
        &.productos-col{
          width: 70% !important;
        }
        &.litros-col{
          width: 30% !important;
        }
        .modificar-pedidos-table-content{
          overflow: hidden;
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.formulario-pedidos-align-right-container{
  width: 100%;
  text-align: right;
  .formulario-pedidos-align-right{
    width: 976px;
    display: inline-block;
  }
}