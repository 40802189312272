@import "./variables";

.tct-tae-table-container{
	margin: 0 auto;
	width: 864px;
	&.bluemax-principal{
		width: 854px;
		.filters-container{
			margin-top: 20px;
		}
		.actions-container{
			button{
				margin-top: 26px !important;
				cursor: pointer;
			}
		}
	}
	.tct-tae-table-title{
		width: 191px;
		height: 32px;
		margin-top: 24px;
		border-bottom: 1px solid $copec-separator;
		font-size: 18px;
		font-weight: bold;
		color: $copec-dark-blue;
	}
	.filters-container{
		width: 100%;
		.filter-input{
			.search{
				width: 200px !important;
				margin: 12px 0 0 0 !important;
				padding: 8px 20px 7px !important;
				&:placeholder-shown{
					background-size: 26px !important;
					background-position: right 3px center !important;
				}
				&:not(:placeholder-shown){
					background-size: 26px !important;
					background-position: right 3px center !important;
				}
			}
			.eds-select{
				margin-top: 11px;
			}
		}
		.search-input{
			margin-top: 14px;
			margin-left: 20px;
		}

		
		.actions-container{
			float: right;
			img{
				width: 32px;
			}
			button{
				float: right;
				display: inline;
				width: 32px;
				height: 32px;
				margin-top: 12px;
				cursor: pointer;
				&.left-button{
					margin-right: 32px;
				}
			}
		}
	}

	.table-separator{
		margin-top: 14px;
		margin-bottom: 14px;
	}
	
	.alert-container-tct-tae {
		position: relative;
	
		.alert-tooltip {
			position: absolute;
			visibility: hidden;
			top: 34px;
			right: -4px;
			background: white;
			box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
			padding: 8px;
			border-radius: 4px;
			z-index: 100;
			&.confirmacion {
				overflow: visible;
				white-space: nowrap;
				padding-right: 12px;
			}
			.content {
				background-color: #f1f5f8;
				color: $copec-dark-blue;
				font-size: 10px;
				font-weight: 500;
				padding: 5px;
				border-radius: 4px;
			}
		}
	
		&:hover {
			.alert-tooltip {
				visibility: visible;
			}
		}
	}
}


.autorizacion-consumo-tarjetas-form{
	width: 522px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	.filters-container{
		display: flex;
		flex-direction: column;
	.rut-cliente{
		width: 162px;
		height: 28px;
		display: flex;
		justify-content: space-between;
		}
		.rut-cliente-codigo{
				// padding: 8px 24px 8px 16px;
				// width: calc(160px - 32px);
				width: 72%;
				height: 28px;
				border-radius: 4px;
				border: 1px solid #7c97ab;
				font-size: 12px;
				font-weight: 500;
				color: $copec-dark-blue;
		}
	
		/*DESAPARECER FLECHAS DE INPUT DE TIPO NUMBER*/
		/* Chrome, Safari, Edge, Opera */
		.rut-cliente-codigo::-webkit-outer-spin-button,
		.rut-cliente-codigo::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	
		/* Firefox */
		.rut-cliente-codigo[type=number] {
			-moz-appearance: textfield;
		}
		.rut-cliente-verificador{
				// padding: 8px 24px 8px 16px;
				// width: calc(160px - 32px);
				width: 16%;
				height: 28px;
				border-radius: 4px;
				border: 1px solid #7c97ab;
				font-size: 12px;
				font-weight: 500;
				color: $copec-dark-blue;
		}
	
		/*DESAPARECER FLECHAS DE INPUT DE TIPO NUMBER*/
		/* Chrome, Safari, Edge, Opera */
		.rut-cliente-verificador::-webkit-outer-spin-button,
		.rut-cliente-verificador::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	
		/* Firefox */
		.rut-cliente-verificador[type=number] {
			-moz-appearance: textfield;
		}
	}
	.accept-button{
		width: 160px;
	}
}

.autorizacion-consumo-tarjetas-container{
	margin: 0 auto;
	width: 1310px;
	&.bluemax-principal{
		width: 854px;
		.filters-container{
			margin-top: 20px;
		}
		.actions-container{
			button{
				margin-top: 26px !important;
			}
		}
	}
	.tct-tae-table-title{
		width: 191px;
		height: 32px;
		margin-top: 24px;
		border-bottom: 1px solid $copec-separator;
		font-size: 18px;
		font-weight: bold;
		color: $copec-dark-blue;
	}
	.filters-container{
		width: 100%;
		.filter-input{
			.search{
				width: 200px !important;
				margin: 12px 0 0 0 !important;
				padding: 8px 20px 7px !important;
				&:placeholder-shown{
					background-size: 26px !important;
					background-position: right 3px center !important;
				}
				&:not(:placeholder-shown){
					background-size: 26px !important;
					background-position: right 3px center !important;
				}
			}
			.eds-select{
				margin-top: 11px;
			}
		}
		.search-input{
			margin-top: 14px;
			margin-left: 20px;
		}

		
		.actions-container{
			float: right;
			
			img{
				width: 32px;
			}
			button{
				float: right;
				display: inline;
				width: 32px;
				height: 32px;
				margin-top: 12px;
				cursor: pointer;
				position: relative;
				&.left-button{
					margin-right: 32px;
				}
				.alert-tooltip {
					position: absolute;
					visibility: hidden;
					top: 34px;
					right: -4px;
					background: white;
					box-shadow: 0 0 4px 0 rgba(30, 30, 30, 0.5);
					padding: 8px;
					border-radius: 4px;
					z-index: 100;
					&.confirmacion {
						overflow: visible;
						white-space: nowrap;
						padding-right: 12px;
					}
					.content {
						background-color: #f1f5f8;
						color: $copec-dark-blue;
						font-size: 10px;
						font-weight: 500;
						padding: 5px;
						border-radius: 4px;
					}
				}
				&:hover {
					.alert-tooltip {
						visibility: visible;
					}
				}
			}
		}
	}
	.tct-tae-table{
		width: 1310px;
		position: relative;
		margin-top: 12px;
		.th-data{
			padding: 0;
			&.width-65{
				width: 65px;
			}
			&.width-86{
				width: 86px;
			}
			&.width-90{
				width: 90px;
			}
			&.width-105{
				width: 105px;
			}
			&.width-115{
				width: 115px;
			}
			&.width-125{
				width: 125px;
			}
			&.width-169{
				width: 169px;
			}
			&.width-174{
				width: 174px;
			}
			&.width-185{
				width: 185px;
			}
			&.width-260{
				width: 260px;
			}
		}
		.th-text{
			padding: 17px 8px 16px;
			position: relative;
			transform: none;
			&.two-lines{
				padding: 9px 8px;
			}
		}
		tbody{
			.data{
				td{
					width: auto;
				}
				&.table-link{
					text-decoration: underline;
  				cursor: pointer;
				}
				&.row-with-rounded-borders{
					td:first-child {
    				border-top-left-radius: 4px !important;
    				border-bottom-left-radius: 4px !important;
    			}
    			td:last-child {
    				border-top-right-radius: 4px !important;
    				border-bottom-right-radius: 4px !important;
    			}
				}
			}
			.table-separator-row-border-top{
				border: none !important;
				height: 8px;
			}
		}
	}

}

.autorizacion-consumo-tarjetas-solicitud{
	width: 950;
	margin: 0 auto;
	.enabled-input{
		width: 135px;
		border: 1px solid $copec-grey;
		height: 21px;
		padding: 8px 8px 8px 16px;
		border-radius: 4px;
		background-color: white;
		font-size: 12px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: $copec-dark-blue;
	}
	.disabled-input{
		width: 160px;
		border: none;
		height: 32px;
		padding: 8px 8px 8px 16px;
		border-radius: 4px;
		background-color: #F1F5F8;
		font-size: 12px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: $copec-dark-blue;
		// color: $copec-dark-grey;
	}
	.numero-tarjeta-container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.numero-tarjeta{
			.title{
				font-size: 18px;
				color:$copec-dark-blue;
				font-weight: 700;
				margin: 20px 0px 5px;
			}
			.subtitle{
				font-size: 16px;
				color:$copec-grey;
				font-weight: 700;
				margin:5px 0px;
			}
		}
		button{
			width: 160px;
			height: 32px;
			padding: 8px 1px 8px 2px;
			border-radius: 24px;
			border: solid 1px $copec-dark-blue;
			font-family: Quicksand;
			font-size: 12px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: center;
			color: $copec-dark-blue;
			background-color: white;
			cursor: pointer;
		}
	}
	.separator{
		display: flex;
		justify-content: space-between;
		align-items: center;
		p{
			width: 10%;
			font-size: 12px;
			color: $copec-dark-grey;
		}
		.line{
			width: 80%;
			margin: 0px;
			border-bottom: none;
			border-top: 1px solid $copec-light-grey;
			margin-top: 7px;
		}
	}
	.datos-cliente-industrial-container{
		.filters-container{
			display: flex;
			justify-content: space-between;
			.filter{
				width: 20%;
				input{
					width: 100%;
				}
			}
			.filter:first-child{
				width: 50%;
				input{
					width: 100%;
				}
			}
		}
	}
	.datos-venta-container{
		.first-row{
			display: flex;
			justify-content: space-between;
			.filter{
				width: 20%;
				input{
					width: 100%;
				}
				.eds-select{
					width: 100%;
				.css-5bbpx8-control{
					width: 100%;
				}
				}
			}
		}
		.second-row{
			display: flex;
			gap: 15px;
			.filter{
				width: 72px;
				.terminal-input{
					width: 100%;
				}
			}
			.filter:first-child{
				width: 445px;
				input{
					width: 100%;
				}
			}
		}
		.aviso{
			display: flex;
			align-items: flex-start;
			gap: 15px;
			height: 63px;
			padding: 16px 19px;
			background-color: #F1F5F8;
			border-radius: 4px;
			p{
				font-size: 16px;
				margin: 0;
			}
			.importante{
				color: $copec-red;
			}
			.texto{
				color: $copec-dark-blue
			}
		}
		.contacto-area{
			margin-top: 35px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			.contacto{
				display: flex;
				justify-content: space-between;
				.contacto-puesto{
					width: 30%;
					color: $copec-dark-blue;
					font-size: 12px;
					font-weight: bold;
					letter-spacing: 0.34px;
					margin-bottom: 6px;
				}
				.input-area{
					width: 70%;
					display: flex;
					justify-content: space-between;
					input{
						width: 30%;
					}
				}
			}
		}
	}
	.atendedor-container{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.filters-container{
			width: 46%;
			.filter{
				width: 100%;
				.filter-input{
					input{
						width: 578px;
					width: calc(100% - 26px);

					}
				}
			}
		}
		.filters-container:first-child{
			width: 20%;
			.filter-input{
				display: flex;
				gap: 15px;
				input{
					width: 4%;
				}
				input:first-child{
					width: 50%;
				}
			}
		}
	}
	.solicitud-guia-manual-table-container{
		table {
			//width: 300px;
			display: table;
			border-collapse: inherit;
			margin-top: 0px !important;
			border-spacing: 0;
			font-size: 12px;
			margin-bottom: 16px;
			padding-top: 8px;
			&.solicitud-guia-manual-table{
				width: 100%;
			}
			thead {
				width: 100%;
				background: $copec-dark-blue;
				tr {
					background: $copec-dark-blue;
					th {
						font-weight: 800;
						//padding-left: 40px;
						white-space: nowrap;
						border-right: 1px solid $copec-dark-blue;
						padding: 6px 0px 6px 8px;
					}
				}
			}
			th,
			tfoot td {
				border: 0;
				font-weight: bold;
				line-height: 1.6;
				&:first-child {
					//padding: 6px 10px 6px 15px;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				&:last-child {
					//padding: 6px 15px 6px 15px;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
				&[colspan="3"] {
					padding: 5px;
				}
			}
			td {
				background: white;
			}
			tbody {
				td {
					padding-top: 12px;
					padding-bottom: 12px;
					padding-right: 10px;
					border-bottom: 1px solid $copec-light-grey;
					white-space: nowrap;
					&.align-right{
						padding: 12px 0px 12px 8px !important;
						&.tdcontent{
							width: 157px;
							display: flex;
							justify-content: space-between;
						}
					}
					.td-data{
						// background-color: $copec-gris-input;
						padding: 8px 16px;
						border-radius: 4px;
						width: calc(92px - 16px);
            &.green{
              background-color:rgb(7, 209, 136,0.15);
              color:$copec-dark-blue;
            }
					}
					
				}
				tr {
					td:last-child{
						padding: 12px 8px 12px 8px !important;
						&.tdsep{
							padding: 0 15px !important;
						}
					}
					&:first-child {
						td:first-child {
							border-top-left-radius: 4px;
						}
						td:last-child {
							border-top-right-radius: 4px;
						}
					}
					&:last-child {
						td:first-child {
							border-bottom-left-radius: 4px;
						}
						td:last-child {
							border-top-right-radius: 4px;
							border-bottom-right-radius: 4px;
						}
					}
					td:first-child {
						padding-left: 20px;
						//width: 40%;
					}
					&.data {
						&.subtotal {
							td:first-child,
							td:last-child {
								font-weight: bold;
							}
							td {
								font-size: 14px;
							}
						}
					}
				}
			}
			tfoot td {
				font-size: 14px;
				&:last-child {
					text-align: right;
				}
			}
		}
	}
	.button-container{
		display: flex;
		justify-content: center;
		gap: 15px;
		button{
			margin: 0;
			cursor: pointer;
		}
	}
}






.consultar-codigos-autorizacion-container{
	.filters-container{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.filter{
			.filter-input{
				height: 38px;
				.react-datepicker-wrapper{
					border: 1px solid #7c97ab;
					border-radius: 4px;
					input{
						width: 93%;
						padding: 8px 0px 7px 10px;
					}
				}
			}
		}

		.actions-container{
			display: flex;
			width: 150px;
			justify-content: flex-end;
			gap: 25px;
			img{
				width: 32px;
			}
			button{
				float: right;
				display: inline;
				width: 32px;
				height: 32px;
				margin-top: 12px;
				cursor: pointer;
				&.left-button{
					margin-right: 32px;
				}
			}
		}
	}
	.consultar-codigos-autorizacion-table-container{
		width: 1310px;
		margin: 0 auto;
		.tct-tae-table{
			position: relative;
			margin-top: 12px;
			.th-data{
				padding: 0;
				&.width-80{
					width: 80px;
				}
				&.width-90{
					width: 90px;
				}
				&.width-105{
					width: 105px;
				}
				&.width-125{
					width: 125px;
				}
				&.width-150{
					width: 150px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}
}

.trx-container{
	width: 1310px;
	margin: 0 auto;
	.cards-container{
		display: flex;
		justify-content: space-between;
	}
	.filter-area{
		width: 1090px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.buttons-container{
			display: flex;
			align-items: center;
			margin-top: 11px;
			.main-button{
				margin: 0;
			}
			.actions-container{
				display: flex;
				width: 150px;
				justify-content: flex-end;
				align-items: center;
				gap: 25px;
				img{
					width: 32px;
				}
				button{
					float: right;
					display: inline;
					width: 32px;
					height: 32px;
					// margin-top: 12px;
					&.left-button{
						margin-right: 32px;
					}
				}
			}
		}
	}
	.TRX-table-container{
		width: 1090px;
		margin: 0 auto;
		.tct-tae-table{
			position: relative;
			margin-top: 12px;
			.th-data{
				padding: 0;
				&.width-86{
					width: 86px;
				}
				&.width-90{
					width: 90px;
				}
				&.width-105{
					width: 105px;
				}
				&.width-115{
					width: 115px;
				}
				&.width-125{
					width: 125px;
				}
				&.width-169{
					width: 169px;
				}
				&.width-207{
					width: 207px;
				}
				&.width-431{
					width: 431px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}
}

.trx-detalle-container{
	width: 1310px;
	margin: 0 auto;
	.cards-container{
		display: flex;
		.header-data-container-multiple{
			width: 300px;
		}
	}
	.filter-area{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 1090px;
		margin: 0 auto;
		.filters-container{
			.filter{
				.filter-input{
					.search{
						margin: 0;
					}
				}
			} 
		} 
		.main-button{
			margin: 0;
		}
	}
	.trx-detalle-table-container{
		width: 1090px;
		margin: 0 auto;
		.tct-tae-table{
			position: relative;
			margin-top: 12px;
			.th-data{
				padding: 0;
				&.width-86{
					width: 86px;
				}
				&.width-90{
					width: 90px;
				}
				&.width-105{
					width: 105px;
				}
				&.width-115{
					width: 115px;
				}
				&.width-125{
					width: 125px;
				}
				&.width-169{
					width: 169px;
				}
				&.width-207{
					width: 207px;
				}
				&.width-431{
					width: 431px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}
}

.trx-resumen-container{
	.filter-area{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 1090px;
		margin: 0 auto;
		.filters-container{
			.filter{
				.filter-input{
					.search{
						margin: 0;
					}
				}
			} 
		} 
		.main-button{
			margin: 0;
		}
	}
	.trx-resumen-table-container{
		width: 1090px;
		margin: 0 auto;
		.tct-tae-table{
			position: relative;
			margin-top: 12px;
			.th-data{
				padding: 0;
				&.width-86{
					width: 86px;
				}
				&.width-90{
					width: 90px;
				}
				&.width-105{
					width: 105px;
				}
				&.width-115{
					width: 115px;
				}
				&.width-125{
					width: 125px;
				}
				&.width-169{
					width: 169px;
				}
				&.width-170{
					width: 170px;
				}
				&.width-431{
					width: 431px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}
}

.tct-tae-table{
	position: relative;
	margin-top: 12px;
	.th-data{
		padding: 0;
		&.width-86{
			width: 86px;
		}
		&.width-90{
			width: 90px;
		}
		&.width-105{
			width: 105px;
		}
		&.width-115{
			width: 115px;
		}
		&.width-125{
			width: 125px;
		}
		&.width-169{
			width: 169px;
		}
		&.width-174{
			width: 174px;
		}
		&.width-185{
			width: 185px;
		}
	}
	.th-text{
		padding: 17px 8px 16px;
		position: relative;
		transform: none;
		&.two-lines{
			padding: 9px 8px;
		}
	}
	tbody{
		.data{
			td{
				width: auto;
			}
			&.table-link{
				text-decoration: underline;
				cursor: pointer;
			}
			&.row-with-rounded-borders{
				td:first-child {
  				border-top-left-radius: 4px !important;
  				border-bottom-left-radius: 4px !important;
  			}
  			td:last-child {
  				border-top-right-radius: 4px !important;
  				border-bottom-right-radius: 4px !important;
  			}
			}
		}
		.table-separator-row-border-top{
			border: none !important;
			height: 8px;
		}
	}
}

.consultar-autorizar-consumo-container{
	.enabled-input{
		width: 135px;
		border: 1px solid $copec-grey;
		height: 21px;
		padding: 5px 8px 5px 16px;
		border-radius: 4px;
		background-color: white;
		font-size: 12px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: $copec-dark-blue;
	}

	.disabled-input{
		min-height: 0px !important;
		height: 32px !important;
		border: solid 1px var(--gris-copec-20-f-1-f-5-f-8);
		width: 100%;
		border-radius: 4px;
		background: $copec-gris-input;
		box-sizing: border-box;
		padding: 8px 16px;
		color: $copec-grey;
		font-size: 12px;
		line-height: 16px;
	}::placeholder{
		color: $copec-dark-blue;
	}

	.consultar-consumo-container{
		width: 1088px;
		margin: 0 auto;
	
		.filters-container{
			
			&.main-filters-table-container{
				display: flex;
				gap: 32px;
				.rut-cliente{
					width: 192px;
					height: 32px;
					display: flex;
					gap: 16px;
					.rut-cliente-codigo{
							// padding: 8px 24px 8px 16px;
							// width: calc(160px - 32px);
							box-sizing: border-box;
							padding-left: 16px;
							width: 144px;
							border-radius: 4px;
							border: 1px solid #7c97ab;
							font-size: 12px;
							font-weight: 500;
							color: $copec-dark-blue;
					}
				}
				
				/*DESAPARECER FLECHAS DE INPUT DE TIPO NUMBER*/
				/* Chrome, Safari, Edge, Opera */
				.rut-cliente-codigo::-webkit-outer-spin-button,
				.rut-cliente-codigo::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			
				/* Firefox */
				.rut-cliente-codigo[type=number] {
					-moz-appearance: textfield;
				}
				.rut-cliente-verificador{
						// padding: 8px 24px 8px 16px;
						// width: calc(160px - 32px);
						box-sizing: border-box;
						padding-left: 12px;
						width: 32px;
						border-radius: 4px;
						border: 1px solid #7c97ab;
						font-size: 12px;
						font-weight: 500;
						color: $copec-dark-blue;
				}
			
				/*DESAPARECER FLECHAS DE INPUT DE TIPO NUMBER*/
				/* Chrome, Safari, Edge, Opera */
				.rut-cliente-verificador::-webkit-outer-spin-button,
				.rut-cliente-verificador::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			
				/* Firefox */
				.rut-cliente-verificador[type=number] {
					-moz-appearance: textfield;
				}
		
				.nombre-cliente-disabled-input{
					width: 304px;
				}
		
				.eds-selector{
					margin-right: 22px;
					.css-5bbpx8-control{
						width: 192px;
					}
					.css-1edfm88-menu{
						width: 192px;
					}
					.css-wx2pn2-control{
						width: 192px;
					}
				}
		
				.tct-tae-producto-select{
					
					.css-rxyvb9-indicatorContainer{
						padding: 4px;
					}
					.css-6j2rmj-indicatorContainer{
						padding: 4px;
					}
					// dev
					// .css-3uuszk-ValueContainer{
					// 	font-family: Quicksand;
					// 	font-size: 12px;
					// 	font-weight: 500;
					// 	font-stretch: normal;
					// 	font-style: normal;
					// 	line-height: 1.33;
					// 	letter-spacing: normal;
					// }		
					// qa
					// .css-nfjame-control{
					// 	font-family: Quicksand;
					// 	font-size: 12px;
					// 	font-weight: 500;
					// 	font-stretch: normal;
					// 	font-style: normal;
					// 	line-height: 1.33;
					// 	letter-spacing: normal;
					// }
					// .css-1whoa15-control{
					// 	font-family: Quicksand;
					// 	font-size: 12px;
					// 	font-weight: 500;
					// 	font-stretch: normal;
					// 	font-style: normal;
					// 	line-height: 1.33;
					// 	letter-spacing: normal;
					// }
					// .css-16f59za{
					// 	font-family: Quicksand;
					// 	font-size: 12px;
					// 	font-weight: 500;
					// 	font-stretch: normal;
					// 	font-style: normal;
					// 	line-height: 1.33;
					// 	letter-spacing: normal;
					// }
				}

				.tct-tae-producto-select div{
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
				}
		
				.search{
					padding: 8px 0px 9px 26px !important;
					margin: 20px 0px 0 !important;
					width: 172px !important;
					background-size: 29px;
					background-position: right 2px center;
				}
			}
	
		}
	
		.consultar-autorizar-consumo-container-main-table-container{
			width: 100%;
			.consultar-autorizar-consumo-container-main-table{
				width: 100%;
				position: relative;
				margin-top: 12px;
				padding: 4px 6px 8px !important;
				.th-data{
					padding: 1px;
					&.width-172{
						width: 172px;
					}
				}
				.th-text{
					padding: 17px 8px 16px;
					position: relative;
					transform: none;
					&.two-lines{
						padding: 9px 8px;
					}
				}
				tbody{
					.data{
						td{
							width: auto;
						}
						&.table-link{
							text-decoration: underline;
							cursor: pointer;
						}
						&.row-with-rounded-borders{
							td:first-child {
								border-top-left-radius: 4px !important;
								border-bottom-left-radius: 4px !important;
							}
							td:last-child {
								border-top-right-radius: 4px !important;
								border-bottom-right-radius: 4px !important;
							}
						}
					}
					.table-separator-row-border-top{
						border: none !important;
						height: 8px;
					}
				}
			}
		}
	
		.consultar-autorizar-consumo-container-consult-table-container{
			width: 100%;
			.consultar-autorizar-consumo-container-consult-table{
				width: 100%;
				position: relative;
				margin-top: 12px;
				// padding-left: 13px;
				// padding-right: 8px;
				padding: 4px 12px 8px !important;
				.th-data{
					padding: 0;
					&.width-53{
						width: 53px;
					}
					&.width-100{
						width: 100px;
						margin: 0 !important;
					}
					&.width-136{
						width: 136px;
					}
					&.width-327{
						width: 327px;
					}
				}
				.th-text{
					padding: 17px 8px 16px;
					position: relative;
					transform: none;
					&.two-lines{
						padding: 9px 8px;
					}
				}
				tbody{
					.data{
						td{
							width: auto;
							// position: relative;
							// .overflowed{
							// 	// color: green;
							// 	width: 80px;
							// 	white-space: nowrap;
							// 	text-overflow: ellipsis;
							// 	overflow: hidden;
							// 	.extra{
							// 		position: absolute;
							// 		color: green;
							// 		top: 35px;
							// 		background-color: white;
							// 		padding: 8px 15px;
							// 		border: 1px solid black;
							// 		border-radius: 4px;
							// 		z-index: 100;
							// 		visibility: hidden;
							// 	}
								
							// }
							// .overflowed_on{
							// 	&:hover{
							// 		.extra{
							// 			visibility: visible;
							// 		}
							// 	}
							// }
						}
						&.table-link{
							text-decoration: underline;
							cursor: pointer;
						}
						&.row-with-rounded-borders{
							td:first-child {
								border-top-left-radius: 4px !important;
								border-bottom-left-radius: 4px !important;
							}
							td:last-child {
								border-top-right-radius: 4px !important;
								border-bottom-right-radius: 4px !important;
							}
						}
					}
					.table-separator-row-border-top{
						border: none !important;
						height: 8px;
					}
				}
			}
		}
	
	}

	.solicitud-autorizacion-guia-manual-container{
		width: 864px;
		margin: 0 auto;

		.disabled-input{
			border: none;
			width: 192px;
		}::placeholder{
			color: $copec-dark-blue;
		}

		.enabled-input{
			box-sizing: border-box;
			height: 32px;
		}
		
		.solicitud-separator{
			display: flex;
			align-items: center;
			gap: 24px;
			.text {
				width: 91px;
				height: 16px;
				font-family: Quicksand;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: $copec-grey;
			}
			.line {
				height: 2px;
				width: 749px;
				background-color: $copec-dark-grey;
			}
		}


		// qa selector
		.css-nfjame-control{
			font-family: Quicksand;
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
		}
		.css-1whoa15-control{
			font-family: Quicksand;
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
		}

		.title-container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 15.5px;
			.title{
				.main-title{
					width: 373px;
					height: 32px;
					font-family: Quicksand;
					font-size: 18px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: normal;
					color: $copec-dark-blue;
					// color: var(--copec-azul-a-003965);
				}
				.sub-title{
					// width: 166px;
					height: 24px;
					// margin: 32px 207px 0 0;
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					color: #003965;
				}
			}
			.thin{
				margin: 0;
			}
		}

		.responsable-container{
			.filter{
				width: 416px;
				input{
					border: none;
				}::placeholder{
					color: $copec-dark-blue;
				}
				.responsable-gm-input{
					width: 416px;
				}
			}
		}

		.datos-cliente-container{
			.filters-container{
				display: flex;
				gap: 32px;
				.cliente-input{
					width: 416px;
				}
			}
		}

		.datos-venta-container{
			.selector{
				// .css-3uuszk-ValueContainer{
				// 	font-family: Quicksand;
				// 	font-size: 12px;
				// 	font-weight: 500;
				// 	font-stretch: normal;
				// 	font-style: normal;
				// 	line-height: 1.33;
				// 	letter-spacing: normal;
				// }
				// qa
				// .css-nfjame-control{
				// 	font-family: Quicksand;
				// 	font-size: 12px;
				// 	font-weight: 500;
				// 	font-stretch: normal;
				// 	font-style: normal;
				// 	line-height: 1.33;
				// 	letter-spacing: normal;
				// }
				// .css-1whoa15-control{
				// 	font-family: Quicksand;
				// 	font-size: 12px;
				// 	font-weight: 500;
				// 	font-stretch: normal;
				// 	font-style: normal;
				// 	line-height: 1.33;
				// 	letter-spacing: normal;
				// }
			}
			.first-row{
				.filters-container{
					display: flex;
					gap: 32px;
					.estacion-de-servicio-input{
						width: 304px;
					}
					.terminal-input{
						width: 80px;
					}
				}
			}
			.second-row{
				.filters-container{
					display: flex;
					gap: 32px;
					.direccion-input{
						width: 416px;
					}
					.region-select div{
						// .css-5bbpx8-control{
						// 	width: 192px;
						// }
						// .css-1edfm88-menu{
						// 	width: 192px;
						// }
						// .css-wx2pn2-control{
						// 	width: 192px;
						// }
						// qa
						// .css-nfjame-control{
						// 	font-family: Quicksand;
						// 	font-size: 12px;
						// 	font-weight: 500;
						// 	font-stretch: normal;
						// 	font-style: normal;
						// 	line-height: 1.33;
						// 	letter-spacing: normal;
						// }
						// .css-1whoa15-control{
						font-family: Quicksand;
						font-size: 12px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: normal;
						// }
					}
					.comuna-select div{
						// .css-5bbpx8-control{
						// 	width: 192px;
						// }
						// .css-1edfm88-menu{
						// 	width: 192px;
						// }
						// .css-wx2pn2-control{
						// 	width: 192px;
						// }
						// // qa
						// .css-nfjame-control{
						// 	font-family: Quicksand;
						// 	font-size: 12px;
						// 	font-weight: 500;
						// 	font-stretch: normal;
						// 	font-style: normal;
						// 	line-height: 1.33;
						// 	letter-spacing: normal;
						// }
						// .css-1whoa15-control{
						font-family: Quicksand;
						font-size: 12px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: normal;
						// }
					}
				}
			}
			.third-row{
				.filters-container{
					max-width: 864px;
					display: flex;
					gap: 32px;
					flex-wrap: wrap;
				}
				.motivo-select div{
					// .css-5bbpx8-control{
					// 	width: 192px;
					// }
					// .css-1edfm88-menu{
					// 	width: 192px;
					// }
					// .css-wx2pn2-control{
					// 	width: 192px;
					// }
					// // qa
					// .css-nfjame-control{
					// 	font-family: Quicksand;
					// 	font-size: 12px;
					// 	font-weight: 500;
					// 	font-stretch: normal;
					// 	font-style: normal;
					// 	line-height: 1.33;
					// 	letter-spacing: normal;
					// }
					// .css-1whoa15-control{
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
					// }
				}
				.producto-select{
					// .css-5bbpx8-control{
					// 	width: 192px;
					// }
					// .css-1edfm88-menu{
					// 	width: 192px;
					// }
					// .css-wx2pn2-control{
					// 	width: 192px;
					// }
					// // qa
					// .css-nfjame-control{
					// 	font-family: Quicksand;
					// 	font-size: 12px;
					// 	font-weight: 500;
					// 	font-stretch: normal;
					// 	font-style: normal;
					// 	line-height: 1.33;
					// 	letter-spacing: normal;
					// }
					// .css-1whoa15-control{
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
					// }
				}
			}
		}

		.table-prueba-container{
			width: 100%;
			table{
				width: 100%;
				padding: 10px;
				thead{
					width: 100%;
					background-color: #003965;
					tr{
						border-radius: 10px;
						th{
							border: 0;
							width: 150px;
							padding: 15px;
							&:first-child{
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
							}
							&:last-child{
								border-top-right-radius: 5px;
								border-bottom-right-radius: 5px;
							}
						}
					}
				}
				tbody{
					tr{
						.despacho{
							color: #0e69af;
						}
						td{
							padding: 15px;
							.w247{
								width: 247px;
								height: 32px;
								box-sizing: border-box;
							}
							.w112{
								box-sizing: border-box;
								width: 112px;
								height: 32px;
							}
							.monto-error{
								border: solid 1px #ea212e;
							}
							.content{
								&.w105{
									width: 105px;
								}
								&.w200{
									width: 200px;
								}
							}
							.filters-container{
								.filter{
									.table-producto-select{
										.css-5bbpx8-control{
											width: 247px;
										}
										.css-1edfm88-menu{
											width: 247px;
										}
										.css-wx2pn2-control{
											width: 247px;
										}
									}
								}
							}
						}
						&:nth-child(even){
							background: #F1F5F8;
						}
						
					}
				}
			}
		}

		.carta-compromiso-container{
			box-sizing: border-box;
			width: 864px;
			height: 40px;
			border-radius: 8px;
			background-color: $copec-dark-grey;
			display: flex;
			align-items: center;
			padding: 8px 16px;
			margin-top: 10px;
			p{
				color: $copec-dark-blue;

				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				.importante{
					color: $copec-red;
					font-weight: bold;
				}
				.click{
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}

		.important-message{
			margin-top: 8px;
		}

		hr{
			margin-top: 14.5px;
		}

		.actions-buttons-container{
			display: flex;
			justify-content: center;
			gap: 34px;
			margin-top: 31.5px;
			.main-button{
				margin: 0;
			}
		}
	}

	.detalle-tarjeta-container{
		width: 640px;
		margin: 0 auto;
		.detalle-separator{
			display: flex;
			align-items: center;
			gap: 24px;
			.text {
				// width: 94px;
				height: 16px;
				font-family: Quicksand;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: $copec-grey;
			}
			.line {
				height: 2px;
				// width: 522px;
				flex-grow: 1;
				background-color: $copec-dark-grey;
			}
		}

		.title-container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 15.5px;
			margin-bottom: 24px;
			.title{
				.main-title{
					width: 373px;
					height: 32px;
					font-family: Quicksand;
					font-size: 18px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: normal;
					color: $copec-dark-blue;
					// color: var(--copec-azul-a-003965);
				}
				.sub-title{
					width: 166px;
					height: 24px;
					// margin: 32px 207px 0 0;
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					color: #003965;
				}
			}
			.thin{
				margin: 0;
			}
		}

		.datos-tarjeta-container{
			.disabled-input{
				border: none;
				width: 304px;
			}::placeholder{
				color: $copec-dark-blue;
			}
			.filters-container{
				display: flex;
				gap: 32px;
			}
		}
		
		.restricciones-disel-container{
			.disabled-input{
				border: none;
				width: 304px;
			}::placeholder{
				color: $copec-dark-blue;
			}
			.filters-container{
				display: flex;
				gap: 32px;
			}
		}

		hr{
			margin-top: 15.5px;
		}
	}

	.ReactModal__Content.ReactModal__Content--after-open{
		padding: 0px !important;
	}
}

.confirmar-guias-manuales-container{
	.confirmar-guias-manuales-principal-container{
		width: 789px;
		margin: 0 auto;
		// width: 416px;
		// margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 15px;
		.filters-container{
			width: 416px;
			margin: 0 auto;
			.eds-select{
				margin-right: 52px;
				// .css-5bbpx8-control{
				// 	width: 192px;
				// }
				// .css-1edfm88-menu{
				// 	width: 192px;
				// }
				// .css-wx2pn2-control{
				// 	width: 192px;
				// }
				div{
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
				}
			}
			.producto-select{
				// .css-5bbpx8-control{
				// 	width: 100px;
				// }
				// .css-1edfm88-menu{
				// 	width: 100px;
				// }
				// .css-wx2pn2-control{
				// 	width: 100px;
				// }
				div{
					font-family: Quicksand;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
				}
			}
		}
		.tct-tae-table{
			position: relative;
			margin-top: 12px;
			width: 416px;
			margin: 0 auto;
			.th-data{
				padding: 0;
				&.width-100{
					width: 100px;
				}
				&.width-292{
					width: 292px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}
	
	.confirmar-guias-manuales-detalle{
		width: 864px;
		margin: 0 auto;

		.separator{
			display: flex;
			align-items: center;
			gap: 24px;
			&.separator-error{
				margin-bottom: 15px;
			}
			.text {
				// width: 94px;
				height: 16px;
				font-family: Quicksand;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: $copec-grey;
			}
			.line {
				height: 2px;
				// width: 522px;
				flex-grow: 1;
				background-color: $copec-dark-grey;
			}
		}
	
		.enabled-input{
			border: 1px solid $copec-grey;
			height: 21px;
			padding: 8px 8px 8px 16px;
			border-radius: 4px;
			background-color: white;
			font-size: 12px;
			font-weight: 700;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $copec-dark-blue;
		}
	
		.disabled-input{
			box-sizing: border-box;
			border: none;
			min-height: 0px;
			height: 32px !important;
			padding: 8px 8px 8px 16px;
			// padding-left: 16px !important;
			// padding: 0px !important;
			border-radius: 4px;
			background-color: #F1F5F8;
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: #003965;
		}::placeholder{
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: #003965;
		}
	
		.numero-guia-container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.numero-guia{
				.title{
					font-size: 18px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: normal;
					color:$copec-dark-blue;
				}
				.subtitle{
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					color: #003965;
				}
			}
			.thin{
				margin: 0px;
			}
			// button{
			// 	width: 160px;
			// 	height: 32px;
			// 	padding: 8px 1px 8px 2px;
			// 	border-radius: 24px;
			// 	border: solid 1px $copec-dark-blue;
			// 	font-family: Quicksand;
			// 	font-size: 12px;
			// 	font-weight: bold;
			// 	font-stretch: normal;
			// 	font-style: normal;
			// 	line-height: 1.33;
			// 	letter-spacing: normal;
			// 	text-align: center;
			// 	color: $copec-dark-blue;
			// 	background-color: white;
			// 	cursor: pointer;
			// }
		}
	
		.estacion-de-servicio{
			.filters-container{
				width: 100%;
				.filter{
					width: 100%;
					input{
						width: 100%;
					}
				}
			}
		}
	
		.datos-cliente-industrial{
			.filters-container{
				&.first-row{
					display: flex;
					gap: 31px;
					.filter{
						.nombre-input{
							width: 305px;
						}
						.direccion-input{
							width: 304px;
						}
						.comuna-input{
							width: 192px;
						}
					}
				}
				&.second-row{
					display: flex;
					gap: 30px;
					.filter{
						.rut-input{
							width: 305px;
						}
						.giro-input{
							width: 305px;
						}
						.tarjeta-input{
							width: 192px;
						}
					}
				}
			}
		}
	
		.datos-venta{
			.filters-container{
				&.first-row{
					display: flex;
					gap: 32px;
					// justify-content: space-between;
					.filter{
						.filter-input{
							.numero-guia-input{
								width: 192px;
							}
							height: 32px;
							.react-datepicker-wrapper{
								width: 192px !important;
								border: 1px solid #7c97ab;
								border-radius: 4px;
								input{
									padding: 8px 0px 8px 16px !important;
								}
							}
							&.time-input{
								box-sizing: border-box;
								width: 192px !important;
							}
							.time-picker-library-container{
								width: 192px;
								height: 23px;
								.time-container{
									margin: 0;
									width: 181px;
									height: 100%;
									display: flex;
									align-items: center;
									justify-content: flex-start;
									padding-left: 10px;
								}
								.time-picker{
									width: 185px;
									display: flex;
									.react-time-picker{
										width: 185px;
									}
								}
							}
							.orden-input{
								width: 192px;
							}
							.codigo-input{
								width: 192px;
							}
						}
						
						
					}
				}
				&.second-row{
					display: flex;
					gap: 32px;
					.filter{
						// .codigo-input{
						// 	width: 192px;
						// }
						.responsable-input{
							width: 192px;
						}
						.tipo-de-entrega-selector div{
							font-family: Quicksand;
							font-size: 12px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.33;
							letter-spacing: normal;
						}
					}
				}
			}
		}

		.table-prueba-container{
			width: 100%;
			margin-bottom: 15px;
			table{
				width: 100%;
				padding: 10px;
				thead{
					width: 100%;
					background-color: #003965;
					tr{
						border-radius: 10px;
						th{
							border: 0;
							width: 150px;
							padding: 15px;
							&:first-child{
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
							}
							&:last-child{
								border-top-right-radius: 5px;
								border-bottom-right-radius: 5px;
							}
						}
					}
				}
				tbody{
					tr{
						.despacho{
							color: #0e69af;
						}
						td{
							padding: 15px;
							.w247{
								width: 247px;
								height: 32px;
								box-sizing: border-box;
							}
							.w112{
								box-sizing: border-box;
								width: 112px;
								height: 32px;
							}
							.content{
								&.w105{
									width: 105px;
								}
								&.w200{
									width: 200px;
								}
							}
							.monto-error{
								border: solid 1px #ea212e;
							}
							.filters-container{
								.filter{
									.table-producto-select{
										.css-5bbpx8-control{
											width: 247px;
										}
										.css-1edfm88-menu{
											width: 247px;
										}
										.css-wx2pn2-control{
											width: 247px;
										}
									}
								}
							}
						}
						&:nth-child(even){
							background: #F1F5F8;
						}
						
					}
				}
			}
		}

		.important-message{
			margin-bottom: 15.5px;
		}

		.button-container{
			margin-top: 32.5px;
			display: flex;
			justify-content: center;
			gap: 15px;
			button{
				margin: 0;
			}
		}
	}
}

.consultar-guias-manuales-container{
	width: 1312px;
	margin: 0 auto;
	.consultar-guias-manuales-por-fecha{
		width: 100%;
		.total-filters-container{
			.filter-input{
				.react-datepicker-wrapper{
					width: 192px !important;
					border: 1px solid #7c97ab;
					border-radius: 4px;
					input{
						padding: 8px 0px 8px 16px !important;
					}
				}
			}
			.first-row{
				display: flex;
				justify-content: space-between;
				align-items: center;
				.filter{
					max-width: 192px !important;
				}
				.filters-container{
					display: flex;
					gap: 32px;
					.eds-select-container{
						margin-right: 22px;
					}
					.thin{
						width: 192px;
						margin: 0;
						margin-top: 20px;
					}
					.tct-tae-producto-select div{
						font-family: Quicksand;
						font-size: 12px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: normal;
					}
					.estado-select div{
						font-family: Quicksand;
						font-size: 12px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: normal;
					}
					.search{
						padding: 8px 1px 9px 26px !important;
						// margin: 20px 0px 0 !important;
						margin: 0 !important;
						margin-top: 19px !important;
						background-size: 29px;
						background-position: right 2px center;
						width: 192px !important;
						// &.large{
						// 	width: 352px !important;
						// }
						// &.small{
						// 	width: 172px !important;
	
						// }
					}
				}
				.filter-button-container{
					width: 32px;
					height: 32px;
					border: solid 1px #003965;
					border-radius: 17px;
					margin-right: 2px;
					margin-top: 15px;
					cursor: pointer;
					&.active{
						box-shadow: 0 0 2px 1px #0e69af;
					}
					.filter-button{
						width: 32px;
						height: 32px;
					}
				}
			}
			.second-row{
				.filters-container{
					display: flex;
					gap: 32px;
					.search{
						padding: 8px 1px 9px 26px !important;
						// margin: 20px 0px 0 !important;
						margin: 0 !important;
						background-size: 29px;
						background-position: right 2px center;
						width: 416px !important;
						// &.large{
						// 	width: 352px !important;
						// }
						// &.small{
						// 	width: 172px !important;
	
						// }
					}
				}
			}
		}
		.consultar-guias-manuales-por-fecha-table-container{
			.tct-tae-table{
				position: relative;
				margin-top: 12px;
				.th-data{
					padding: 0;
					&.width-58{
						width: 58px;
					}
					&.width-100{
						width: 100px;
					}
					&.width-136{
						width: 136px;
					}
					&.width-222{
						width: 222px;
					}
				}
				// ESTILOS
				// margin: 0 24px;
				// padding: 7px 8px 7px 7px;
				// border-radius: 4px;
				// background-color: #ffeab4;
				// box-sizing: border-box;
				.th-text{
					padding: 17px 8px 16px;
					position: relative;
					transform: none;
					&.two-lines{
						padding: 9px 8px;
					}
				}
				tbody{
					.data{
						td{
							width: auto;
							.estado-elipsis{
								width: 87px !important;
								padding: 2px 3px 2px 3px;
								color: white;
								text-align: center;
								font-size: 12px;
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2;
								letter-spacing: normal;
								background: $copec-azul-2;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								border-radius: 4px;
								&.Autorizada{
									background-color: #ffeab4;
									color: red !important;
								}
								&.Confirmada{
									background-color: #87DCD1;
									color: #139F7F !important;
								}
								&.Anulada{
									background-color: #fac7cb;
									color: #ea212e !important;
								}
							}
							&.pd-left-7{
								padding-left: 7px;
							}
						}
						&.table-link{
							text-decoration: underline;
							cursor: pointer;
						}
						&.row-with-rounded-borders{
							td:first-child {
								border-top-left-radius: 4px !important;
								border-bottom-left-radius: 4px !important;
							}
							td:last-child {
								border-top-right-radius: 4px !important;
								border-bottom-right-radius: 4px !important;
							}
						}
					}
					.table-separator-row-border-top{
						border: none !important;
						height: 8px;
					}
				}
			}
		}
	}

	.consultar-guias-manuales-por-guia{
		width: 1312px;
		margin: 0 auto;
		.enabled-input{
			width: 135px;
			border: 1px solid $copec-grey;
			height: 14px;
			padding: 8px 8px 8px 16px;
			border-radius: 4px;
			background-color: white;
			font-size: 12px;
			font-weight: 700;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $copec-dark-blue;
		}
		.disabled-input{
			width: 160px;
			border: none;
			height: 14px;
			padding: 8px 8px 8px 16px;
			border-radius: 4px;
			background-color: #F1F5F8;
			font-size: 12px;
			font-weight: 700;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $copec-dark-blue;
			// color: $copec-dark-grey;
		}
		.filter-input{
			.css-5bbpx8-control{
				width: 192px;
			}
			.css-1edfm88-menu{
				width: 192px;
			}
			.css-wx2pn2-control{
				width: 192px;
			}
			.css-3uuszk-ValueContainer{
				font-family: Quicksand;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
			}
			// qa
			.css-nfjame-control{
				font-family: Quicksand;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
			}
			.css-1whoa15-control{
				font-family: Quicksand;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
			}
		}
		.filters-container{
			display: flex;
			gap: 32px;
			.eds-select{
				margin-right: 22px;
			}
			.nro-guia-input{
				box-sizing: border-box;
				width: 192px;
				height: 32px;
			}
			.nro-guia-input::-webkit-inner-spin-button,
			.nro-guia-input::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			.nro-guia-input {
				-moz-appearance: textfield;
			}
		}
		.consultar-guias-manuales-por-numero-guia-table-container{
			.tct-tae-table{
				position: relative;
				margin-top: 12px;
				.th-data{
					padding: 0;
					&.width-58{
						width: 58px;
					}
					&.width-100{
						width: 100px;
					}
					&.width-136{
						width: 136px;
					}
					&.width-222{
						width: 222px;
					}
				}
				// ESTILOS
				// margin: 0 24px;
				// padding: 7px 8px 7px 7px;
				// border-radius: 4px;
				// background-color: #ffeab4;
				// box-sizing: border-box;
				.th-text{
					padding: 17px 8px 16px;
					position: relative;
					transform: none;
					&.two-lines{
						padding: 9px 8px;
					}
				}
				tbody{
					.data{
						td{
							width: auto;
							.estado-elipsis{
								width: 87px !important;
								padding: 2px 3px 2px 3px;
								color: white;
								text-align: center;
								font-size: 12px;
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2;
								letter-spacing: normal;
								background: $copec-azul-2;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								border-radius: 4px;
								&.Autorizada{
									background-color: #ffeab4;
									color: red !important;
								}
								&.Confirmada{
									background-color: #87DCD1;
									color: #139F7F !important;
								}
								&.Anulada{
									background-color: #fac7cb;
									color: #ea212e !important;
								}
							}
							&.pd-left-7{
								padding-left: 7px;
							}
						}
						&.table-link{
							text-decoration: underline;
							cursor: pointer;
						}
						&.row-with-rounded-borders{
							td:first-child {
								border-top-left-radius: 4px !important;
								border-bottom-left-radius: 4px !important;
							}
							td:last-child {
								border-top-right-radius: 4px !important;
								border-bottom-right-radius: 4px !important;
							}
						}
					}
					.table-separator-row-border-top{
						border: none !important;
						height: 8px;
					}
				}
			}
		}
	}
	
	.consultar-guias-manuales-info-container{
		width: 864px;
		margin: 0 auto;
	
		.enabled-input{
			border: 1px solid $copec-grey;
			height: 21px;
			padding: 8px 8px 8px 16px;
			border-radius: 4px;
			background-color: white;
			font-size: 12px;
			font-weight: 700;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $copec-dark-blue;
		}
	
		.disabled-input{
			border: none;
			height: 32px;
			padding: 8px 8px 8px 16px;
			border-radius: 4px;
			background-color: #F1F5F8;
			font-size: 12px;
			font-weight: 700;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $copec-dark-blue;
			// color: $copec-dark-grey;
		}

		.separator{
			display: flex;
			align-items: center;
			gap: 24px;
			.text {
				// width: 94px;
				height: 16px;
				font-family: Quicksand;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: $copec-grey;
			}
			.line {
				height: 2px;
				// width: 522px;
				flex-grow: 1;
				background-color: $copec-dark-grey;
			}
		}
	
		.numero-guia-container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.numero-guia{
				.title{
					font-size: 18px;
					color:$copec-dark-blue;
					font-weight: 700;
					font-size: 18px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: normal;
					// margin: 20px 0px 5px;
				}
				.subtitle{
					margin: 0;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					color: #003965;
				}
			}
			button{
				width: 160px;
				height: 32px;
				padding: 8px 1px 8px 2px;
				border-radius: 24px;
				border: solid 1px $copec-dark-blue;
				font-family: Quicksand;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				text-align: center;
				color: $copec-dark-blue;
				background-color: white;
				cursor: pointer;
			}
		}
		
		.estacion-de-servicio{
			.filters-container{
				width: 100%;
				.filter{
					width: 100%;
					input{
						width: 100%;
					}
				}
			}
		}
	
		
	
		.datos-cliente-industrial{
			.filters-container{
				&.first-row{
					display: flex;
					gap: 32px;
					.filter{
						.nombre-input{
							width: 304px;
						}
						.direccion-input{
							width: 304px;
						}
						.comuna-input{
							width: 192px;
						}
					}
				}
				&.second-row{
					display: flex;
					justify-content: space-between;
					.filter{
						.rut-input{
							width: 304px;
						}
						.giro-comercial-input{
							width: 304px;
						}
						.tarjeta-input{
							width: 192px;
						}
					}
				}
			}
		}
	
		.datos-venta{
			.filters-container{
				&.first-row{
					display: flex;
					// justify-content: space-between;
					gap: 32px;
					.filter{
						.nro-guia-input{
							width: 192px;
						}
						.react-datepicker-wrapper{
							width: 192px !important;
							border: 1px solid #7c97ab;
							border-radius: 4px;
							input{
								padding: 12px 0px 8px 16px !important;
							}
						}
						.disabled-date{
							.react-datepicker-wrapper{
								border: none;
								.react-datepicker__input-container{
									height: 38px;
									border: none;
									background-color: #F1F5F8;
								}
							}

						}
						.time-input{
							box-sizing: border-box;
							width: 192px !important;
							.time-picker-library-container{
								width: 192px;
								height: 23px;
								.time-container{
									margin: 0;
									width: 95%;
									height: 100%;
									display: flex;
									align-items: center;
									justify-content: flex-start;
									padding-left: 10px;
									height: 29px;
								}
								.time-picker{
									width: 175px;
									display: flex;
									.react-time-picker{
										width: 174px;
									}
								}
							}
							&.disabled-time{
								.time-picker-library-container{
									.time-container{
										border: none;
										background-color: #F1F5F8;
									}
								}
							}
						}
						.codigo-autorizacion-input{
							width: 192px;
						}
					}
				}
				&.second-row{
					display: flex;
					gap: 32px;
					.filter{
						.codigo-autorizacion-input{
							width: 192px;
						}
						.responsable-gm-input{
							width: 416px;
						}
					}
				}
			}
		}

		.table-prueba-container{
			width: 100%;
			margin-bottom: 15px;
			table{
				width: 100%;
				padding: 10px;
				thead{
					width: 100%;
					background-color: #003965;
					tr{
						border-radius: 10px;
						th{
							border: 0;
							width: 150px;
							padding: 15px;
							&:first-child{
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
							}
							&:last-child{
								border-top-right-radius: 5px;
								border-bottom-right-radius: 5px;
							}
						}
					}
				}
				tbody{
					tr{
						.despacho{
							color: #0e69af;
						}
						td{
							padding: 15px;
							.w247{
								width: 247px;
								height: 32px;
								box-sizing: border-box;
							}
							.w112{
								box-sizing: border-box;
								width: 112px;
								height: 32px;
							}
							.content{
								&.w105{
									width: 105px;
								}
								&.w200{
									width: 200px;
								}
							}
							.filters-container{
								.filter{
									.table-producto-select{
										.css-5bbpx8-control{
											width: 247px;
										}
										.css-1edfm88-menu{
											width: 247px;
										}
										.css-wx2pn2-control{
											width: 247px;
										}
									}
								}
							}
						}
						&:nth-child(even){
							background: #F1F5F8;
						}
						
					}
				}
			}
		}

		.button-area{
			display: flex;
			gap: 32px;
			justify-content: center;
			.thin{
				margin: 0 !important;
			}
		}
		// .consultar-guia-manual-info-table-container{
		// 	table {
		// 		//width: 300px;
		// 		display: table;
		// 		border-collapse: inherit;
		// 		margin-top: 0px !important;
		// 		border-spacing: 0;
		// 		font-size: 12px;
		// 		margin-bottom: 16px;
		// 		padding-top: 8px;
		// 		&.solicitud-guia-manual-table{
		// 			width: 100%;
		// 		}
		// 		thead {
		// 			width: 100%;
		// 			background: $copec-dark-blue;
		// 			tr {
		// 				background: $copec-dark-blue;
		// 				th {
		// 					font-weight: 800;
		// 					//padding-left: 40px;
		// 					white-space: nowrap;
		// 					border-right: 1px solid $copec-dark-blue;
		// 					padding: 6px 0px 6px 8px;
		// 				}
		// 			}
		// 		}
		// 		th,
		// 		tfoot td {
		// 			border: 0;
		// 			font-weight: bold;
		// 			line-height: 1.6;
		// 			&:first-child {
		// 				//padding: 6px 10px 6px 15px;
		// 				border-top-left-radius: 4px;
		// 				border-bottom-left-radius: 4px;
		// 			}
		// 			&:last-child {
		// 				//padding: 6px 15px 6px 15px;
		// 				border-top-right-radius: 4px;
		// 				border-bottom-right-radius: 4px;
		// 			}
		// 			&[colspan="3"] {
		// 				padding: 5px;
		// 			}
		// 		}
		// 		td {
		// 			background: white;
		// 		}
		// 		tbody {
		// 			td {
		// 				padding-top: 12px;
		// 				padding-bottom: 12px;
		// 				padding-right: 10px;
		// 				border-bottom: 1px solid $copec-light-grey;
		// 				white-space: nowrap;
		// 				&.align-right{
		// 					padding: 12px 0px 12px 8px !important;
		// 					&.tdcontent{
		// 						width: 157px;
		// 						display: flex;
		// 						justify-content: space-between;
		// 					}
		// 				}
		// 				.td-data{
		// 					// background-color: $copec-gris-input;
		// 					padding: 8px 16px;
		// 					border-radius: 4px;
		// 					width: calc(92px - 16px);
		// 					&.green{
		// 						background-color:rgb(7, 209, 136,0.15);
		// 						color:$copec-dark-blue;
		// 					}
		// 				}
						
		// 			}
		// 			tr {
		// 				td:last-child{
		// 					padding: 12px 8px 12px 8px !important;
		// 					&.tdsep{
		// 						padding: 0 15px !important;
		// 					}
		// 				}
		// 				&:first-child {
		// 					td:first-child {
		// 						border-top-left-radius: 4px;
		// 					}
		// 					td:last-child {
		// 						border-top-right-radius: 4px;
		// 					}
		// 				}
		// 				&:last-child {
		// 					td:first-child {
		// 						border-bottom-left-radius: 4px;
		// 					}
		// 					td:last-child {
		// 						border-top-right-radius: 4px;
		// 						border-bottom-right-radius: 4px;
		// 					}
		// 				}
		// 				td:first-child {
		// 					padding-left: 20px;
		// 					//width: 40%;
		// 				}
		// 				&.data {
		// 					&.subtotal {
		// 						td:first-child,
		// 						td:last-child {
		// 							font-weight: bold;
		// 						}
		// 						td {
		// 							font-size: 14px;
		// 						}
		// 					}
		// 				}
		// 			}
		// 			&:last-child{
		// 				td{
		// 					.td-data{
		// 						padding: 0px 16px;
		// 					}
		// 				}
		// 			}
		// 		}
		// 		tfoot td {
		// 			font-size: 14px;
		// 			&:last-child {
		// 				text-align: right;
		// 			}
		// 		}
		// 	}
		// }
	}
}

.transacciones-producto-container{
	.enabled-input{
		border: 1px solid $copec-grey;
		height: 21px;
		padding: 8px 8px 8px 16px;
		border-radius: 4px;
		background-color: white;
		font-size: 12px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: $copec-dark-blue;
	}

	.disabled-input{
		box-sizing: border-box;
		border: none;
		min-height: 0px;
		height: 32px !important;
		padding: 8px 8px 8px 16px;
		// padding-left: 16px !important;
		// padding: 0px !important;
		border-radius: 4px;
		background-color: #F1F5F8;
		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #003965;
	}::placeholder{
		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #003965;
	}

	.css-3uuszk-ValueContainer{
		font-family: Quicksand;
		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
	}
	// qa
	.css-nfjame-control{
		font-family: Quicksand;
		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
	}
	.css-1whoa15-control{
		font-family: Quicksand;
		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
	}

	.cards-container{
		box-sizing: border-box;
		width: 1310px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		// gap: 32px;
		// padding: 0 1px;
	}

	.main-filters-container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		&.w-1088{
			width: 1088px;
			margin: 0 auto;
		}
		&.w-1312{
			width: 1312px;
			margin: 0 auto;
		}
		.stock-asignado-input{
			width: 171px;
		}
		.total-tct-input{
			width: 171px;
		}
		.filters-container{
			display: flex;
			gap: 32px;
		}
		.actions-button-container{
			margin-top: 22px;
			display: flex;
			gap: 32px;
			img{
				width: 32px;
				height: 32px;
			}
			.alert-tooltip{
				top: 34px;
				right: 2px;
				width: 120px;
				text-align: center;
				color: $copec-dark-blue;
			}
			.alert-container-cierre-diario{
				cursor: pointer;
			}
		}
	}

	.diesel-table-container{
		width: 1312px;
		margin: 0 auto;
		.diesel-table{
			width: 100%;
			position: relative;
			margin-top: 12px;
			padding: 4px 6px 8px !important;
			.th-data{
				padding: 1px;
				&.width-253{
					width: 253px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}

	.lubricante-bluemax-table-container{
		width: 1088px;
		margin: 0 auto;
		.lubricante-bluemax-table{
			width: 100%;
			position: relative;
			margin-top: 12px;
			padding: 4px 6px 8px !important;
			.th-data{
				padding: 1px;
				&.width-110{
					width: 110px;
				}
				&.width-364{
					width: 364px;
				}
			}
			.th-text{
				padding: 17px 8px 16px;
				position: relative;
				transform: none;
				&.two-lines{
					padding: 9px 8px;
				}
			}
			tbody{
				.data{
					td{
						width: auto;
					}
					&.table-link{
						text-decoration: underline;
						cursor: pointer;
					}
					&.row-with-rounded-borders{
						td:first-child {
							border-top-left-radius: 4px !important;
							border-bottom-left-radius: 4px !important;
						}
						td:last-child {
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				.table-separator-row-border-top{
					border: none !important;
					height: 8px;
				}
			}
		}
	}
}