.timepicker {
  width: 100%;
  position: relative;
  color: $copec-dark-blue;
  min-width: 130px;
  &__input {
    border-radius: 4px;
    background: #fff;
    border: 1px solid $copec-grey;
    min-height: 32px;
    box-sizing: border-box;
    padding: 8px 16px;
    color: $copec-dark-blue;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    display: block;
    width: 100%;
    cursor: pointer;
    caret-color: transparent;
    &:disabled {
      border-color: transparent;
      background: $copec-gris-input;
      cursor: default;
      & ~ .timepicker__carrot {
        display: none;
        cursor: default;
      }
    }
    &::selection {
      background: transparent;
    }
    &::placeholder {
      color: $copec-grey;
    }
  }
  &.cierre-diario-timepicker{
    width: 120px;
    min-width: 120px;
    &__input {
      font-weight: bold;
      &::placeholder {
        color: $copec-dark-blue;
      }
    }
    .timepicker__input{
      font-weight: 500;
      &::placeholder{
        color: $copec-dark-blue;
        font-weight: 500;
      }
    }
    .timepicker__carrot{
      right: 5px;
    }

    .timepicker__selector__toggles{
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .timepicker__selector{
      padding: 16px 16px 20px;
    }
  }
  &__carrot {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    pointer-events: none;
  }
  &__selector {
    padding: 16px 16px 32px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(74, 143, 195, 0.6);
    border: solid 1px #4a8fc3;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    box-sizing: border-box;
    z-index: 1000;
    &.top-picker {
      top: auto;
      bottom: 100%;
    }
    &__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.15;
      width: 100%;
      text-align: center;
      width: 100%;
      margin-top: 0;
      margin-bottom: 8px;
    }
    &__toggles {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 32px;
    }
    &__toggle {
      text-align: center;

      &-value {
        font-size: 18px;
        line-height: 1.78;
        font-weight: 700;
        width: 56px;
        height: 32px;
        text-align: center;
        margin: 0;
      }
    }
    &__separator {
      font-size: 18px;
      line-height: 1.78;
      font-weight: 700;
      width: 16px;
      height: 32px;
      text-align: center;
      margin: 0;
    }
    &__btn {
      padding: 8px;
      background: white;
      border: none;
      cursor: pointer;
      img {
        width: 12px;
        display: block;
      }
      &.increase-btn {
        img {
          transform: rotateZ(180deg);
          transform-origin: center;
        }
      }
    }
    .main-button {
      font-size: 12px;
      line-height: 1.33;
      padding-top: 8px;
      padding-bottom: 8px;
      height: auto;
    }
    & + .timepicker__carrot {
      transform: translateY(-50%) rotateZ(180deg);
      transform-origin: center center;
    }
  }
}

tr:last-child{
  .display-on-top-picker {
    .timepicker__selector{
      top: -228px;
    }
  }
}

.gas-timepicker{
  min-width: 0;
  width: 70px;
  input{
    padding: 5px;
  }
}
