@import "./variables";

.pedidos-activos {
  .icons {
    display: flex;
    flex-direction: row;
    width: 752px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 130px;
    &.dinamo{
      width: 650px;
      margin: 0 auto 0 0;
    }
    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .badge {
        width: 20px;
        height: 20px;
        position: absolute;
        top: -10px;
        left: 40px;
        border-radius: 50%;
        background-color: #ea212e;
        //padding: 1px 6px;
        font-size: 12px;
        color: white;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1px;
      }
      .description {
        position: absolute;
        left: 50%;
        top: 90%;
        transform: translate(-50%, 12px);
        font-size: 14px;
        font-weight: bold;
        color: $copec-dark-blue;
        &.mobile-description {
          display: none;
        }
      }
    }
    .line {
      flex-grow: 1;
      height: 2px;
      background-color: $copec-dark-blue;
    }
  }

  .pedidosa-options {
    margin-top: 10px;
    max-width: 1147px;
    .filters-container {
      margin-bottom: 10px;
    }

    .actions {
      margin-top: 45px;

      .actions-buttons {
        width: 138px;
        height: 32px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        &.hallow {
          background-color: white;
          cursor: not-allowed;
        }
      }
    }

    .actions .actions-buttons:last-child{
      margin-right: 0px;
    }

    &.dinamo{
      max-width: 1307px;
    }
    .important-message{
      margin: 8px 0px;
    }
  }

  .pedidos-activos-table {
    width: 1147px;
    //width: 1317px;
    .restante {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .th-data {
      width: calc(128px - 16px);
      padding: 0 8px;
      position: relative;
      height: 46px;
      &.dinamo{
        width: calc(250px - 16px) !important;
      }
      &.th-select {
        width: 42px;
      }
      &.bigger {
        width: calc(150px - 16px);
      }
    }

    &.dinamo {
      width: 1307px;
      .th-data {
        width: calc(116px - 16px);
        &.th-select {
          width: 42px;
        }
      }
    }

    td {
      &.estado {
        .sap {
          font-size: 10px;
        }
      }
    }

    .document {
      cursor: pointer;
      position: relative;
      .documento-info {
        position: absolute;
        left: -100px;
        visibility: visible;
        background: white;
        border-radius: 4px;
        color: $copec-dark-blue;
        padding: 10px 11px;
        box-shadow: 0 0 6px 1px rgba(188, 188, 188, 0.5);
        z-index: 10;

        &.recalculo {
          left: 100px;
          top: -70px;
        }
        .title {
          font-weight: bold;
          font-size: 10px;
          text-align: center;
          padding: 5px 0;
        }
        /* .content {
          //max-height: 210px;
          //overflow-y: auto;
          //overflow-x: hidden;
          table {
            .headers {
              th {
                color: $copec-dark-blue;
                padding: 0 0 0 15px;
              }
            }
            .data {
              td {
                padding: 5px;
                white-space: nowrap;
              }
            }
            tr {
              &.separator-table-row {
                height: 2px !important;
              }
            }
          }
        } */
        .footer {
          .important {
            color: #ed1d24;
          }
        }
      }
    }
  }

  .pedidos-activos-table-container {
    min-height: 400px;
    padding-bottom: 250px;
    &.avisos,&.ot{
      min-height: 40px;
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: $mobile-break-point) {
    margin-left: 0;
    .icons {
      width: auto;
      height: 96px;
      & + hr {
        display: none;
      }
      .icon {
        .description {
          &.mobile-description {
            font-size: 10px;
            display: block;
            white-space: nowrap;
          }
          &:not(.mobile-description) {
            display: none;
          }
        }
      }
    }
    .pedidosa-options {
      display: none;
    }

    &-mobile-empty-state {
      padding: 16px 71px 16px 16px;
      border-radius: 8px;
      background: $sidebar-grey;
      margin: 0;
      font-weight: 500;
      color: $copec-dark-blue;
      font-size: 14px;
      line-height: 24px;
    }
    .mobile-table__estado-dynamo{
      width: 100% !important;
      text-align: right;
      font-size: 10px;
      color: $copec-dark-blue;
      margin-bottom: 5px;
    }
    .mobile-table__item {
      max-height: 80px;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
      &.has-estado-dynamo{
        max-height: 110px;
      }
      &.open {
        max-height: 290px;
        &.has-estado-dynamo{
          max-height: 320px;
        }
      }
      &-name-container {
        flex-wrap: wrap;
        padding-right: 4px;
        padding-left: 12px;
        max-width: 77%;
        .mobile-table__item-content-list-item {
          padding-left: 0;
        }
        & > * {
          width: 47%;
        }
      }
      &-name-full-width {
        width: 100%;
        //display: flex;
        justify-content: space-between;
        .mobile-table__item-name:not(:first-child) {
          padding-left: 4px;
        }
        p {
          display: inline-block;
          max-width: 49%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          min-width: 15%;
        }
      }
      &-bottom-section {
        margin-top: 8px;
        border-top: 1px solid #9db1c0;
        padding-top: 8px;
        padding-left: 24px;
        padding-right: 29px;
      }
      &-bottom-actions {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        &.dinamo{
          justify-content: center;
        }
        .main-button {
          font-size: 12px;
          line-height: 16px;
          padding: 8px 0;
          height: auto;
          width: 48%;
        }
      }
      &-content-list-item-value img {
        margin-right: 8px;
      }
    }
    .mobile-actions {
      width: 100%;
      margin-top: 80px;
      bottom: 0;
      .main-button {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .estado-crediticio {
    width: calc(552px - 16px);
    display: flex;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(14, 105, 175, 0.3);
    justify-content: space-between;
    padding: 12px;
    color: $copec-dark-blue;
    margin-left: 100px;
    //margin-left: 750px;
    //margin-left:100px;
    p {
      font-size: 10px;
    }
    .info1,
    .info2 {
      display: flex;
      align-items: center;
    }
    .info1 {
      .numbers {
        width: 184px;
        .credito {
          display: flex;
          flex-direction: column;
          align-items: center;
          .titlec {
            font-size: 10px;
            color: green;
            display: flex;
            gap: 7px;
            font-weight: 800;
            margin-bottom: 2px;
          }
          .montoc {
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 800;
          }
        }
      }
      .partida {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3px;
        .title {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
      .title,
      .monto {
        font-size: 10px;
        font-weight: 500;
      }
      .sep3 {
        margin-bottom: 3px;
      }
    }
    .info2 {
      display: flex;
      gap: 15px;
      margin-top: 4px;
      .numbers {
        width: 184px;
      }
      .partida {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        .title {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
      .warning {
        width: 52px;
        height: 90px;
        background-color: #f1f5f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        p {
          font-size: 8px;
          text-align: center;
          font-weight: 600;
          &.off{
            color:#d2d2d2;
          }
        }
      }
      .title,
      .monto {
        font-size: 10px;
        font-weight: 500;
      }
      .sep5 {
        margin-bottom: 5px;
      }
    }
  }

  .estados-container{
    width: 1307px;
    display:flex;
    margin: 20px 0;
    &.dinamo{
      width: 1307px;
    }
  }

  @media (max-width: 500px) {
    .estado-crediticio {
      flex-direction: column;
      width: 270px;
      padding: 20px;
      .info1 {
        .chart {
          width: 72px;
        }
      }
    }
    .estados-container{
      flex-direction: column;
      width: 100% !important;
      margin: unset;
      .icons{
        width: unset;
        margin:unset;
        margin-bottom: 20px;
      }
      .estado-crediticio{
        margin-bottom: 15px;
        align-self: center;
      }
    }
  }


  
}

.rechazar-pedido {
  margin-top: 31px;
  display: flex;
  gap: 140px;
  align-items: flex-start;
  
  @media screen and (max-width: $mobile-break-point) {
    .dinamo-info{
      margin-top: 24px !important;
    }
  }

  .motivo-rechazo-container{
    width: 975px;
    display: inline-block;
    .eds-select{
      float: left;
    }
    .dinamo-info{
      margin: 0;
      margin-top: -20px;
      float: right;
      @media screen and (max-width: $mobile-break-point) {
        margin-top: 24px !important;
      }
    }
  }

  hr.mtop {
    margin-top: 23.5px;
    width: 640px;
  }

  .actions{
    width: 640px;
  }

  .item {
    .title {
      font-size: 12px;
      color: $copec-dark-blue;
      font-weight: bold;
      margin-bottom: 8px;
      &.mtop {
        margin-top: 25px;
      }
    }
  }

  .rechazar-sidebar {
    .item {
      margin-bottom: 32px;
      .title {
        font-size: 12px;
        color: $copec-dark-blue;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .value {
        width: calc(192px - 32px);
        height: calc(32px - 16px);
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 500;
        background-color: #f1f5f8;
        color: #7c97ab;
      }
    }
  }

  .main {
    .actions {
      display: flex;
      justify-content: center;
      float: unset;
      .actions-buttons {
        &.blocked {
          background-color: white;
          cursor: not-allowed;
          border: 1px solid #003965;
          color: #003965;
        }
      }
    }

    .productos {
      width: calc(640px - 16px);
      background-color: #dee4ea;
      padding: 8px;
      border-radius: 4px;
      .header {
        width: calc(624px - 32px);
        background-color: $copec-dark-blue;
        color: white;
        padding: 16px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        margin-bottom: 8px;
        .liters {
          width: 80px;
        }
      }
      .items {
        background-color: white;
        width: 624px;
        border-radius: 4px;
        margin-bottom: 8px;
        .producto {
          display: flex;
          justify-content: space-between;
          padding: 8px 16px;
          .title,
          .liters {
            font-size: 12px;
            font-weight: 500;
            color: $copec-dark-blue;
            line-height: 2;
          }
          .liters {
            width: 80px;
          }
        }
        .separator {
          margin: 0 16px;
          border: solid 1px #dee4ea;
        }
        @media screen and (max-width: $mobile-break-point) {
          .separator {
            border-bottom: none;
            border-left: none;
            border-right: none;
          }
        }
      }
      .total {
        width: calc(624px - 32px);
        padding: 16px;
        display: flex;
        background-color: white;
        border-radius: 4px;
        justify-content: space-between;
        color: $copec-dark-blue;
        .title,
        .liters {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.14;
        }
        .liters {
          width: 80px;
        }
      }
    }
  }
}

.resumen-rechazar-pedido,
.resumen-ingreso-pedido {
  width: 640px;
  margin: 31px auto;

  .mtop {
    display: inline-block;
    margin-top: 24px;
  }

  .msep {
    margin-top: 15px;
    margin-bottom: 32px;
  }

  .actions {
    display: flex;
    justify-content: center;
    float: unset;
    .actions-buttons {
      width: 224px;
      height: 48px;
      font-size: 16px;
      &.red {
        background-color: #ea212e;
      }
      &.red-border {
        border: solid 1px #ea212e !important;
        color: #ea212e;
      }
    }
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.78;
    color: $copec-dark-blue;
  }
  .title-separator {
    width: 191px;
    border: solid 1px #dee5ea;
  }

  .item {
    .title {
      font-size: 12px;
      color: $copec-dark-blue;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .value {
      width: calc(192px - 32px);
      height: calc(32px - 16px);
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 500;
      background-color: #f1f5f8;
      color: #7c97ab;
      border-radius: 4px;
      &.overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &.full {
        width: calc(640px - 32px);
      }
      &.obs {
        height: auto;
        line-height: 2;
        color: $copec-dark-blue;
      }
    }
  }

  @media screen and (max-width: $mobile-break-point) {
    .title-separator {
      border-top: none;
      border-left: none;
      border-right: none;
    }

    .item .value {
      border-radius: 4px;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 23.5px;

    &.col4 {
      .item {
        .value {
          width: calc(136px - 32px);
        }
      }
    }
  }

  .productos {
    width: calc(640px - 16px);
    background-color: #dee4ea;
    padding: 8px;
    border-radius: 4px;
    margin-top: 24px;
    &.original {
      width: calc(256px - 16px);
      float: left;
      .items .producto .title {
        width: 130px;
        color: $copec-grey;
      }
      .items .producto .liters {
        width: 70px;
        padding-right: 8px;
        color: $copec-grey;
      }

      .total .liters {
        width: 70px;
        padding-right: 8px;
      }

      .total {
        color: $copec-grey;
      }
    }

    &.modificacion {
      float: right;
      width: calc(360px - 16px);
    }
    .header {
      width: calc(100% - 32px);
      background-color: $copec-dark-blue;
      color: white;
      padding: 16px;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      margin-bottom: 8px;
      .liters {
        width: 80px;
        text-align: right;
        padding-right: 8px;
      }
    }
    .items {
      background-color: white;
      width: 100%;
      border-radius: 4px;
      margin-bottom: 8px;
      .producto {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        .title,
        .liters {
          font-size: 12px;
          font-weight: 500;
          color: $copec-dark-blue;
          line-height: 2;
          text-transform: capitalize;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .liters {
          width: 80px;
          text-align: right;
          padding-right: 8px;
        }
      }
      .separator {
        margin: 0 16px;
      }

      @media screen and (max-width: $mobile-break-point) {
        .separator {
          border-left: none;
          border-right: none;
        }
      }

      .separator-last {
        margin: -2px 16px;
        border: solid 1px white;
      }
    }
    .total {
      width: calc(100% - 32px);
      padding: 16px;
      display: flex;
      background-color: white;
      border-radius: 4px;
      justify-content: space-between;
      color: $copec-dark-blue;
      .title,
      .liters {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.14;
      }
      .liters {
        width: 80px;
        text-align: right;
        padding-right: 8px;
      }
    }
  }
}

@media screen and (max-width: $mobile-break-point) {
  .pedidos-activos-mobile-data-modal {

    .volumen-dinamo-container{
      width: 100%;
      background-color: $copec-light-grey;
      border-radius: 4px;
      margin-top: 24px;
      
      .content{
        padding:8px;
      }
      .title{
        border-radius: 4px;
        padding: 10px 0 12px 15px;
        background-color: $copec-dark-blue;
        font-size: 12px;
        color:white;
        font-weight: 800;
        margin-bottom: 8px;
      }
      .dbody{
        background-color: white;
        padding: 8px 16px;
        border-radius: 4px;
        .material{
          display: flex;
          flex-direction: column;
          gap:8px;
        }
        .msep{
          margin: 8px 0;
        }
        .nombre{
          font-size: 12px;
          color: $copec-dark-blue;
          font-weight: 800;
        }
        .entrada{
          display: flex;
          font-size: 12px;
          font-weight: 800;
          color:$copec-dark-blue;
          .tipo{
            color:#7c97ab;
          }
          .volumen1,.volumen2,.volumen3{
            width: 50px;
            text-align: right;
          }
          .volumen1{
            margin: 0 auto 0 50px;
          }
          .volumen2{
            margin: 0 auto 0 43px;
          }
          .volumen3{
            margin: 0 auto 0 47px;
          }
          
        }
      }
    }

    &__title {
      font-size: 18px;
      line-height: 32px;
      margin: 6px 0 0;
      font-weight: 700;
      color: $copec-dark-blue;
    }
    &__info {
      color: $copec-grey;
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }

    table {
      margin-top: 24px;
      table-layout: fixed;
      td,
      th {
        &:first-child {
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.recalculo-data {
            white-space: normal;
            font-size: 12px;
            line-height: 24px;
            .recalculo {
              &-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
              }
              &-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
        &:last-child {
          text-align: right;
          vertical-align: top;
        }
      }
    }
    &.volumen {
      th:first-child {
        width: 200px;
      }
    }
    &.recalculo {
      th:first-child {
        width: 160px;
      }
    }
  }

  .resumen-ingreso-pedido {
    width: auto;
    .productos {
      width: calc(100% - 16px) !important;
      .producto .title {
        width: 150px !important;
      }
    }
    .info {
      flex-wrap: wrap;
      &:not(.col4) {
        .item:nth-child(3) {
          flex-grow: 1;
        }
      }
      &.col4 {
        margin-top: 0;
      }
    }
    .item {
      width: 48%;
      margin-bottom: 24px;
      .value {
        width: 100% !important;
        height: 32px !important;
        box-sizing: border-box;
        border-radius: 4px;
      }
      &.mtop {
        width: 100%;
        .value {
          height: 88px !important;
        }
      }
    }
    .actions {
      flex-direction: column;
      border-top: 1px solid $copec-light-grey;
      padding-top: 32px;
      .actions-buttons {
        width: 100%;
        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-break-point) {
  .pedidos-activos {
    .view-subtitle {
      font-size: 22px;
    }
    .rechazar-pedido {
      display: inline-block;
      width: 100%;
      .rechazar-sidebar {
        width: 100%;
        .item .value {
          width: calc(100% - 32px) !important;
          border-radius: 4px;
        }
      }
      .main .productos {
        width: calc(100% - 16px);
        .header {
          width: calc(100% - 32px);
        }
        .items {
          width: 100%;
          .producto .title {
            width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .total {
          width: calc(100% - 32px);
        }
      }
      .actions {
        display: inline-block;
        width: 100%;
        .actions-buttons {
          width: calc(100% - 30px);
          margin-left: 15px;
          margin-bottom: 24px;
        }
      }
    }

    .comprobante {
      zoom: 50%;
    }
    .comprobante-container {
      .back-button-container {
        width: 100%;
        display: inline-block;
        margin-top: 24px;
        .back-button {
          width: 100%;
        }
      }
      .comprobante-action {
        float: none;
      }
    }
  }
  .resumen-rechazar-pedido {
    width: 100%;
    .info {
      display: inline-block;
      width: 100%;
      .item {
        width: 100%;
        margin-bottom: 32px;
        &.shared-line {
          width: calc(50% - 4px);
          float: left;
          &.left {
            margin-right: 7px;
          }
        }
        .value {
          width: calc(100% - 32px) !important;
          border-radius: 4px;
        }
      }
    }
    .productos {
      width: calc(100% - 16px);
      .header {
        width: calc(100% - 32px);
      }
      .items {
        width: 100%;
        .producto .title {
          width: 150px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .total {
        width: calc(100% - 32px);
      }
    }
    .mtop {
      width: 100%;
      .value {
        width: calc(100% - 32px) !important;
        border-radius: 4px;
      }
    }
    .actions {
      display: inline-block;
      width: 100%;
      .actions-buttons {
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-bottom: 24px;
      }
    }
  }
}

.pedidos-activos-table-tipo-pedido{
  width: 90px !important;
  padding: 2px 3px 2px 3px;
  border-radius: 2px;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  background: $copec-azul-2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $mobile-break-point) {
    margin-top: 4px;
  }

  &.pedidos-activos-table-tipo-pedido-emergencia{
    background: $copec-red-2;
  }
  &.pedidos-activos-table-tipo-pedido-preprograma{
    background: $copec-morado;
  }
  &.pedidos-activos-table-tipo-pedido-cambio-fecha{
    background: $copec-grey;
  }
  .highlighter-container{
    width: calc(106px - 16px) !important;
  }
}

.mobile-pedidos-activos-tipo-pedido{
  width: 32px !important;
  height: 16px;
  color: white;
  border-radius: 2px;
  font-size: 12px;
  background: $copec-azul-2;
  line-height: normal;
  text-align: center;
  float: left;
  margin-top: 4px;
  margin-right: 4px;
  &.mobile-pedidos-activos-tipo-pedido-emergencia{
    background: $copec-red-2;
  }
  &.mobile-pedidos-activos-tipo-pedido-preprograma{
    background: $copec-morado;
  }
  &.mobile-pedidos-activos-tipo-pedido-emergencia{
    background: $copec-red-2;
  }
  &.mobile-pedidos-activos-tipo-pedido-cambio-fecha{
    background: $copec-grey;
  }
}

.mobile-pedidos-activos-cantidad{
  float: right;
}


/* ESTILOS PARA MODULO DE MANTENIMIENTO, REUTILIZANDO PEDIDOS ACTIVOS */

.pedidos-activos-table{




  &.ot{
    width:1310px;

    .th-data{
      width: calc(110px - 16px);
      &.w150{
        width: calc(150px - 16px);
      }
      &.w390{
        width: calc(401px - 16px);
      }
    }

    .data{
      .align-left{
        width:98px;
      }
    }

  }

  &.avisos{
    width:1310px;

    .data{
      .align-left{
        
        .ellipsis-container{
          display: block;
        }
      }
      
    }

    .th-data{
      width: calc(90px - 16px);
      &.w80{
        width: calc(80px - 16px);
      }
      &.w176{
        width: calc(180px - 16px);
      }
    }

    .data{
      .align-left{
        width:70px;
      }
    }


  }
}
