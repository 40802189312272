.cell-border-top-left {
  border-top-left-radius: 4px;
}
.cell-border-bottom-left {
  border-bottom-left-radius: 4px;
}
.cell-border-top-right {
  border-top-right-radius: 4px;
}
.cell-border-bottom-right {
  border-bottom-right-radius: 4px;
}

.tanque-name-column{
  white-space: nowrap !important; 
}

.no-padding-left{
  padding-left: 0 !important;
}

.flutuaciones-table {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  .red{
    color: $copec-red;
  }
  thead {
    .vertical-separator {
      background-color: $copec-dark-blue;
      min-width: 8px;
    }
    th {
      height: 48px;
      box-sizing: border-box;
      .align-center {
        text-align: center;
      }
    }
  }

  tr {
    &:first-child {
      th {
        background: $copec-dark-blue;
        &:first-child {
          @extend .cell-border-top-left;
        }
        &:last-child {
          @extend .cell-border-top-right;
        }
      }
    }
    &:last-child {
      th {
        background: $copec-dark-blue;
        &:first-child {
          @extend .cell-border-bottom-left;
        }
        &:last-child {
          @extend .cell-border-bottom-right;
        }
      }
    }
  }
  th,
  td {
    padding: 8px 16px;
    border: 0;
  }
  tbody,
  tfoot {
    td {
      background: #fff;
      white-space: nowrap;
      line-height: 24px;
      button:not(.main-button) {
        border: none;
        background: none;
        text-decoration: underline;
        color: $copec-dark-blue;
        cursor: pointer;
      }
    }
  }
  tfoot {
    td {
      font-weight: 700;
      &:last-child {
        text-align: right;
      }
    }
  }
  .row-with-border td {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: $copec-separator;
      height: 1px;
      width: 100%;
      bottom: 1px;
      left: 0;
    }
    &:first-child {
      &::after {
        left: 16px;
      }
    }
    &:last-child {
      &::after {
        left: auto;
        right: 16px;
      }
    }
  }
  .sifon-camiones-separator td{
    padding: 0;
  }
  .row-without-border td {
    position: relative;
    &:first-child {
      &::after {
        left: 16px;
      }
    }
    &:last-child {
      &::after {
        left: auto;
        right: 16px;
      }
    }
  }
  .full-width {
    @extend .cell-border-top-left;
    @extend .cell-border-top-right;
    @extend .cell-border-bottom-left;
    @extend .cell-border-bottom-right;
  }
  .horizontal-separator {
    height: 8px;
    background: $copec-table-background !important;
    padding: 0;
  }
  .vertical-separator {
    width: 8px;
    background-color: transparent;
    padding: 0;
    & + td {
      &::after {
        left: 16px;
      }
    }
  }
  .cell-before-separator {
    &::after {
      right: 16px;
      left: auto !important;
    }
  }
  .min-width-cell {
    min-width: 65px;
  }
  .only-row-span-cell-border td[rowspan] {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: $copec-separator;
      height: 1px;
      width: 100%;
      bottom: 1px;
      left: 0;
    }
  }
  .bold-row {
    font-weight: 700;
  }
  .right-aligned-cell {
    text-align: right;
  }
  .table-select > [class*="control"] {
    border: none;
  }
  .add-new-row {
    color: $copec-grey;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: $copec-gris-input;
    display: table;
    margin: 0 auto;
    height: auto;
    width: 160px;
  }
  .remove-row-btn {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
  }
  .disabled-input {
    border: none;
  }
  .bold-cell {
    font-weight: 700;
  }
  .react-datepicker-wrapper {
    height: 32px;
    border: 1px solid $copec-grey;
    border-radius: 4px;
    input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .row-icon.edit{
    cursor: pointer;
  }
}

.hf-observaciones__content {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: $copec-dark-blue;
  margin: 24px 0 0;
  padding: 16px;
  background: $sidebar-grey;
  border-radius: 4px;
}

.flutuaciones-modal {
  position: relative;
  padding: 16px;
  .close-modal {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &__title {
    font-size: 16px;
    line-height: 24px;
    color: $copec-dark-blue;
    margin: 0 24px 0 0;
  }
  &__description {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $copec-grey;
    margin: 0;
  }
}

.hf-form {
  width: 870px;
  margin: auto;
  .filters-container {
    width: 100%;
    display: flex;
  }
  .filter {
    flex-grow: 1;
    .filter-input-element {
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &-tables {
    display: flex;
    table {
      &:not(:last-child) {
        margin-right: 8px;
        flex-grow: 1;
        .table-input {
          max-width: 105px;
          min-width: 105px;
        }
      }
      &:last-child {
        width: 192px;
      }
    }
    th {
      white-space: nowrap;
    }
    td {
      width: 100%;
    }
  }
}

.hf-form-section {
  margin-top: 24px;
  &__title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: $copec-dark-blue;
  }
  &__note {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $copec-grey;
    margin: 24px 0 0;
    padding: 16px;
    background: $sidebar-grey;
    border-radius: 4px;
  }
}

.hf-form-actions {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  .main-button {
    &.red {
      color: $copec-red;
      border-color: $copec-red;
      &:not(.hallow) {
        background: $copec-red;
        color: white;
      }
    }
    &:first-child {
      margin-right: 32px;
    }
  }
}
.table-input {
  width: 120px;
  float: right;
  border-radius: 4px;
  background: #fff;
  border: 1px solid $copec-grey;
  min-height: 32px;
  box-sizing: border-box;
  padding: 8px 16px;
  color: $copec-grey;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  &-label {
    display: block;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 16px;
  }
}

.flutuaciones-section-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: $copec-dark-blue;
  text-transform: uppercase;
  margin: 0;
  &.push-down {
    margin-top: 32px;
  }
  &.normalizacion-entradas{
    margin-top: 32px;
  }
}

p.ventas-title{
  margin-bottom: 14px;
}
.flutuaciones-textarea {
  border: 1px solid $copec-grey-3;
  border-radius: 4px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  margin-top: 8px;
  &-count {
    text-align: right;
    margin: 0 0 8px 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $copec-dark-blue;
  }
}

