.historial-de-modificaciones {
  width: 865px;
  margin: 0 auto;
  .filters-container {
    display: flex;
    align-items: flex-end;
  }
  .flutuaciones-table {
    width: unset;
    th,
    td {
      min-width: 65px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
.hdm-resumen__table {
  margin-top: 24px;
  th,
  td {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  }
  th {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.hdm-resumen{
  padding: 16px;
  .close-modal{
    float: right;
  }
}