@import "./variables";
.custom-select{
  width: 100%;
  position: relative;
  color: $copec-dark-blue;
  font-size: 12px;

  .custom-select-title{
    padding: 8px 16px;
    border: 1px solid $copec-grey;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  .custom-select-dropdown{
    border: 1px solid $copec-grey;
    border-radius: 4px;
    position: absolute;
    width: calc(100% - 2px);
    background: white;
    z-index: 100;

  }
  .submenu{
    display: none;
    position: absolute;
    background: white;
    margin-top: -25px;
    left: calc(100% + 1px);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #0e69af;
    border-radius: 4px;
    width: 143px;
    font-weight: normal;
    .submenu-item{
      padding: 9px 15px 8px;
      font-weight: 500;

      &:hover{
        background: $copec-gris-input;
      }
    }
  }

  .option{
    padding: 9px 5px 8px 15px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;

    .option-title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover{
      background: $copec-gris-input;
      .option-title{
        font-weight: bold;
        cursor: pointer;
      }
      .submenu {
        display: block;
      }
    }
  }
}

.last-comp .custom-select .submenu{
  left: -144px;
}
