.configuracion-tanques {
  width: 785px;
  margin: 0 auto;
  &.configuracion-tanques-big{
    width: 850px;
  }
  .flutuaciones-table {
    th {
      width: 100%;
    }
  }
  .configurations-table {
    th:not(:last-child) {
      min-width: 110px;
    }
  }
  .disabled-inputs-view {
    .filters-container {
      width: 100%;
      display: flex;
      .filter {
        width: calc(100% / 3);
        &:last-child {
          margin-right: 0;
        }
        .filter-input-element {
          width: 100%;
        }
      }
    }
  }

  .page-options {
    .main-button {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 14px;
      padding-top: 8px;
      padding-bottom: 8px;
      height: auto;
      width: 160px;
    }
  }
  .new-configuration-table {
    margin-bottom: 8px;
    & ~ hr {
      margin-top: 16px;
    }
    td,
    th {
      padding-left: 8px;
      padding-right: 8px;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
    &.new-configuration-table-details{
      td, th{
        padding-left: 24px;
        padding-right: 24px;
      }

    }
    .table-select {
      width: auto;
      max-width: 150px;
      width: 90px;
      border-color: $copec-grey;
      &.disabled {
        border: none;
        & > [class*="control"] {
          background: $copec-gris-input;
        }
        [class*="indicator"] {
          display: none;
        }
      }
    }
    .cell-with-large-select .table-select {
      width: 205px;
      max-width: 225px;
    }
    .cell-with-small-select {
      .table-select {
        width: 55px;
      }
    }
    .table-input {
      width: 82px;
    }
  }
  .configuracion-concesionario-tanques {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    display: table;
  }

  .new-configurations-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    .main-button {
      margin: 0 16px;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      height: auto;
      width: 160px;
      &.red {
        color: $copec-red;
        border-color: $copec-red;
      }
    }
  }
  
  .new-configuration-duration-table {
    margin-bottom: 16px;
    .react-datepicker-wrapper {
      border: 1px solid $copec-grey;
      border-radius: 4px;
    }
  }
  .configuracion-table {
    margin-bottom: 24px;
  }
  .link{
    font-size: 12px;
  }

  .fluctuaciones-disabled-select{
    background: $copec-gris-input;
    color: $copec-grey;
    width: 48px;
    text-align: center;
    padding: 4px 0;
  }

  .disabled-input-select{
    width: 128px;
    height: 29px;
    padding-left: 16px;
    background: white;
    border: 1px solid $copec-grey;
    color: $copec-grey;
    border-radius: 4px;

  }
}

